import {createContact} from 'api/contact.api';
import {ErrorMessage} from 'components/error-message';
import {Button} from 'components/html/button';

import {Input} from 'components/html/input';
import {useModal} from 'components/modal';

import {Field, Form, Formik} from 'formik';
import {ContactGroup} from 'iterfaces/contact-group';
import React from 'react';

import I18n from 'services/I18n';
import {createContactFormSchema} from 'validators/contact';
import {InferType} from 'yup';

import styles from './create-contact-form.module.scss';

export type CreateContactFormValues = Pick<
    InferType<typeof createContactFormSchema>,
    'name' | 'email'
>;

export type CreateContactFormProps = {
    onSuccess: () => void;
    contactGroup: ContactGroup['id'];
};

export const createContactFormInitialValues: CreateContactFormValues = {
    name: '',
    email: '',
};

export const CreateContactForm: React.FC<CreateContactFormProps> = ({
    onSuccess,
    contactGroup,
}) => {
    const [showModal] = useModal();
    return (
        <Formik
            onSubmit={(values, {setFieldValue}) => {
                createContact(
                    {
                        ...values,
                        contactGroup,
                    },
                    () => {
                        onSuccess();
                        setFieldValue('name', createContactFormInitialValues.name);
                        setFieldValue('email', createContactFormInitialValues.email);
                    },
                    ({code}) => {
                        showModal({
                            children: <ErrorMessage error={code} />,
                        });
                    },
                );
            }}
            validationSchema={createContactFormSchema}
            initialValues={createContactFormInitialValues}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({errors}) => (
                <Form className={styles.form}>
                    <Field
                        name={'name'}
                        label={I18n.t('contact.name')}
                        as={Input}
                        error={errors.name}
                        fixedWidth={false}
                        className={styles.nameField}
                    />
                    <Field
                        name={'email'}
                        label={I18n.t('email')}
                        as={Input}
                        error={errors.email}
                        fixedWidth={false}
                        className={styles.emailField}
                    />
                    <Button
                        variant={'secondary'}
                        transitionEffects={false}
                        icon={{
                            icon: 'plus',
                        }}
                        shadow={false}
                        fixedWidth={false}
                        className={styles.submitButton}
                        type={'submit'}
                        title={I18n.t('form.contact.create.submitButton')}
                    >
                        {I18n.t('form.contact.create.submitButton')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
