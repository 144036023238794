import React from 'react';
import {RouteProps} from 'react-router';
import {Redirect, Route} from 'react-router-dom';

interface AppRouterProps extends RouteProps {
    auth?: boolean;
    loggedOnly?: boolean;
    guestOnly?: boolean;
}

const AppRouter: React.FC<AppRouterProps> = ({
    auth = false,
    loggedOnly = false,
    guestOnly = false,
    ...props
}) => {
    return loggedOnly && !auth ? (
        <Route {...props} component={() => <Redirect to={'/login'} />} />
    ) : guestOnly && auth ? (
        <Route {...props} component={() => <Redirect to={'/login'} />} />
    ) : (
        <Route {...props} />
    );
};
export const GuestRouter: React.FC<AppRouterProps> = (props) => (
    <AppRouter {...props} guestOnly />
);
export const LoggedRouter: React.FC<AppRouterProps> = (props) => (
    <AppRouter {...props} loggedOnly />
);
export default AppRouter;
