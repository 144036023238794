import {Vendor, initVendor} from './Vendor';

export default interface Auth {
    token: string;
    vendor: Partial<Vendor>;
}

export const initAuth: Auth = {
    vendor: initVendor,
    token: '',
};
