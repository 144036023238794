import DASHBOARD_ROUTES from '../../routes';

export default {
    START: DASHBOARD_ROUTES.TEMPLATES,
    CREATE_TEMPLATE: `${DASHBOARD_ROUTES.TEMPLATES}/create`,
    EDIT_TEMPLATE: (id: number | string, locale = 'is') =>
        `${DASHBOARD_ROUTES.TEMPLATES}/edit/${id}${
            locale !== 'is' ? `/${locale}` : ''
        }`,
};
