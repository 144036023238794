import {Survey} from 'iterfaces/survey';
import {MediaObject} from 'iterfaces/media-object';

import {CreateSurveyFormValues} from 'components/forms/survey-form';

import * as ACTIONS from './types';

export const setSurveys = (surveys: Survey[]) => ({
    payload: {surveys},
    type: ACTIONS.SET_SURVEYS,
});

export const setSurveyForm = (surveyForm: Partial<CreateSurveyFormValues>) => ({
    payload: {surveyForm},
    type: ACTIONS.SET_SURVEY_FORM,
});

export const setSurveyLogo = (surveyLogo: MediaObject) => ({
    payload: {surveyLogo},
    type: ACTIONS.SET_SURVEY_LOGO,
});
