import {createSection, deleteSection, updateSection} from 'api/survey.api';
import {ErrorMessage} from 'components/error-message';
import {Anchor} from 'components/html/anchor';
import {Button} from 'components/html/button';

import {Input} from 'components/html/input';
import {Icon} from 'components/icon';
import {useModal} from 'components/modal';

import {FastField, FieldArray, getIn} from 'formik';

import I18n from 'i18n-js';
import {FormPartial, FormPartialWithArrayHelpers} from 'iterfaces/form';
import {get} from 'lodash';
import React, {ChangeEvent, FocusEvent, useState} from 'react';
import {sectionInit} from '../../';
import {CreateSurveyFormValues} from '../../survey-form';
import styles from '../../survey-form.module.scss';
import {Questions} from '../questions';

export const Section: React.FC<
    {
        path: string;
        index: number;
        locale?: string;
        surveyId: number;
    } & FormPartialWithArrayHelpers<CreateSurveyFormValues>
> = ({path, index, locale = 'is', surveyId, ...restParam}) => {
    const {
        errors,
        values,
        touched,
        remove,
        handleBlur,
        handleChange,
        validateField,
    } = restParam;
    const currentPath = `${path}[${index}]`;
    const initialId = getIn(values, `${currentPath}.id`) as number;
    const [sectionCreated, setSectionCreated] = useState(!!initialId);
    const [, setSectionUpdated] = useState(false);
    const [sectionId, setSectionId] = useState(initialId);
    const [appendModal] = useModal();

    const fieldName = `${currentPath}.name`;
    const fieldUpdater = surveyId
        ? {
              forceupdate: 'true',
          }
        : {};

    return (
        <div className={styles.section}>
            <div className={styles.sectionInner}>
                <div className={styles.row}>
                    <FastField
                        label={I18n.t('form.survey.section.label')}
                        name={fieldName}
                        as={Input}
                        error={
                            get(values, `${currentPath}.translations.${locale}.name`)
                                ? getIn(touched, fieldName) &&
                                  getIn(errors, fieldName)
                                : locale !== 'is'
                                ? 'Þýðingu vantar'
                                : getIn(touched, fieldName) &&
                                  getIn(errors, fieldName)
                        }
                        placeholder={get(
                            values,
                            `${currentPath}.translations.is.name`,
                            '',
                        )}
                        onFocus={() => {
                            if (!sectionCreated) {
                                setSectionCreated(true);
                                createSection(
                                    {
                                        survey: surveyId,
                                        newTranslations: {
                                            [locale]: {
                                                name: I18n.t(
                                                    'form.survey.section.label',
                                                ),
                                            },
                                        },
                                    },
                                    ({data}) => {
                                        setSectionId(data.id);
                                    },
                                    ({code}) => {
                                        setSectionCreated(false);
                                        if (/[53]../.test(code)) {
                                            appendModal({
                                                children: (
                                                    <ErrorMessage error={code} />
                                                ),
                                            });
                                        }
                                    },
                                );
                            }
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            handleChange(event);
                            validateField(fieldName);
                        }}
                        onBlur={(event: FocusEvent<HTMLInputElement>) => {
                            handleBlur(event);
                            if (!getIn(errors, fieldName)) {
                                updateSection(
                                    sectionId,
                                    {
                                        newTranslations: {
                                            [locale]: {
                                                name: getIn(values, fieldName),
                                            },
                                        },
                                    },
                                    () => {
                                        setSectionUpdated(true);
                                    },
                                    ({code}) => {
                                        if (/[53]../.test(code)) {
                                            appendModal({
                                                children: (
                                                    <ErrorMessage error={code} />
                                                ),
                                            });
                                        }
                                    },
                                );
                            }
                        }}
                        autoComplete={'off'}
                        disabled={values.published}
                        {...fieldUpdater}
                    />
                    {locale !== 'is' ? (
                        <Anchor
                            className={styles.icon}
                            data-tip={get(
                                values,
                                `${currentPath}.translations.is.name`,
                                '',
                            )}
                            transitions={false}
                        >
                            <Icon icon={'preview'} size={'medium'} />
                        </Anchor>
                    ) : null}
                </div>
                {locale === 'is' &&
                getIn(values, path).length > 1 &&
                !values.published ? (
                    <Anchor
                        onClick={() => {
                            if (sectionId) {
                                deleteSection(
                                    sectionId,
                                    () => {
                                        remove(index);
                                    },
                                    ({code}) => {
                                        if (/[53]../.test(code)) {
                                            appendModal({
                                                children: (
                                                    <ErrorMessage error={code} />
                                                ),
                                            });
                                        }
                                    },
                                );
                            } else {
                                remove(index);
                            }
                        }}
                        title={I18n.t('form.survey.section.deleteTooltip')}
                        transitions={false}
                        className={styles.sectionRemoveButton}
                    >
                        <Icon icon={'minus'} size={'medium'} />
                    </Anchor>
                ) : null}
            </div>
            <Questions
                path={`${currentPath}.questions`}
                sectionId={sectionId}
                locale={locale}
                {...restParam}
            />
        </div>
    );
};

export const Sections: React.FC<
    {
        path: string;
        surveyId: number;
        locale: string;
    } & FormPartial<CreateSurveyFormValues>
> = ({path, surveyId, locale = 'is', ...restParam}) => {
    const {values} = restParam;
    return (
        <FieldArray name={'sections'} validateOnChange={false}>
            {(props) => {
                return (
                    <div className={styles.sections}>
                        {(getIn(values, path) as string[]).map((_, index) => (
                            <Section
                                path={path}
                                {...restParam}
                                index={index}
                                locale={locale}
                                surveyId={surveyId}
                                key={index}
                                {...props}
                            />
                        ))}
                        {locale === 'is' && !values.published ? (
                            <div className={styles.sectionsControls}>
                                <Button
                                    variant={'secondary'}
                                    size={'big'}
                                    shadow={false}
                                    icon={{icon: 'plus', size: 'medium'}}
                                    transitionEffects={false}
                                    onClick={() => {
                                        props.push(sectionInit);
                                        setTimeout(() => {
                                            window.scrollTo({
                                                top: 99999999,
                                                behavior: 'smooth',
                                            });
                                        }, 300);
                                    }}
                                    type={'button'}
                                    title={I18n.t('form.survey.section.addTooltip')}
                                    className={styles.sectionAddButton}
                                >
                                    {I18n.t('form.survey.section.addTooltip')}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                );
            }}
        </FieldArray>
    );
};
