import {Answer} from 'iterfaces/survey';
import {QuestionResults, AnswerWithResults} from 'iterfaces/results';

export const mapQuestionResultsToAnswersWithResults = (
    questionResults: QuestionResults,
    answers: Answer[],
): AnswerWithResults[] =>
    answers.map((answer) => ({
        ...answer,
        result: questionResults[answer.id.toString()],
    }));
