import {Survey} from 'iterfaces/survey';
import {CreateSurveyFormValues} from '../survey-form';

export const mapSurveyFormValuesToSurveySections = (
    values: CreateSurveyFormValues,
    locale = 'is',
): Survey['sections'] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return values.sections.map((section) => ({
        ...section,
        name: section.translations[locale].name,
        questions: section.questions?.map((question) => ({
            ...question,
            name: question.translations[locale].name,
            multiple: question.type === 'multipleChoice',
            answers: question.answers?.map((answer) => ({
                ...answer,
                name: answer.translations[locale].name,
            })),
        })),
    }));
};
