import {loginVendor, setApiAuthorization} from 'api/authorization.api';
import {getMe} from 'api/vendor.api';
import {ErrorMessage} from 'components/error-message';

import {LogInForm} from 'components/forms/log-in-form';
import {Anchor} from 'components/html/anchor';
import {Paragraph} from 'components/html/paragraph';
import {useModal} from 'components/modal';

import I18n from 'i18n-js';
import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import appHistory from 'services/history';

import {useAuth} from 'store/user/hooks';

import {pathnames} from '../../log-in';

import styles from './log-in-action.module.scss';

import DASHBOARD_ROUTES from 'containers/dashboard/routes';

export const LogInAction: React.FC<RouteComponentProps> = ({history}) => {
    const [, logIn] = useAuth();
    const [showModal] = useModal();

    return (
        <>
            <LogInForm
                initialValues={{
                    email: '',
                    password: '',
                }}
                validateOnBlur={false}
                validateOnChange={false}
                apiCallback={loginVendor}
                onSuccess={(response) => {
                    setApiAuthorization(response.data.token);
                    getMe(
                        (innerResponse) => {
                            logIn({
                                token: response.data.token,
                                vendor: innerResponse.data,
                            });
                            appHistory.push(DASHBOARD_ROUTES.START);
                        },
                        ({code}) => {
                            showModal({children: <ErrorMessage error={code} />});
                        },
                    );
                }}
                onForgotPasswordClick={() => {
                    history.push(pathnames.resetPasswordAction);
                }}
            />
            <Paragraph fontSize={'small'} className={styles.links}>
                {I18n.t('form.logIn.dontHaveAnAccountLink')}{' '}
                <Anchor
                    onClick={() => {
                        history.push(pathnames.signUpAction);
                    }}
                    title={I18n.t('vendor.signUp')}
                    href={'#'}
                >
                    {I18n.t('vendor.signUp')}
                </Anchor>
            </Paragraph>
        </>
    );
};
