import {Heading} from 'components/html/heading';
import {Input} from 'components/html/input';

import {Field, getIn} from 'formik';

import {FormPartial} from 'iterfaces/form';
import React from 'react';

import {
    RecipientFormProps,
    RecipientFormQuestionsValues,
    RecipientFormValues,
} from '../../recipient-form';

import styles from './questions.module.scss';

export const Questions: React.FC<
    {
        questions: RecipientFormQuestionsValues;
    } & Pick<RecipientFormProps, 'editable'> &
        FormPartial<RecipientFormValues>
> = ({questions, editable = true, ...restParam}) => {
    const {values, errors} = restParam;
    return (
        <>
            {questions.map((question) => {
                return (
                    <div className={styles.question} key={question.id}>
                        <Heading element={'h5'}>
                            {question.translations[values.locale].name}
                        </Heading>
                        <div className={styles.answers}>
                            {question.answers.map((answer) => {
                                if (question.multiple) {
                                    const fieldIndex =
                                        values.selectedAnswers.findIndex(
                                            (value) => value.answerId === answer.id,
                                        );
                                    return (
                                        <Field
                                            name={`selectedAnswers[${fieldIndex}].isSelected`}
                                            as={Input}
                                            type={'checkbox'}
                                            label={
                                                answer.translations[values.locale]
                                                    .name
                                            }
                                            disabled={!editable}
                                            key={`${question.id}_${answer.id}`}
                                        />
                                    );
                                } else {
                                    const fieldIndex =
                                        values.selectedAnswers.findIndex(
                                            (value) =>
                                                value.questionId === question.id,
                                        );
                                    return (
                                        <Field
                                            name={`selectedAnswers[${fieldIndex}].answerId`}
                                            value={answer.id.toString()}
                                            // decomment following line for proper working Field -> send errors to input component
                                            // error={getIn(errors, `selectedAnswers[${fieldIndex}].answerId`)}
                                            as={Input}
                                            type={'radio'}
                                            label={
                                                answer.translations[values.locale]
                                                    .name
                                            }
                                            disabled={!editable}
                                            key={`${question.id}_${answer.id}`}
                                        />
                                    );
                                }
                            })}
                            {question.answers.map((answer) => {
                                // cycling again, because field are not used properly - they should be separated for radio button but instead reunited under one Field component
                                // checking if this question has errors in validation formik
                                if (!question.multiple) {
                                    const fieldIndex =
                                        values.selectedAnswers.findIndex(
                                            (value) =>
                                                value.questionId === question.id,
                                        );
                                    // if errors, print them inside box of question with errors
                                    if (errors)
                                        return (
                                            <div
                                                className={styles.error}
                                                key={`${question.id}_${answer.id}_error`}
                                            >
                                                {getIn(
                                                    errors,
                                                    `selectedAnswers[${fieldIndex}].answerId`,
                                                )}
                                            </div>
                                        );
                                }
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
};
