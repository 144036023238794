import flattenDeep from 'lodash/flattenDeep';

import {Survey} from 'iterfaces/survey';

import {RecipientFormValues} from '../recipient-form';

export const mapSurveySectionsToRecipientFormValues = (
    locale: string,
    sections: Survey['sections'],
): RecipientFormValues => {
    return {
        locale,
        sections,
        selectedAnswers: flattenDeep(
            sections.map((section) =>
                section.questions.map((question) => {
                    if (question.multiple) {
                        return question.answers.map((answer) => ({
                            multiple: true,
                            questionId: question.id,
                            answerId: answer.id,
                            isSelected: false,
                        }));
                    } else {
                        return {
                            multiple: false,
                            questionId: question.id,
                            // Changed the answer id to be empty, otherwise the first radio button will be pre-checked
                            // answerId: question.answers[0].id.toString(),
                            answerId: '',
                        };
                    }
                }),
            ),
        ),
    };
};
