import {trackPromise} from 'react-promise-tracker';

import Api, {ErrorCallback, ResponseCallback} from 'services/Api';

import {GivenAnswer} from 'iterfaces/given-answer';

export const readGivenAnswers = (
    surveyId: number,
    onSuccess: ResponseCallback<GivenAnswer[]>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<GivenAnswer[]>(`given_answers?recipient.survey=${surveyId}`, {
            onSuccess,
            onError,
        }),
    );
};
