import {mapSurveyToCreateSurveyFormValues} from 'components/forms/survey-form';
import {SurveyForm} from 'components/forms/survey-form';
import {Modal} from 'components/modal';
import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import useTemplate from '../../hooks/useTemplate';
import TEMPLATES_ROUTES from '../../routes';

export const EditTemplate: React.FC<
    RouteComponentProps<{
        id: string;
        locale: string;
    }>
> = ({history, match}) => {
    const [template, setTemplate] = useTemplate();
    const currentLocale = match.params.locale;

    useEffect(() => {
        setTemplate(parseInt(match.params.id));
    }, [match.params.id]);

    return template ? (
        <Modal size={'full'}>
            <SurveyForm
                onSuccess={() => {
                    history.push(TEMPLATES_ROUTES.START);
                }}
                onCancel={() => {
                    history.push(TEMPLATES_ROUTES.START);
                }}
                initialValues={mapSurveyToCreateSurveyFormValues(
                    template,
                    currentLocale,
                )}
                locale={currentLocale}
                isTemplateForm={true}
            />
        </Modal>
    ) : (
        <span>No template found.</span>
    );
};
