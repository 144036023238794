import React from 'react';
import {Route} from 'react-router-dom';

import I18n from 'i18n-js';

import {Heading} from 'components/html/heading';

import {Start} from './routes/start';
import {Surveys} from './routes/surveys';
import {Vendors} from './routes/vendors';
import {Templates} from './routes/templates';
import {Recipients} from './routes/recipients';
import {Results} from './routes/results';
import {Settings} from './routes/settings';

import styles from './dashboard.module.scss';
import ROUTES from './routes';

export const Dashboard: React.FC = () => {
    return (
        <div className={styles.container}>
            <Heading element={'h1'}>{I18n.t('app.title')}</Heading>
            <main
                style={{
                    marginTop: '3rem',
                }}
            >
                <Route path={ROUTES.START} component={Start} exact />
                <Route path={ROUTES.VENDORS} component={Vendors} />
                <Route path={ROUTES.TEMPLATES} component={Templates} />
                <Route path={ROUTES.RECIPIENTS} component={Recipients} />
                <Route path={ROUTES.SURVEYS} component={Surveys} />
                <Route path={ROUTES.RESULTS} component={Results} />
                <Route path={ROUTES.SETTINGS} component={Settings} />
            </main>
        </div>
    );
};
