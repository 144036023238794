import {Heading} from 'components/html/heading';

import {AnswerWithResults} from 'iterfaces/results';

import join from 'lodash/join';
import React, {HTMLAttributes} from 'react';

import {PieChart} from 'react-minimal-pie-chart';

import I18n from 'services/I18n';
import {mapNumberToLetter} from 'utils/map-number-to-letter';

import styles from './question-results.module.scss';

export type QuestionResultsProps = HTMLAttributes<HTMLDivElement> & {
    name: string;
    answers: AnswerWithResults[];
};

export const QuestionResults: React.FC<QuestionResultsProps> = ({
    name,
    answers,
    className,
    ...restParam
}) => {
    // todo get color from stylesheets directly
    const pieColor = '147, 34, 128';
    const alpha = (factor: number) => 1 - 0.1 * factor;
    const noAnswers = !answers.find((answer) => answer.result);

    const enhancedAnswers = answers
        .sort((previousAnswer, nextAnswer) =>
            previousAnswer.result > nextAnswer.result
                ? -1
                : previousAnswer.result < nextAnswer.result
                ? 1
                : 0,
        )
        .map((answer, index) => {
            return {
                title: mapNumberToLetter(index),
                name: answer.translations.is.name,
                value: answer.result,
                color: `rgba(${pieColor}, ${alpha(index)})`,
                className: answer.result
                    ? styles[`legendItem${index + 1}`]
                    : styles.legendItem0,
            };
        });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
        <div
            className={join([styles.outerContainer, className], ' ')}
            {...restParam}
        >
            <Heading element={'h5'} className={styles.questionNameHeading}>
                {name}
            </Heading>
            <div
                className={join(
                    [styles.innerContainer, styles.innerContainer_noanswer],
                    ' ',
                )}
            >
                {noAnswers ? (
                    <>
                        <ul
                            className={join(
                                [styles.legend, styles.legend_noanswer],
                                ' ',
                            )}
                        >
                            {enhancedAnswers.map((answer, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={join(
                                            [styles.legendItem, answer.className],
                                            ' ',
                                        )}
                                    >
                                        <span className={styles.legendItemValue}>
                                            {answer.value}%
                                        </span>{' '}
                                        {/* {I18n.t(
                                    'dashboard.results.view.answerLabel',
                                )}{' '} */}
                                        <span className={styles.legendItemValue}>
                                            <span className={styles.answerLetter}>
                                                {mapNumberToLetter(index + 1)}:
                                            </span>
                                            <span className={styles.answerName}>
                                                {answer.name}
                                            </span>
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                        <Heading element={'h3'} className={styles.noAnswersMessage}>
                            {I18n.t('dashboard.results.view.noAnswersMessage')}
                        </Heading>
                    </>
                ) : (
                    <>
                        <PieChart
                            data={enhancedAnswers.filter((answer) => answer.value)}
                            animate={true}
                            className={styles.chart}
                            label={({dataIndex}) => {
                                return mapNumberToLetter(dataIndex + 1);
                            }}
                            labelPosition={enhancedAnswers.length > 1 ? 50 : 0}
                            // todo get color directly from stylesheets
                            labelStyle={{
                                fill: '#fff',
                            }}
                        />
                        <ul className={styles.legend}>
                            {enhancedAnswers.map((answer, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={join(
                                            [styles.legendItem, answer.className],
                                            ' ',
                                        )}
                                    >
                                        <span className={styles.legendItemValue}>
                                            {answer.value}%
                                        </span>{' '}
                                        {/* {I18n.t(
                                            'dashboard.results.view.answerLabel',
                                        )}{' '} */}
                                        <span className={styles.legendItemValue}>
                                            <span className={styles.answerLetter}>
                                                {mapNumberToLetter(index + 1)}:
                                            </span>
                                            <span className={styles.answerName}>
                                                {answer.name}
                                            </span>
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}
            </div>
        </div>
    );
};
