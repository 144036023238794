import React from 'react';

import {joinClass} from 'utils/joinClass';

import styles from './loader.module.scss';

export const Loader = () => {
    return (
        <div className={styles.container}>
            <div className={styles.circle} />
            <div className={joinClass(styles.circle, styles.second)} />
        </div>
    );
};
