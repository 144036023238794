import {
    mapSurveySectionsToRecipientFormValues,
    RecipientForm,
} from 'components/forms/recipient-form';

import {Button} from 'components/html/button';
import {useModal} from 'components/modal';

import {FormPartial} from 'iterfaces/form';
import React from 'react';

import I18n from 'services/I18n';
import {formHasFirstQuestionAndAnswer} from '../../helpers/form-has-first-question-and-answer';

import {mapSurveyFormValuesToSurveySections} from '../../helpers/map-survey-form-values-to-survey';

import {CreateSurveyFormProps, CreateSurveyFormValues} from '../../survey-form';

import styles from '../../survey-form.module.scss';

export const Controls: React.FC<
    Pick<CreateSurveyFormProps, 'onCancel' | 'isTemplateForm' | 'locale'> &
        FormPartial<CreateSurveyFormValues>
> = ({onCancel, isTemplateForm, locale = 'is', ...restParam}) => {
    const [appendModal] = useModal();
    const {values, errors} = restParam;

    return (
        <div className={styles.controls}>
            {locale === 'is' ? (
                <Button
                    type={'button'}
                    size={'big'}
                    variant={'warning'}
                    icon={{
                        icon: 'cross',
                        size: 'medium',
                        variant: 'light',
                    }}
                    title={I18n.t('form.survey.cancel')}
                    className={styles.formControlsButton}
                    onClick={() => {
                        onCancel?.();
                    }}
                >
                    {I18n.t('form.survey.cancel')}
                </Button>
            ) : null}
            {locale === 'is' ? (
                <Button
                    type={'button'}
                    size={'big'}
                    variant={'primary'}
                    icon={{
                        icon: 'preview',
                        size: 'medium',
                        variant: 'light',
                    }}
                    onClick={() => {
                        appendModal({
                            title: I18n.t('form.survey.preview.title'),
                            scroll: true,
                            children: (
                                <RecipientForm
                                    initialValues={mapSurveySectionsToRecipientFormValues(
                                        locale,
                                        mapSurveyFormValuesToSurveySections(values),
                                    )}
                                    editable={false}
                                />
                            ),
                            hasCloseIcon: true,
                        });
                    }}
                    title={I18n.t('form.survey.previewButton')}
                    className={styles.formControlsButton}
                    disabled={
                        !(
                            !Object.keys(errors).length &&
                            formHasFirstQuestionAndAnswer(values)
                        )
                    }
                >
                    {I18n.t('form.survey.previewButton')}
                </Button>
            ) : null}
            <Button
                size={'big'}
                variant={'primary'}
                icon={{
                    icon: 'check',
                    size: 'medium',
                    variant: 'light',
                }}
                title={I18n.t('form.survey.save')}
                className={styles.formControlsButton}
                type={'submit'}
            >
                {I18n.t('form.survey.save')}
            </Button>
        </div>
    );
};
