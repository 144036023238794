interface Storage<T> {
    [key: string]: T | undefined;
}

const getStore = <T>(): Storage<T> => {
    const dataJson = localStorage.app || '{}';
    return JSON.parse(dataJson);
};

const setStore = <T>(value: Storage<T>) => {
    localStorage.setItem('app', JSON.stringify(value));
};

const setItem = <T>(key: string, value: T) => {
    setStore({...getStore(), [key]: value});
};

const getItem = <T>(key: string): T | undefined => {
    const data = getStore<T>();
    return Object.prototype.hasOwnProperty.call(data, key) ? data[key] : undefined;
};

const removeItem = (key: string) => {
    const data = getStore();
    delete data[key];
    setStore(data);
};

const hasItem = (key: string) => {
    return getItem(key) !== undefined;
};

export default {
    hasItem,
    getItem,
    removeItem,
    setItem,
};
