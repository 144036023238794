import {CreateVendorResponse} from 'api/vendor.api';
import {ErrorMessage} from 'components/error-message';
import {Button} from 'components/html/button';

import {Input} from 'components/html/input';
import {useModal} from 'components/modal';
import {Field, Form, Formik} from 'formik';

import I18n from 'i18n-js';
import {FormProps} from 'iterfaces/form';
import React from 'react';
import {mapViolationsToFieldErrors} from 'utils/map-violations-to-field-errors';

import {signUpSchema} from 'validators/vendor';
import {InferType} from 'yup';

import styles from './sign-up-form.module.scss';

export type SignUpFormValues = Pick<
    InferType<typeof signUpSchema>,
    'firstName' | 'lastName' | 'email' | 'password' | 'confirmPassword'
>;

export type SignUpFormProps = FormProps<SignUpFormValues, CreateVendorResponse>;

export const SignUpForm: React.FC<SignUpFormProps> = ({
    apiCallback,
    onSuccess,
    ...restParam
}) => {
    const [showModal] = useModal();

    return (
        <Formik
            validationSchema={signUpSchema}
            onSubmit={(values, {setErrors, setSubmitting}) => {
                apiCallback(values, onSuccess, ({violations, code}) => {
                    setSubmitting(false);
                    if (/[53]../.test(code)) {
                        showModal({children: <ErrorMessage error={code} />});
                    } else {
                        setErrors(mapViolationsToFieldErrors(violations));
                    }
                });
            }}
            {...restParam}
        >
            {({errors, isSubmitting}) => {
                return (
                    <Form>
                        <Field
                            label={`${I18n.t('vendor.firstName')}*`}
                            name={'firstName'}
                            error={errors.firstName}
                            as={Input}
                            className={styles.field}
                        />
                        <Field
                            label={`${I18n.t('vendor.lastName')}*`}
                            name={'lastName'}
                            error={errors.lastName}
                            as={Input}
                            className={styles.field}
                        />
                        <Field
                            label={`${I18n.t('vendor.email')}*`}
                            name={'email'}
                            error={errors.email}
                            as={Input}
                            className={styles.field}
                        />
                        <Field
                            label={`${I18n.t('vendor.password')}*`}
                            name={'password'}
                            type={'password'}
                            error={errors.password}
                            as={Input}
                            className={styles.field}
                        />
                        <Field
                            label={`${I18n.t('form.signUp.confirmPasswordLabel')}*`}
                            name={'confirmPassword'}
                            type={'password'}
                            error={errors.confirmPassword}
                            as={Input}
                            className={styles.field}
                        />
                        <Button
                            type={'submit'}
                            disabled={isSubmitting}
                            className={styles.submit}
                        >
                            {I18n.t('form.submit')}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};
