import {createContactGroup} from 'api/contact-group.api';
import {ErrorMessage} from 'components/error-message';
import {Button} from 'components/html/button';

import {Input} from 'components/html/input';
import SelectInput from 'components/html/select/select';
import {TextArea} from 'components/html/textarea';
import {useModal} from 'components/modal';

import {Field, Form, Formik} from 'formik';
import {ContactGroup, CreateContactGroupResponse} from 'iterfaces/contact-group';
import {Vendor} from 'iterfaces/Vendor';
import React, {FormHTMLAttributes} from 'react';

import I18n from 'services/I18n';
import {createContactGroupFormSchema} from 'validators/contact-group';

import styles from './create-contact-group-form.module.scss';

export type CreateContactGroupFormValues = Pick<
    ContactGroup,
    'name' | 'description' | 'locale'
>;

export type CreateContactGroupFormProps = FormHTMLAttributes<HTMLFormElement> & {
    onSuccess: (response: CreateContactGroupResponse) => void;
    onCancel: () => void;
    vendor: Vendor['id'];
};

export const createContactGroupFormInitialValues: CreateContactGroupFormValues = {
    name: '',
    description: '',
    locale: 'is',
};

export const CreateContactGroupForm: React.FC<CreateContactGroupFormProps> = ({
    onSuccess,
    onCancel,
    vendor,
    className,
}) => {
    const [showModal] = useModal();
    return (
        <Formik
            onSubmit={(values) => {
                createContactGroup(
                    {
                        ...values,
                        vendor,
                    },
                    ({data}) => {
                        onSuccess(data);
                    },
                    ({code}) => {
                        showModal({
                            children: <ErrorMessage error={code} />,
                        });
                    },
                );
            }}
            validationSchema={createContactGroupFormSchema}
            initialValues={createContactGroupFormInitialValues}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({errors}) => (
                <Form className={className}>
                    <Field
                        name={'name'}
                        label={I18n.t('contactGroup.name')}
                        as={Input}
                        error={errors.name}
                    />
                    <Field
                        name={'description'}
                        label={I18n.t('contactGroup.description')}
                        as={TextArea}
                        error={errors.description}
                    />
                    <Field
                        name={'locale'}
                        label={I18n.t('contactGroup.locale')}
                        as={SelectInput}
                        error={errors.description}
                    >
                        <option value="is">Islenska</option>
                        <option value="en">Enska</option>
                        <option value="pl">Pólska</option>
                    </Field>
                    <div className={styles.controls}>
                        <Button
                            type={'button'}
                            size={'big'}
                            variant={'warning'}
                            icon={{
                                icon: 'cross',
                                size: 'medium',
                                variant: 'light',
                            }}
                            title={I18n.t('form.contactGroup.create.cancelButton')}
                            onClick={() => {
                                onCancel();
                            }}
                            className={styles.controlsButton}
                        >
                            {I18n.t('form.contactGroup.create.cancelButton')}
                        </Button>
                        <Button
                            size={'big'}
                            icon={{
                                icon: 'check',
                                size: 'medium',
                                variant: 'light',
                            }}
                            type={'submit'}
                            title={I18n.t('form.contactGroup.create.submitButton')}
                            className={styles.controlsButton}
                        >
                            {I18n.t('form.contactGroup.create.submitButton')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
