import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {Paragraph} from 'components/html/paragraph';
import {Button} from 'components/html/button';
import {Anchor} from 'components/html/anchor';

import styles from './success.module.scss';

export const Success: React.FC<RouteComponentProps> = () => (
    <>
        <Paragraph>{I18n.t('resetPassword.successMessage')}</Paragraph>
        <Anchor href={'/login'} className={styles.button}>
            <Button className={styles.button}>{I18n.t('vendor.logIn')}</Button>
        </Anchor>
    </>
);
