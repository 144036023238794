import React, {useRef} from 'react';

import xlsx from 'xlsx';

import I18n from 'services/I18n';

import {contactsImportFileSchema} from 'validators/contact';

import {Button, ButtonProps} from 'components/html/button';

import {EXPORT_HEADERS, ExportContact} from '../edit-contacts';

const handleXlsxFileUpload = async (file: File) => {
    const workBook = xlsx.read(await file.arrayBuffer(), {
        type: 'array',
    });
    return xlsx.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]], {
        header: EXPORT_HEADERS,
    }) as ExportContact[];
};

const handleFileUpload = async (file: File): Promise<ExportContact[] | null> => {
    switch (file.type) {
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return handleXlsxFileUpload(file);
        default:
            console.error('Unsupported file type');
            return null;
    }
};

export type ImportButtonProps = Partial<ButtonProps> & {
    onContactsUpload: (contacts: ExportContact[]) => void;
};

export const ImportButton: React.FC<ImportButtonProps> = ({
    onContactsUpload,
    ...restParam
}) => {
    const uploadInput = useRef<HTMLInputElement>(null);
    return (
        <>
            <input
                type={'file'}
                accept={
                    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                ref={uploadInput}
                onChange={async (event) => {
                    const file = event.target.files?.[0];
                    if (file) {
                        const uploadedContacts = await handleFileUpload(file);
                        if (uploadedContacts) {
                            if (
                                contactsImportFileSchema.validateSync(
                                    uploadedContacts,
                                )
                            ) {
                                onContactsUpload(uploadedContacts);
                            } else {
                                console.error(
                                    I18n.t(
                                        'dashboard.contacts.editContactGroupContacts.unsupportedFileStructureError',
                                    ),
                                );
                            }
                        }
                    }
                }}
                style={{
                    display: 'none',
                }}
            />
            <Button
                title={I18n.t(
                    'dashboard.contacts.editContactGroupContacts.importButton.description',
                )}
                onClick={() => {
                    uploadInput.current?.click();
                }}
                {...restParam}
            >
                {I18n.t(
                    'dashboard.contacts.editContactGroupContacts.importButton.label',
                )}
            </Button>
        </>
    );
};
