import {trackPromise} from 'react-promise-tracker';

import Api, {axiosInstance, ErrorCallback, ResponseCallback} from '../services/Api';

export const setApiAuthorization = (accessToken?: string) => {
    axiosInstance.defaults.headers.common.Authorization = accessToken
        ? `bearer ${accessToken}`
        : '';
};

export type UserLoginData = {
    email: string;
    password: string;
};

export type UserLoginResponse = {
    token: string;
};

export const loginVendor = (
    userLoginData: UserLoginData,
    onSuccess: ResponseCallback<UserLoginResponse>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.post<UserLoginData, UserLoginResponse>(
            'authentication_token',
            userLoginData,
            {
                onSuccess,
                onError,
            },
        ),
    );
};
