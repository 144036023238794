import {updateVendor} from 'api/vendor.api';
import {VendorForm} from 'components/forms/vendor-form';

import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {ImageUploadInput} from 'components/image-upload-input';
import {Modal} from 'components/modal';
import {initMediaObject, MediaObject} from 'iterfaces/media-object';
import React, {useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {useVendor} from 'store/user/hooks';

import DASHBOARD_ROUTES from '../../routes';
import styles from './settings.module.scss';

export const Settings: React.FC<RouteComponentProps> = ({history}) => {
    const [vendor, setVendor] = useVendor();
    const [saved, setSaved] = useState(false);
    return (
        <Modal
            size={'full'}
            hasCloseIcon={true}
            closeIconClickHandler={() => {
                history.push(DASHBOARD_ROUTES.START);
            }}
        >
            <Heading element={'h4'}>{I18n.t('dashboard.settings.title')}</Heading>
            {saved ? (
                <Paragraph className={styles.message}>
                    <strong>{I18n.t('dashboard.settings.successMessage')}</strong>
                </Paragraph>
            ) : null}
            <ImageUploadInput
                mediaObject={vendor.logo || initMediaObject}
                onMediaObjectChange={(mediaObject: MediaObject) => {
                    updateVendor(
                        vendor.id,
                        {
                            logo: mediaObject.id,
                        },
                        () => {
                            setSaved(true);
                            setVendor({...vendor, logo: mediaObject});
                        },
                        console.error,
                    );
                }}
                className={styles.imageUpload}
            />
            <VendorForm
                vendorId={vendor.id}
                onSuccess={(vendor) => {
                    setVendor(vendor);
                    setSaved(true);
                }}
                initialValues={{
                    firstName: vendor.firstName,
                    lastName: vendor.lastName,
                }}
                className={styles.form}
            />
        </Modal>
    );
};
