import {ReactComponent as Chart} from './static/icon-chart.svg';
import {ReactComponent as Check} from './static/icon-check.svg';
import {ReactComponent as Cross} from './static/icon-cross.svg';
import {ReactComponent as Minus} from './static/icon-minus.svg';
import {ReactComponent as Pen} from './static/icon-pen.svg';
import {ReactComponent as Template} from './static/icon-template.svg';
import {ReactComponent as Plus} from './static/icon-plus.svg';
import {ReactComponent as Preview} from './static/icon-preview.svg';
import {ReactComponent as Send} from './static/icon-send.svg';
import {ReactComponent as Settings} from './static/icon-settings.svg';
import {ReactComponent as Logout} from './static/icon-logout.svg';

export * from './icon';

export const iconElements = {
    chart: Chart,
    check: Check,
    cross: Cross,
    minus: Minus,
    pen: Pen,
    template: Template,
    plus: Plus,
    preview: Preview,
    send: Send,
    settings: Settings,
    logout: Logout,
};
