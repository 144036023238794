import * as yup from 'yup';
import I18n from 'services/I18n';

import {emailSchema} from './email';

export const firstNameSchema = yup
    .string()
    .max(180, I18n.t('validation.firstName.tooLong', {number: 180}));

export const lastNameSchema = yup
    .string()
    .max(180, I18n.t('validation.lastName.tooLong', {number: 180}));

export const passwordSchema = yup
    .string()
    .min(6, I18n.t('validation.password.tooShort', {number: 6}));

export const logInSchema = yup.object().shape({
    email: emailSchema.required(I18n.t('validation.email.missing')),
    password: passwordSchema.required(I18n.t('validation.password.missing')),
});

export const signUpSchema = yup.object().shape({
    firstName: firstNameSchema.required(I18n.t('validation.firstName.missing')),
    lastName: lastNameSchema.required(I18n.t('validation.lastName.missing')),
    email: emailSchema.required(I18n.t('validation.email.missing')),
    password: passwordSchema.required(I18n.t('validation.password.missing')),
    confirmPassword: passwordSchema
        .required(I18n.t('validation.password.notConfirmed'))
        .oneOf(
            [yup.ref('password'), null],
            I18n.t('validation.password.notMatching'),
        ),
});

export const forgotPasswordSchema = yup.object().shape({
    email: emailSchema.required(I18n.t('validation.email.missing')),
});

export const resetPasswordSchema = yup.object().shape({
    password: passwordSchema.required(I18n.t('validation.password.missing')),
    confirmPassword: passwordSchema
        .required(I18n.t('validation.password.notConfirmed'))
        .oneOf(
            [yup.ref('password'), null],
            I18n.t('validation.password.notMatching'),
        ),
});

export const editVendorSchema = yup.object().shape({
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    password: passwordSchema,
    confirmPassword: passwordSchema.oneOf(
        [yup.ref('password')],
        I18n.t('validation.password.notMatching'),
    ),
});
