import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {Paragraph} from 'components/html/paragraph';
import {ResetPasswordForm} from 'components/forms/reset-password-form';

import {pathnames} from '../../reset-password';

import styles from './action.module.scss';

export type ActionProps = {
    token: string;
};

export const Action: React.FC<ActionProps & RouteComponentProps> = ({
    token,
    history,
}) => (
    <>
        <Paragraph>{I18n.t('resetPassword.description')}</Paragraph>
        <div className={styles.formContainer}>
            <ResetPasswordForm
                onSuccess={() => {
                    history.push(pathnames.success);
                }}
                token={token}
            />
        </div>
    </>
);
