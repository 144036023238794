import {Survey} from 'iterfaces/survey';
import {CreateSurveyFormValues} from '../';

export const mapSurveyToCreateSurveyFormValues = (
    survey: Survey,
    locale = 'is',
): CreateSurveyFormValues => {
    return {
        ...survey,
        name: survey.translations[locale]?.name || '',
        description: survey.translations[locale]?.description || '',
        //eslint-disable-next-line
        //@ts-ignore
        sections: survey.sections.map((section) => ({
            ...section,
            name: section.translations[locale]?.name || '',
            questions: section.questions.map((question) => ({
                ...question,
                name: question.translations[locale]?.name || '',
                type: question.multiple ? 'multipleChoice' : 'singleChoice',
                answers: question.answers.map((answer) => ({
                    ...answer,
                    name: answer.translations[locale]?.name || '',
                })),
            })),
        })),
    };
};
