import {
    CloneSurveyRequest,
    CloneSurveyResponse,
    CreateAnswerRequest,
    CreateAnswerResponse,
    CreateQuestionRequest,
    CreateQuestionResponse,
    CreateSectionRequest,
    CreateSectionResponse,
    CreateSurveyRequest,
    CreateSurveyResponse,
    Question,
    Section,
    Survey,
} from 'iterfaces/survey';
import queryString from 'query-string';
import {trackPromise} from 'react-promise-tracker';
import Api, {ErrorCallback, ObjectType, ResponseCallback} from 'services/Api';

export const publishSurvey = (
    data: {survey: number},
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.post<{survey: number}, ObjectType>('survey_publish', data, {
            onSuccess,
            onError,
        }),
    );
};

export const createSurvey = (
    data: CreateSurveyRequest,
    onSuccess: ResponseCallback<CloneSurveyResponse>,
    onError: ErrorCallback,
) => {
    Api.post<CreateSurveyRequest, CloneSurveyResponse>('surveys', data, {
        onSuccess,
        onError,
    });
};

export const cloneSurvey = (
    data: CloneSurveyRequest,
    onSuccess: ResponseCallback<CreateSurveyResponse>,
    onError: ErrorCallback,
) => {
    Api.post<CloneSurveyRequest, CreateSurveyResponse>('surveys/clone', data, {
        onSuccess,
        onError,
    });
};

export const createSection = (
    data: CreateSectionRequest,
    onSuccess: ResponseCallback<CreateSectionResponse>,
    onError: ErrorCallback,
) => {
    Api.post<CreateSectionRequest, CreateSectionResponse>('sections', data, {
        onSuccess,
        onError,
    });
};

export const createQuestion = (
    data: CreateQuestionRequest,
    onSuccess: ResponseCallback<CreateQuestionResponse>,
    onError: ErrorCallback,
) => {
    Api.post<CreateQuestionRequest, CreateQuestionResponse>('questions', data, {
        onSuccess,
        onError,
    });
};

export const createAnswer = (
    data: CreateAnswerRequest,
    onSuccess: ResponseCallback<CreateAnswerResponse>,
    onError: ErrorCallback,
) => {
    Api.post<CreateAnswerRequest, CreateAnswerResponse>('answers', data, {
        onSuccess,
        onError,
    });
};

export const readSurvey = (
    id: number,
    onSuccess: ResponseCallback<Survey>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<Survey>(`surveys/${id}`, {
            onSuccess,
            onError,
        }),
    );
};

export const readSurveys = (
    params: {[param: string]: boolean | number},
    onSuccess: ResponseCallback<Survey[]>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<Survey[]>(
            `surveys?itemsPerPage=10&order[id]=desc&${queryString.stringify(
                params,
            )}`,
            {
                onSuccess,
                onError,
            },
        ),
    );
};

export const updateSurvey = (
    id: number,
    data: Partial<CreateSurveyRequest>,
    onSuccess: ResponseCallback<CreateSurveyResponse>,
    onError: ErrorCallback,
) => {
    Api.put<Partial<CreateSurveyRequest>, CreateSurveyResponse>(
        `surveys/${id}`,
        data,
        {
            onSuccess,
            onError,
        },
    );
};

export const updateSection = (
    id: number,
    data: Partial<CreateSectionRequest>,
    onSuccess: ResponseCallback<CreateSectionResponse>,
    onError: ErrorCallback,
) => {
    Api.put<Partial<CreateSectionRequest>, CreateSectionResponse>(
        `sections/${id}`,
        data,
        {
            onSuccess,
            onError,
        },
    );
};

export const deleteAnswer = (
    id: number,
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    Api.delete(`answers/${id}`, {
        onSuccess,
        onError,
    });
};

export const deleteSection = (
    id: Section['id'],
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    Api.delete(`sections/${id}`, {
        onSuccess,
        onError,
    });
};

export const updateQuestion = (
    id: number,
    data: Partial<CreateQuestionRequest>,
    onSuccess: ResponseCallback<CreateQuestionResponse>,
    onError: ErrorCallback,
) => {
    Api.put<Partial<CreateQuestionRequest>, CreateQuestionResponse>(
        `questions/${id}`,
        data,
        {
            onSuccess,
            onError,
        },
    );
};

export const deleteQuestion = (
    id: Question['id'],
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    Api.delete(`questions/${id}`, {
        onSuccess,
        onError,
    });
};

export const updateAnswer = (
    id: number,
    data: Partial<CreateAnswerRequest>,
    onSuccess: ResponseCallback<CreateAnswerResponse>,
    onError: ErrorCallback,
) => {
    Api.put<Partial<CreateAnswerRequest>, CreateAnswerResponse>(
        `answers/${id}`,
        data,
        {
            onSuccess,
            onError,
        },
    );
};

export const deleteDurvey = (
    id: Survey['id'],
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.delete(`surveys/${id}`, {
            onSuccess,
            onError,
        }),
    );
};
