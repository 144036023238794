import React, {useState} from 'react';

import xlsx from 'xlsx';

import I18n from 'services/I18n';

import {downloadFile} from 'utils/download-file';
import {jsonToCsv} from 'utils/json-to-csv';
import {Contact} from 'iterfaces/contact';

import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {Input} from 'components/html/input';
import {Button} from 'components/html/button';

import {EXPORT_HEADERS, ExportContact} from '../edit-contacts';

import styles from './export-dialog.module.scss';

const handleCsvDownload = (contacts: ExportContact[]) => {
    downloadFile(jsonToCsv(contacts), 'contacts.csv');
};

const handleXlsDownload = (contacts: ExportContact[]) => {
    const workBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(
        workBook,
        xlsx.utils.json_to_sheet(contacts, {
            header: EXPORT_HEADERS,
            skipHeader: true,
        }),
    );
    const buffer = xlsx.write(workBook, {type: 'array', bookType: 'xls'});
    const file = new Blob([buffer], {type: 'application/octet-stream'});
    downloadFile(file, 'contacts.xls');
};

const handleDownload = (extension: 'csv' | 'xls', contacts: Contact[]) => {
    const data = contacts.map((contact) => ({
        name: contact.name,
        email: contact.email,
    }));
    if (extension === 'csv') {
        handleCsvDownload(data);
    } else if (extension === 'xls') {
        handleXlsDownload(data);
    }
};

export type ExportDialogProps = {
    contacts: Contact[];
};

export const ExportDialog: React.FC<ExportDialogProps> = ({contacts}) => {
    const [extension, setExtension] = useState<'csv' | 'xls'>('csv');
    return (
        <>
            <Heading element={'h3'}>
                {I18n.t(
                    'dashboard.contacts.editContactGroupContacts.exportDialog.title',
                )}
            </Heading>
            <Paragraph className={styles.description}>
                {I18n.t(
                    'dashboard.contacts.editContactGroupContacts.exportDialog.description',
                )}
            </Paragraph>
            <div className={styles.fields}>
                <Input
                    name={'extension'}
                    type={'radio'}
                    label={'csv'}
                    value={'csv'}
                    onChange={(event) => {
                        const value = event.target.value as 'csv';
                        setExtension(value);
                    }}
                    defaultChecked={true}
                />
                <Input
                    name={'extension'}
                    type={'radio'}
                    label={'xls'}
                    value={'xls'}
                    onChange={(event) => {
                        const value = event.target.value as 'xls';
                        setExtension(value);
                    }}
                />
            </div>
            <Button
                onClick={() => {
                    handleDownload(extension, contacts);
                }}
                className={styles.button}
                title={I18n.t(
                    'dashboard.contacts.editContactGroupContacts.exportDialog.downloadButton',
                )}
            >
                {I18n.t(
                    'dashboard.contacts.editContactGroupContacts.exportDialog.downloadButton',
                )}
            </Button>
        </>
    );
};
