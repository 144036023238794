import {Survey} from 'iterfaces/survey';

import {useDispatch, useSelector} from 'react-redux';

import {CreateSurveyFormValues} from 'components/forms/survey-form';
import {MediaObject} from 'iterfaces/media-object';

import {Store} from '../';

import {setSurveys, setSurveyForm, setSurveyLogo} from './action-creators';

export const useSurveys = (): [Survey[], (surveys: Survey[]) => void] => {
    const dispatch = useDispatch();
    return [
        useSelector<Store, Survey[]>((state) => state.survey.surveys),
        (surveys: Survey[]) => dispatch(setSurveys(surveys)),
    ];
};

export const useSurveyForm = (): [
    CreateSurveyFormValues,
    (surveyForm: CreateSurveyFormValues) => void,
] => {
    const dispatch = useDispatch();
    return [
        useSelector<Store, CreateSurveyFormValues>(
            (state) => state.survey.surveyForm,
        ),
        (surveyForm: CreateSurveyFormValues) => dispatch(setSurveyForm(surveyForm)),
    ];
};

export const useSurveyLogo = (): [
    MediaObject,
    (surveyLogo: MediaObject) => void,
] => {
    const dispatch = useDispatch();
    return [
        useSelector<Store, MediaObject>((state) => state.survey.surveyLogo),
        (surveyLogo: MediaObject) => dispatch(setSurveyLogo(surveyLogo)),
    ];
};
