import React from 'react';
import {Provider} from 'react-redux';

import {usePromiseTracker} from 'react-promise-tracker';

import {Loader} from 'components/loader';
import {ModalProvider} from 'components/modal';

import store from '../store';

import AppRouting from './AppRouting';

const App: React.FC = () => {
    const {promiseInProgress} = usePromiseTracker();

    return (
        <Provider store={store}>
            <ModalProvider>
                <AppRouting />
                {promiseInProgress ? <Loader /> : null}
            </ModalProvider>
        </Provider>
    );
};

export default App;
