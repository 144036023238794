import {deleteDurvey, readSurveys} from 'api/survey.api';
import {ActionDataTable} from 'components/action-data-table';
import {ErrorMessage} from 'components/error-message';
import {Anchor} from 'components/html/anchor';
import {Button} from 'components/html/button';
import {Heading} from 'components/html/heading';
import {Icon} from 'components/icon';
import {Modal, useModal} from 'components/modal';
import {Survey} from 'iterfaces/survey';
import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import I18n from 'services/I18n';
import {useTemplates} from 'store/templates/hooks';
import DASHBOARD_ROUTES from '../../../../routes';
import SURVEYS_ROUTES from '../../routes';
import styles from './start.module.scss';

const useTemplatesSubscription = (
    page = 1,
): [
    Survey[],
    (callback?: () => void) => void,
    (callback?: () => void) => void,
    boolean,
    (callback?: () => void) => void,
] => {
    const [_templates, _setTemplates] = useTemplates();
    const [templates, setTemplates] = useState<Survey[]>([]);
    const [_page, setPage] = useState<number>(page);
    const [noMore, setNoMore] = useState<boolean>(false);
    const [showModal] = useModal();

    const updateValues = (callback?: () => void) => {
        readSurveys(
            {template: false, page: _page},
            ({data}) => {
                setTemplates(data);
                _setTemplates([..._templates, ...data]);
                setPage(_page + 1);
                if (callback) {
                    callback();
                }
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    const refresh = (callback?: () => void) => {
        readSurveys(
            {template: false, page: 1},
            ({data}) => {
                setTemplates(data);
                _setTemplates([..._templates, ...data]);
                setPage(2);
                if (callback) {
                    callback();
                }
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    const loadMore = (callback?: () => void) => {
        readSurveys(
            {template: false, page: _page},
            ({data}) => {
                setTemplates([...templates, ...data]);
                _setTemplates([..._templates, ...data]);
                setPage(_page + 1);
                if (data.length === 0) {
                    setNoMore(true);
                }
                window.scrollTo({top: 999999999, behavior: 'smooth'});
                callback?.();
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    useEffect(() => {
        updateValues();
    }, []);

    return [templates, updateValues, loadMore, noMore, refresh];
};

export const Start: React.FC<RouteComponentProps> = ({history}) => {
    //eslint-disable-next-line
    const [subscription, updateSubscription, loadMore, noMore, refresh] =
        useTemplatesSubscription(1);
    const [showModal] = useModal();

    return (
        <div className={styles.container}>
            <Modal size={'full'} className={styles.modal}>
                <Heading element={'h4'} className={styles.heading}>
                    {I18n.t('dashboard.surveys.title')}
                </Heading>
                <Anchor
                    onClick={() => {
                        history.push(DASHBOARD_ROUTES.START);
                    }}
                    className={styles.button}
                    title={I18n.t('navigation.return')}
                    transitions={false}
                >
                    <Icon icon={'cross'} size={'medium'} />
                </Anchor>
                <ActionDataTable<Survey>
                    data={subscription}
                    columns={[
                        {
                            readableName: 'name',
                            value: 'translations.is.name',
                        },
                        'published',
                        'publishedDate',
                    ]}
                    languages={[
                        {
                            locale: 'en',
                            onClick: ({id}) => {
                                history.push(SURVEYS_ROUTES.EDIT_SURVEY(id, 'en'));
                            },
                        },
                        {
                            locale: 'pl',
                            onClick: ({id}) => {
                                history.push(SURVEYS_ROUTES.EDIT_SURVEY(id, 'pl'));
                            },
                        },
                    ]}
                    columnWidths={{
                        name: '20%',
                        publishedDate: '1%',
                    }}
                    mapHandlers={{
                        published: (value) => {
                            if (value) {
                                return <Icon icon={'check'} />;
                            }
                            return <Icon icon={'cross'} variant={'ghost'} />;
                        },
                        publishedDate: (value) => {
                            if (value) {
                                const date = new Date(value);
                                if (date) {
                                    return date.toISOString().split('T')[0];
                                }
                            }
                            return '-';
                        },
                    }}
                    actions={[
                        {
                            button: {
                                icon: {
                                    icon: 'pen',
                                },
                                // title: I18n.t('dashboard.surveys.table.editButton'),
                                // children: I18n.t(
                                //     'dashboard.surveys.table.editButton',
                                // ),
                            },
                            // test: ({published}) => !published,
                            onClick: ({id}) => {
                                history.push(SURVEYS_ROUTES.EDIT_SURVEY(id));
                            },
                        },
                        {
                            button: {
                                icon: {
                                    icon: 'minus',
                                },
                                // title: I18n.t(
                                //     'dashboard.templates.table.deleteButton',
                                // ),
                                // children: I18n.t(
                                //     'dashboard.templates.table.deleteButton',
                                // ),
                            },
                            test: ({published}) => !published,
                            onClick: ({id}) => {
                                if (window.confirm(I18n.t('survey.deleteAlert'))) {
                                    deleteDurvey(
                                        id,
                                        () => {
                                            refresh();
                                        },
                                        ({code}) => {
                                            showModal({
                                                children: (
                                                    <ErrorMessage error={code} />
                                                ),
                                            });
                                        },
                                    );
                                }
                            },
                        },
                    ]}
                    readableNames={{
                        name: I18n.t('survey.name'),
                        publishedDate: I18n.t('survey.publishedDate'),
                    }}
                    className={styles.table}
                />
                {!noMore ? (
                    <div className={styles.loadMore} onClick={() => loadMore()}>
                        {I18n.t('dashboard.templates.loadMore')}
                    </div>
                ) : (
                    <span className={styles.noMoreItems}>
                        {I18n.t('dashboard.templates.noMoreItems')}
                    </span>
                )}
            </Modal>
            <div className={styles.navigation}>
                <Button
                    onClick={() => {
                        history.push(SURVEYS_ROUTES.CREATE);
                    }}
                    size={'big'}
                    variant={'primary'}
                    title={I18n.t('dashboard.survey.newSurvey')}
                    icon={{icon: 'template', size: 'medium', variant: 'light'}}
                    className={styles.button}
                >
                    {I18n.t('dashboard.survey.newSurvey')}
                </Button>
            </div>
        </div>
    );
};
