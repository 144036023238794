import React, {TableHTMLAttributes} from 'react';

import join from 'lodash/join';

import styles from './table.module.scss';

export type TableProps = TableHTMLAttributes<HTMLTableElement> & {
    variant?: 'compact' | 'spacious';
};

export const Table: React.FC<TableProps> = ({
    variant = 'compact',
    children,
    className,
    ...restParam
}) => {
    return (
        <table
            className={join(
                [styles.table, styles[variant], className ? className : ''],
                ' ',
            )}
            {...restParam}
        >
            {children}
        </table>
    );
};
