import Api, {ErrorCallback, ResponseCallback} from 'services/Api';
import {trackPromise} from 'react-promise-tracker';

import {
    ContactGroup,
    CreateContactGroupRequest,
    CreateContactGroupResponse,
} from 'iterfaces/contact-group';

export const createContactGroup = (
    data: CreateContactGroupRequest,
    onSuccess: ResponseCallback<CreateContactGroupResponse>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.post<CreateContactGroupRequest, CreateContactGroupResponse>(
            'contact_groups',
            data,
            {
                onSuccess,
                onError,
            },
        ),
    );
};

export const readContactGroups = (
    onSuccess: ResponseCallback<ContactGroup[]>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<ContactGroup[]>('contact_groups?page=1&itemsPerPage=1000', {
            onSuccess,
            onError,
        }),
    );
};

export const deleteContactGroup = (
    id: ContactGroup['id'],
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.delete(`contact_groups/${id}`, {
            onSuccess,
            onError,
        }),
    );
};
