import {createRecipientAnswer} from 'api/recipient-answer.api';

import {readRecipient} from 'api/recipient.api';
import {ErrorMessage} from 'components/error-message';
import {
    mapSurveySectionsToRecipientFormValues,
    RecipientForm,
    RecipientFormValues,
} from 'components/forms/recipient-form';
import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {ImageUploadInput} from 'components/image-upload-input';

import {Modal, useModal} from 'components/modal';

import styles from 'containers/survey/survey.module.scss';
import {Recipient} from 'iterfaces/recipient';
import React, {useEffect, useState} from 'react';

import {trackPromise} from 'react-promise-tracker';
import {RouteComponentProps} from 'react-router-dom';
import {AxiosErrorData} from 'services/Api';

import I18n from 'services/I18n';

const handleCreateAnswersRequest = (
    token: string,
    values: RecipientFormValues,
    onSuccess: () => void,
    onError: (error: AxiosErrorData) => void,
) => {
    trackPromise(
        Promise.all(
            values.selectedAnswers.map((selectedAnswer) => {
                if (selectedAnswer.multiple && selectedAnswer.isSelected) {
                    return new Promise((resolve, reject) => {
                        createRecipientAnswer(
                            {
                                answer: selectedAnswer.answerId,
                                token,
                            },
                            () => {
                                resolve(void 0);
                            },
                            (error) => {
                                reject();
                                onError(error);
                            },
                        );
                    });
                } else if (!selectedAnswer.multiple) {
                    return new Promise((resolve, reject) => {
                        createRecipientAnswer(
                            {
                                answer: parseInt(selectedAnswer.answerId),
                                token,
                            },
                            () => {
                                resolve(void 0);
                            },
                            (error) => {
                                reject();
                                onError(error);
                            },
                        );
                    });
                }
            }),
        ).then(() => {
            onSuccess();
        }),
    );
};

const useRecipientSubscription = (
    token: string,
): [Recipient | undefined, boolean] => {
    const [recipient, setRecipient] = useState<Recipient | undefined>(undefined);
    const [alreadyAnswered, setAlreadyAnswered] = useState(false);
    const [appendModal] = useModal();

    useEffect(() => {
        readRecipient(
            token,
            ({data}) => {
                setRecipient(data);
            },
            ({code}) => {
                if (code === '302') {
                    setAlreadyAnswered(true);
                } else {
                    appendModal({
                        children: <ErrorMessage error={code} />,
                    });
                }
            },
        );
    }, []);

    return [recipient, alreadyAnswered];
};

export const Survey: React.FC<RouteComponentProps<{token: string}>> = ({match}) => {
    const {token} = match.params;
    const [recipientSubscription, alreadyAnswered] = useRecipientSubscription(token);
    const [answersSent, setAnswersSent] = useState(false);
    const [appendModal] = useModal();

    return (
        <>
            {alreadyAnswered ? (
                <Modal
                    isFixed={true}
                    size={'compact'}
                    concreteBackground={true}
                    className={styles.dialog}
                >
                    <Heading element={'h1'}>
                        {I18n.t('survey.alreadyAnsweredDialog.title')}
                    </Heading>
                    <Paragraph className={styles.dialogDescription}>
                        {I18n.t('survey.alreadyAnsweredDialog.description')}
                    </Paragraph>
                </Modal>
            ) : answersSent ? (
                <Modal
                    isFixed={true}
                    size={'compact'}
                    concreteBackground={true}
                    className={styles.dialog}
                >
                    <Heading element={'h1'}>
                        {I18n.t('survey.successfullySentDialog.title')}
                    </Heading>
                    <Paragraph className={styles.dialogDescription}>
                        {I18n.t('survey.successfullySentDialog.description')}
                    </Paragraph>
                </Modal>
            ) : recipientSubscription ? (
                <div className={styles.container}>
                    <Modal>
                        <Heading element={'h1'}>{I18n.t('app.title')}</Heading>
                        {recipientSubscription.survey.logo ? (
                            <ImageUploadInput
                                mediaObject={recipientSubscription.survey.logo}
                                allowChange={false}
                                className={styles.logo}
                            />
                        ) : null}
                        <Heading element={'h3'} className={styles.surveyHeading}>
                            {recipientSubscription.survey.name}
                        </Heading>
                        <Paragraph className={styles.surveyDescription}>
                            {recipientSubscription.survey.description}
                        </Paragraph>
                        <RecipientForm
                            initialValues={mapSurveySectionsToRecipientFormValues(
                                recipientSubscription.locale,
                                recipientSubscription.survey.sections,
                            )}
                            onSuccess={(values) => {
                                handleCreateAnswersRequest(
                                    token,
                                    values,
                                    () => {
                                        setAnswersSent(true);
                                    },
                                    ({code}) => {
                                        appendModal({
                                            children: <ErrorMessage error={code} />,
                                        });
                                    },
                                );
                            }}
                            className={styles.form}
                        />
                        <Paragraph fontSize={'small'} className={styles.formatHint}>
                            {I18n.t('survey.disclaimer')}
                        </Paragraph>
                    </Modal>
                </div>
            ) : null}
        </>
    );
};
