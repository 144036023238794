import React from 'react';
import {MemoryRouter, Route, Switch, RouteComponentProps} from 'react-router-dom';

import {Modal} from 'components/modal';

import {Action} from './routes/action';
import {Success} from './routes/success';

export const pathnames = {
    action: '/action',
    success: '/success',
};

export const ResetPassword: React.FC<RouteComponentProps<{token: string}>> = ({
    match,
}) => (
    <Modal size={'compact'}>
        <MemoryRouter initialEntries={Object.values(pathnames)} initialIndex={0}>
            <Switch>
                <Route
                    path={pathnames.action}
                    component={(props: RouteComponentProps) => (
                        <Action token={match.params.token} {...props} />
                    )}
                    //eslint-disable-next-line
                    //@ts-ignore
                    token={match.params.token}
                />
                <Route path={pathnames.success} component={Success} />
            </Switch>
        </MemoryRouter>
    </Modal>
);
