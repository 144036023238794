import {FormikErrors, FormikValues} from 'formik';
import {AxiosErrorData} from 'services/Api';

export function mapViolationsToFieldErrors<T extends FormikValues = FormikValues>(
    violations: AxiosErrorData['violations'],
): FormikErrors<T> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Object.fromEntries(
        violations.map(({propertyPath, message}) => [propertyPath, message]),
    );
}
