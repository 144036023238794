import {createSurvey, updateSurvey} from 'api/survey.api';
import {ErrorMessage} from 'components/error-message';

import {Anchor} from 'components/html/anchor';
import {Input} from 'components/html/input';
import {TextArea} from 'components/html/textarea';
import {Icon} from 'components/icon';
import {ImageUploadInput} from 'components/image-upload-input';

import {useModal} from 'components/modal';
import {Field, Form, Formik, getIn} from 'formik';
import {FormPartial} from 'iterfaces/form';
import {initMediaObject, MediaObject} from 'iterfaces/media-object';
import {get} from 'lodash';
import React, {FocusEvent, KeyboardEvent, useEffect, useState} from 'react';
import ReactTooltip from 'react-tooltip';

import I18n from 'services/I18n';
import {useSurveyForm, useSurveyLogo} from 'store/survey/hooks';

import {useVendor} from 'store/user/hooks';
import {createSurveyFormSchema} from 'validators/survey';

import {InferType} from 'yup';

import {fillCreateSurveyFormInitialValues} from './';

import {surveyInit} from './initial-values';
import {Controls} from './partials/controls';

import {Sections} from './partials/sections';

import styles from './survey-form.module.scss';

export type CreateSurveyFormValues = InferType<typeof createSurveyFormSchema>;

export type CreateSurveyFormProps = {
    onSuccess: () => void;
    onCancel: () => void;
    initialValues?: CreateSurveyFormValues;
    isTemplateForm: boolean;
    locale?: string;
};

export const Survey: React.FC<
    {
        isTemplateForm: CreateSurveyFormProps['isTemplateForm'];
        locale?: CreateSurveyFormProps['locale'];
    } & FormPartial<CreateSurveyFormValues>
> = ({isTemplateForm, locale = 'is', ...restParam}) => {
    const {values, errors, touched, handleBlur, setFieldValue} = restParam;
    const [, setSurveyUpdated] = useState(false);
    const [surveyId, setSurveyId] = useState(getIn(values, `id`) as number);
    const [vendor] = useVendor();
    const [appendModal] = useModal();
    useEffect(() => {
        setFieldValue('id', surveyId);
    }, [surveyId]);
    const [, setSurveyLogo] = useSurveyLogo();

    useEffect(() => {
        if (!surveyId) {
            createSurvey(
                {
                    newTranslations: {
                        [locale]: {
                            name: values.name,
                            description: values.description,
                        },
                    },
                    vendor: vendor.id,
                    template: isTemplateForm,
                },
                ({data}) => {
                    setSurveyId(data.id);
                },
                ({code}) => {
                    if (/[53]../.test(code)) {
                        appendModal({children: <ErrorMessage error={code} />});
                    }
                },
            );
        }
    }, []);

    const handleUpdateSurvey = (key: 'description' | 'name') => {
        if (!getIn(errors, key)) {
            updateSurvey(
                surveyId,
                {
                    newTranslations: {
                        [locale]: {
                            name: getIn(values, 'name') || '',
                            description: getIn(values, 'description') || '',
                        },
                    },
                },
                () => {
                    setSurveyUpdated(true);
                },
                ({code}) => {
                    if (/[53]../.test(code)) {
                        appendModal({children: <ErrorMessage error={code} />});
                    }
                },
            );
        }
    };

    return (
        <>
            <ReactTooltip />
            <div className={styles.title}>
                {values?.translations?.is?.name
                    ? values.translations.is.name
                    : I18n.t('unnamed')}
                <span className={styles.titleLanguage}>
                    {locale !== 'is' ? `: ${locale.toUpperCase()}` : ''}
                </span>
            </div>
            <div className={styles.sectionInner}>
                <div className={styles.row}>
                    <Field
                        label={I18n.t('survey.name')}
                        name={'name'}
                        error={
                            get(values, `translations.${locale}.name`)
                                ? getIn(errors, 'name')
                                : locale !== 'is'
                                ? 'Þýðingu vantar'
                                : getIn(errors, 'name')
                        }
                        as={Input}
                        placeholder={get(values, `translations.is.name`, '')}
                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                            if (!touched.name) {
                                event.target.select();
                            }
                        }}
                        onBlur={(event: FocusEvent<HTMLInputElement>) => {
                            handleBlur(event);
                            handleUpdateSurvey('name');
                        }}
                        autoComplete={'off'}
                        disabled={values.published}
                    />
                    {locale !== 'is' ? (
                        <Anchor
                            className={styles.icon}
                            transitions={false}
                            data-tip={get(values, `translations.is.name`, '')}
                        >
                            <Icon icon={'preview'} size={'medium'} />
                        </Anchor>
                    ) : null}
                </div>
            </div>
            <div className={styles.row}>
                <Field
                    label={I18n.t('survey.description')}
                    name={'description'}
                    error={
                        get(values, `translations.${locale}.description`)
                            ? getIn(errors, 'description')
                            : locale !== 'is'
                            ? 'Þýðingu vantar'
                            : getIn(errors, 'description')
                    }
                    as={TextArea}
                    placeholder={get(values, `translations.is.description`, '')}
                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                        if (!touched.description) {
                            event.target.select();
                        }
                    }}
                    onBlur={(event: FocusEvent<HTMLInputElement>) => {
                        handleBlur(event);
                        handleUpdateSurvey('description');
                    }}
                    autoComplete={'off'}
                    disabled={values.published}
                />
                {locale !== 'is' ? (
                    <Anchor
                        className={styles.icon}
                        transitions={false}
                        data-tip={get(values, `translations.is.description`, '')}
                    >
                        <Icon icon={'preview'} size={'medium'} />
                    </Anchor>
                ) : null}
            </div>
            {!isTemplateForm ? (
                <Field
                    label={I18n.t('survey.logo')}
                    name={'logo.id'}
                    as={ImageUploadInput}
                    mediaObject={values.logo || initMediaObject}
                    onMediaObjectChange={(image: MediaObject) => {
                        setFieldValue('logo', image);
                        setSurveyLogo(image);
                        updateSurvey(
                            surveyId,
                            {
                                logo: image.id,
                            },
                            () => {
                                setSurveyUpdated(true);
                            },
                            ({code}) => {
                                appendModal({
                                    children: <ErrorMessage error={code} />,
                                });
                            },
                        );
                    }}
                    className={styles.logoInput}
                    disabled={values.published}
                    allowChange={values.published ? false : true}
                />
            ) : null}
            <Sections
                path={'sections'}
                locale={locale}
                surveyId={surveyId}
                {...restParam}
            />
        </>
    );
};

export const SurveyForm: React.FC<CreateSurveyFormProps> = ({
    isTemplateForm = false,
    locale = 'is',
    onSuccess,
    onCancel,
    initialValues,
}) => {
    const [, setSurveyForm] = useSurveyForm();
    return (
        <Formik
            validationSchema={createSurveyFormSchema}
            onSubmit={(values) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setSurveyForm(values);
                onSuccess();
            }}
            initialValues={
                fillCreateSurveyFormInitialValues(initialValues) || surveyInit
            }
            validateOnChange={true}
            validateOnBlur={true}
        >
            {(formikProps) => (
                <Form
                    className={styles.form}
                    onKeyDown={(e: KeyboardEvent<HTMLFormElement>) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                >
                    {/*
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore */}
                    <Survey
                        locale={locale}
                        isTemplateForm={isTemplateForm}
                        {...formikProps}
                    />
                    {/*
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore */}
                    <Controls
                        onCancel={onCancel}
                        isTemplateForm={isTemplateForm}
                        locale={locale}
                        {...formikProps}
                    />
                </Form>
            )}
        </Formik>
    );
};
