import React, {HTMLAttributes} from 'react';

import {Button, ButtonProps} from 'components/html/button';

import classNames from 'classnames';

import styles from './navigation.module.scss';

export type NavigationProps = HTMLAttributes<HTMLElement> & {
    buttons: Array<
        ButtonProps & {
            isActive?: boolean;
        }
    >;
};

export const Navigation: React.FC<NavigationProps> = ({buttons, className}) => {
    return (
        <nav className={classNames(styles.container, className)}>
            {buttons.map(({isActive, ...button}, index) => (
                <Button
                    key={index}
                    {...button}
                    className={classNames(styles.button, {
                        [styles.activeButton]: isActive,
                    })}
                    variant={isActive ? 'secondary' : 'primary'}
                />
            ))}
        </nav>
    );
};
