import React, {AnchorHTMLAttributes} from 'react';

import join from 'lodash/join';

import styles from './anchor.module.scss';

export type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
    transitions?: boolean;
    disabled?: boolean;
    fontSize?: 'normal' | 'small';
};

export const Anchor: React.FC<AnchorProps> = ({
    transitions = true,
    disabled = false,
    fontSize = 'normal',
    className,
    ...restParam
}) => {
    return (
        <a
            className={join(
                [
                    styles.anchor,
                    transitions ? styles.transitions : '',
                    disabled ? styles.disabled : '',
                    className ? className : '',
                    fontSize === 'small' ? styles.smallFontSize : '',
                ],
                ' ',
            )}
            {...restParam}
        >
            {restParam.children}
        </a>
    );
};
