import {createAnswer, deleteAnswer, updateAnswer} from 'api/survey.api';

import {ErrorMessage} from 'components/error-message';
import {Anchor} from 'components/html/anchor';
import {Input} from 'components/html/input';
import {Icon} from 'components/icon';
import {useModal} from 'components/modal';

import {FastField, FieldArray, getIn} from 'formik';
import {FormPartial, FormPartialWithArrayHelpers} from 'iterfaces/form';
import {get} from 'lodash';
import React, {ChangeEvent, FocusEvent, useState} from 'react';

import I18n from 'services/I18n';
import {answerInit} from '../../initial-values';

import {CreateSurveyFormValues} from '../../survey-form';

import styles from '../../survey-form.module.scss';

export const Answer: React.FC<
    {
        path: string;
        questionId: number;
        locale?: string;
        index: number;
    } & FormPartialWithArrayHelpers<CreateSurveyFormValues>
> = ({path, questionId, locale = 'is', index, ...restParam}) => {
    const {
        values,
        errors,
        touched,
        remove,
        handleChange,
        handleBlur,
        validateField,
    } = restParam;
    const currentPath = `${path}[${index}]`;
    const initialId = getIn(values, `${currentPath}.id`) as number;
    const disabled = !questionId;
    const [answerCreated, setAnswerCreated] = useState(!!initialId);
    const [, setAnswerUpdated] = useState(false);
    const [answerId, setAnswerId] = useState(initialId);
    const [appendModal] = useModal();

    const fieldName = `${currentPath}.name`;
    const fieldUpdater = questionId
        ? {
              forceupdate: 'true',
          }
        : {};

    return (
        <div key={index} className={styles.answer}>
            <div className={styles.sectionInner}>
                <div className={styles.row}>
                    <FastField
                        label={I18n.t('form.survey.answer.label')}
                        name={fieldName}
                        error={
                            get(values, `${currentPath}.translations.${locale}.name`)
                                ? getIn(touched, fieldName) &&
                                  getIn(errors, fieldName)
                                : locale !== 'is'
                                ? 'Þýðingu vantar'
                                : getIn(touched, fieldName) &&
                                  getIn(errors, fieldName)
                        }
                        as={Input}
                        disabled={disabled || values.published}
                        placeholder={get(
                            values,
                            `${currentPath}.translations.is.name`,
                            '',
                        )}
                        title={
                            disabled
                                ? I18n.t(
                                      'form.survey.answer.notBeforeQuestionTooltip',
                                  )
                                : undefined
                        }
                        onFocus={() => {
                            if (!answerCreated) {
                                setAnswerCreated(true);
                                createAnswer(
                                    {
                                        question: questionId,
                                        newTranslations: {
                                            [locale]: {
                                                name: I18n.t(
                                                    'form.survey.answer.label',
                                                ),
                                            },
                                        },
                                    },
                                    ({data}) => {
                                        setAnswerId(data.id);
                                    },
                                    ({code}) => {
                                        if (/[53]../.test(code)) {
                                            setAnswerCreated(false);
                                            appendModal({
                                                children: (
                                                    <ErrorMessage error={code} />
                                                ),
                                            });
                                        }
                                    },
                                );
                            }
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            handleChange(event);
                            validateField(fieldName);
                        }}
                        onBlur={(event: FocusEvent<HTMLInputElement>) => {
                            handleBlur(event);
                            if (!getIn(errors, fieldName) && answerId) {
                                updateAnswer(
                                    answerId,
                                    {
                                        newTranslations: {
                                            [locale]: {
                                                name: getIn(values, fieldName),
                                            },
                                        },
                                    },
                                    () => {
                                        setAnswerUpdated(true);
                                    },
                                    ({code}) => {
                                        if (/[53]../.test(code)) {
                                            appendModal({
                                                children: (
                                                    <ErrorMessage error={code} />
                                                ),
                                            });
                                        }
                                    },
                                );
                            }
                        }}
                        autoComplete={'off'}
                        {...fieldUpdater}
                    />
                    {locale !== 'is' ? (
                        <Anchor
                            className={styles.icon}
                            data-tip={get(
                                values,
                                `${currentPath}.translations.is.name`,
                                '',
                            )}
                            transitions={false}
                        >
                            <Icon icon={'preview'} size={'medium'} />
                        </Anchor>
                    ) : null}
                </div>
                {locale === 'is' &&
                getIn(values, path).length > 1 &&
                !values.published ? (
                    <Anchor
                        onClick={() => {
                            if (answerId) {
                                deleteAnswer(
                                    answerId,
                                    () => {
                                        remove(index);
                                    },
                                    ({code}) => {
                                        if (/[53]../.test(code)) {
                                            appendModal({
                                                children: (
                                                    <ErrorMessage error={code} />
                                                ),
                                            });
                                        }
                                    },
                                );
                            } else {
                                remove(index);
                            }
                        }}
                        title={I18n.t('form.survey.answer.deleteTooltip')}
                        transitions={false}
                        className={styles.answerRemoveButton}
                    >
                        <Icon icon={'minus'} size={'medium'} />
                    </Anchor>
                ) : null}
            </div>
        </div>
    );
};

export const Answers: React.FC<
    {
        path: string;
        questionId: number;
        locale: string;
    } & FormPartial<CreateSurveyFormValues>
> = ({path, questionId, locale = 'is', ...restParam}) => {
    const {values} = restParam;
    return (
        <FieldArray name={path} validateOnChange={false}>
            {(arrayHelpers) => {
                return (
                    <div className={styles.answers}>
                        {getIn(values, path).map(
                            (
                                //eslint-disable-next-line
                                //@ts-ignore
                                answer: CreateSurveyFormValues['sections'][number]['questions'][number]['answers'][number],
                                index: number,
                            ) => (
                                <Answer
                                    path={path}
                                    questionId={questionId}
                                    index={index}
                                    locale={locale}
                                    {...restParam}
                                    {...arrayHelpers}
                                    key={index}
                                />
                            ),
                        )}
                        {locale === 'is' && !values.published ? (
                            <Anchor
                                onClick={() => {
                                    arrayHelpers.push(answerInit);
                                }}
                                title={I18n.t('form.survey.answer.add')}
                                transitions={false}
                                className={styles.answerAddButton}
                            >
                                <Icon icon={'plus'} size={'medium'} />
                            </Anchor>
                        ) : null}
                    </div>
                );
            }}
        </FieldArray>
    );
};
