import {CreateSurveyFormValues} from 'components/forms/survey-form';

import {Button} from 'components/html/button';
import {Input} from 'components/html/input';

import {Formik, FormikConfig} from 'formik';
import {ContactGroup} from 'iterfaces/contact-group';
import React, {useEffect, useState} from 'react';

import I18n from 'services/I18n';

import {useSurveyForm} from 'store/survey/hooks';
import {selectContactGroupsFormSchema} from 'validators/contact-group';
import langEn from 'static/lang-en.png';
import langPl from 'static/lang-pl.png';
import langIs from 'static/lang-is.png';
import {InferType} from 'yup';
import classNames from 'classnames';
import {groupBy} from 'lodash';

import styles from './select-survey-contact-groups-form.module.scss';
import isSurveyTranslated, {
    TranslationStatus,
} from 'components/action-data-table/helpers/isSurveyTranslated';
import useTemplate from 'containers/dashboard/routes/templates/hooks/useTemplate';
import {Loader} from 'components/loader';

export type SelectSurveyContactGroupsFormValues = Pick<
    InferType<typeof selectContactGroupsFormSchema>,
    'selectedContactGroup'
> & {
    contactGroups: Array<Pick<ContactGroup, 'id' | 'name' | 'contacts'>>;
};

export type SelectSurveyContactGroupsFormProps = Partial<
    FormikConfig<SelectSurveyContactGroupsFormValues>
> & {
    initialValues: SelectSurveyContactGroupsFormValues;
    onSend: (
        values: SelectSurveyContactGroupsFormValues,
        surveyForm: Partial<CreateSurveyFormValues>,
    ) => void;
    onSaveAsDraft: (
        values: SelectSurveyContactGroupsFormValues,
        surveyForm: Partial<CreateSurveyFormValues>,
    ) => void;
    className?: string;
};

const titles: {[key: string]: string} = {
    is: 'Íslenskumælandi',
    en: 'Enskumælandi',
    pl: 'Pólskumælandi',
};

const flags: {[key: string]: string} = {
    is: langIs,
    en: langEn,
    pl: langPl,
};

export const SelectSurveyContactGroupsForm: React.FC<
    SelectSurveyContactGroupsFormProps
> = ({initialValues, onSend, onSaveAsDraft, className, ...restParam}) => {
    const SEND = 'SEND';
    const SAVE_AS_DRAFT = 'SAVE_AS_DRAFT';

    const [submitType, setSubmitType] = useState(SEND);
    const [surveyForm] = useSurveyForm();
    const [survey, setSurvey] = useTemplate();

    // State that retrieves the contactGroups related to the published survey
    const [surveyContactGroups, setSurveyContactGroups] =
        //eslint-disable-next-line
        useState<any>([]);

    // Update state with the contactGroups related to the published survey
    useEffect(() => {
        setSelectedContactGroups([]);
        setSurvey(surveyForm.id);

        // Check if the survey its already been published and save the groups in the state
        if (surveyForm && surveyForm.published) {
            setSurveyContactGroups(surveyForm.contactGroups);
        }
    }, []);

    const [activePanel, setActivePanel] = useState<string>('none');

    const [selectedContactGroups, setSelectedContactGroups] =
        //eslint-disable-next-line
        useState<any>([]);

    // Update state with the ids of the contactGroups related to the published survey
    useEffect(() => {
        // Check if survey is already filled with data
        if (surveyForm.contactGroups) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (surveyForm.contactGroups.length > 0) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const selectedContactGroups_temp = []; //creates the clone of the state
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                //eslint-disable-next-line
                surveyForm.contactGroups.map((contactGroup: any) => {
                    // Update the state only if the value is not already inside it
                    if (
                        !selectedContactGroups.some(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            (selectedContactGroup) =>
                                contactGroup.id.toString() ===
                                selectedContactGroup.id,
                        )
                    ) {
                        selectedContactGroups_temp.push(contactGroup.id.toString());
                    }
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setSelectedContactGroups(selectedContactGroups_temp);
            }
        }
    }, [surveyForm]);

    // State that updates if selected recipients have less than 5 emails
    const [selectedContactGroupsErrors, setSelectedContactGroupsErrors] =
        //eslint-disable-next-line
        useState<any>([]);

    const recipientGroups = groupBy(initialValues.contactGroups, 'locale');

    const collapse = (item: string) => {
        if (item === activePanel) {
            setActivePanel('none');
        } else {
            setActivePanel(item);
        }
    };
    if (!survey) return <Loader />;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                if (submitType === SEND) {
                    //eslint-disable-next-line
                    //@ts-ignore
                    onSend(
                        {
                            //eslint-disable-next-line
                            //@ts-ignore
                            ...values,
                            //eslint-disable-next-line
                            //@ts-ignore
                            contactGroups: selectedContactGroups,
                        },
                        surveyForm,
                    );
                } else {
                    //eslint-disable-next-line
                    //@ts-ignore
                    onSaveAsDraft(
                        {
                            //eslint-disable-next-line
                            //@ts-ignore
                            ...values,
                            //eslint-disable-next-line
                            //@ts-ignore
                            contactGroups: selectedContactGroups,
                        },
                        surveyForm,
                    );
                }
            }}
            // validationSchema={selectContactGroupsFormSchema}
            enableReinitialize={true}
            {...restParam}
        >
            {({submitForm}) => (
                <div className={styles.container}>
                    {['is', 'en', 'pl'].map((item: string) =>
                        recipientGroups[item] &&
                        //eslint-disable-next-line
                        //@ts-ignore
                        isSurveyTranslated(survey, item) ===
                            TranslationStatus.TRANSLATED ? (
                            <div
                                className={classNames(styles.collapseItem, {
                                    [styles.collapsed]: activePanel === item,
                                })}
                                key={item}
                            >
                                <div
                                    className={styles.collapseHeader}
                                    onClick={() => collapse(item)}
                                >
                                    <img
                                        alt={titles[item]}
                                        src={flags[item]}
                                        className={styles.language}
                                    />{' '}
                                    {titles[item]} viðtakendur (
                                    {recipientGroups[item].length})
                                </div>
                                <div className={styles.items}>
                                    {recipientGroups[item].map(
                                        //eslint-disable-next-line
                                        (contactGroup, index) => {
                                            const contactGroupIdToString = String(
                                                contactGroup.id,
                                            );
                                            return (
                                                <Input
                                                    key={contactGroup.id}
                                                    type="checkbox"
                                                    value={contactGroup.id}
                                                    label={contactGroup.name}
                                                    className={styles.contactGroup}
                                                    checked={selectedContactGroups.includes(
                                                        contactGroup.id.toString(),
                                                    )}
                                                    disabled={
                                                        surveyContactGroups.filter(
                                                            //eslint-disable-next-line
                                                            (e: any) =>
                                                                e.id ===
                                                                contactGroup.id,
                                                        ).length > 0
                                                    }
                                                    error={
                                                        selectedContactGroupsErrors.find(
                                                            //eslint-disable-next-line
                                                            (error: any) =>
                                                                error.groupId ===
                                                                contactGroupIdToString,
                                                        )
                                                            ? I18n.t(
                                                                  'validation.survey.contactGroups.selectedContactGroup.tooFew',
                                                              )
                                                            : undefined
                                                    }
                                                    onChange={(value) => {
                                                        const {target} = value;
                                                        if (target.checked) {
                                                            setSelectedContactGroups(
                                                                [
                                                                    ...selectedContactGroups,
                                                                    target.value,
                                                                ],
                                                            );
                                                            // Check if selected recipient contain at least 5 emails
                                                            // if (
                                                            //     initialValues
                                                            //         .contactGroups[
                                                            //         index
                                                            //     ].contacts.length < 5
                                                            // ) {
                                                            //     const newValue = {
                                                            //         groupId:
                                                            //             target.value,
                                                            //         contactsLength:
                                                            //             initialValues
                                                            //                 .contactGroups[
                                                            //                 index
                                                            //             ].contacts
                                                            //                 .length,
                                                            //     };
                                                            //     // Update errors state
                                                            //     setSelectedContactGroupsErrors(
                                                            //         [
                                                            //             ...selectedContactGroupsErrors,
                                                            //             newValue,
                                                            //         ],
                                                            //     );
                                                            // }
                                                        } else {
                                                            setSelectedContactGroups(
                                                                selectedContactGroups.filter(
                                                                    (x: string) =>
                                                                        x !==
                                                                        target.value,
                                                                ),
                                                            );
                                                            // Remove selected group error
                                                            setSelectedContactGroupsErrors(
                                                                selectedContactGroupsErrors.filter(
                                                                    //eslint-disable-next-line
                                                                    (object: any) =>
                                                                        object.groupId !==
                                                                        target.value,
                                                                ),
                                                            );
                                                        }
                                                    }}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        ) : null,
                    )}
                    <div className={styles.controls}>
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                setSubmitType(SAVE_AS_DRAFT);
                                submitForm();
                            }}
                            size={'big'}
                            variant={'primary'}
                            disabled={survey.published}
                            icon={{
                                icon: 'check',
                                size: 'medium',
                                variant: 'light',
                            }}
                            type={submitType === SAVE_AS_DRAFT ? 'submit' : 'button'}
                            className={styles.controlsButton}
                            title={I18n.t('form.survey.saveAsDraft')}
                        >
                            {I18n.t('form.survey.saveAsDraft')}
                        </Button>
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                if (!selectedContactGroupsErrors.length) {
                                    setSubmitType(SEND);
                                    submitForm();
                                }
                            }}
                            size={'big'}
                            variant={'primary'}
                            icon={{
                                icon: 'send',
                                size: 'medium',
                                variant: 'light',
                            }}
                            type={submitType === SEND ? 'submit' : 'button'}
                            className={styles.controlsButton}
                            title={I18n.t('form.survey.send')}
                            // Button disabled if selected groups have less than 5 emails or if there aren't selected groups
                            disabled={
                                selectedContactGroups.length === 0 ||
                                selectedContactGroupsErrors.length > 0
                            }
                        >
                            {I18n.t('form.survey.send')}
                        </Button>
                    </div>
                </div>
            )}
        </Formik>
    );
};
