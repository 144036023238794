import {Survey} from 'iterfaces/survey';

import * as ACTIONS from './types';

export type TemplatesStore = {
    templates: Survey[];
};

type Action = {
    type: string;
    payload: Partial<TemplatesStore>;
};

const initialState: TemplatesStore = {
    templates: [],
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case ACTIONS.SET_TEMPLATES: {
            return {...state, templates: action.payload.templates};
        }
        default:
            return state;
    }
};
