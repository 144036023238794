import {PageWrapper} from 'components/page-wrapper';
import {Survey} from 'containers/survey/survey';
import {Unsubscribe} from 'containers/unsubscribe/unsubscribe';
import React, {useState} from 'react';
import {Router} from 'react-router-dom';
import history from 'services/history';
import logo from '../static/logo.png';
import {getAuth} from '../store/user/auth';
import {useAuth, useUserIsLoggedIn} from '../store/user/hooks';
import {GuestRouter, LoggedRouter} from './AppRouter';
import {ConfirmSignUp} from './confirm-sign-up';
import {Dashboard} from './dashboard';
import {LogIn} from './log-in';
import {ResetPassword} from './reset-password';

const AppRouting: React.FC = () => {
    const [checkStore, setCheckStore] = useState(true);
    const auth = useUserIsLoggedIn();
    const {vendor, token} = getAuth();
    const [, setAuth] = useAuth();
    if (!auth && checkStore && token && vendor && vendor.id) {
        setCheckStore(false);
        setAuth({vendor, token});
    }

    return (
        <Router history={history}>
            <PageWrapper header={{logo}}>
                <LoggedRouter path={'/'} component={Dashboard} auth={auth} exact />
                <LoggedRouter
                    path={'/dashboard'}
                    component={Dashboard}
                    auth={auth}
                />
                <GuestRouter path={'/login'} component={LogIn} auth={auth} />
                <GuestRouter path={'/survey/:token'} component={Survey} />
                <GuestRouter path={'/unsubscribe/:token'} component={Unsubscribe} />
                <GuestRouter
                    path={'/confirm-sign-up/:token'}
                    component={ConfirmSignUp}
                    auth={auth}
                />
                <GuestRouter
                    path={'/reset-password/:token'}
                    component={ResetPassword}
                    auth={auth}
                />
            </PageWrapper>
        </Router>
    );
};

export default AppRouting;
