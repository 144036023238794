import React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';

import {Start} from './routes/start';
import {EditContacts} from './routes/edit-contacts';
import {CreateContactGroup} from './routes/create-contact-group';

import ROUTES from './routes';

export const Recipients: React.FC<RouteComponentProps> = () => (
    <>
        <Route exact path={ROUTES.START} component={Start} />
        <Route path={ROUTES.CREATE_CONTACT_GROUP} component={CreateContactGroup} />
        <Route
            path={ROUTES.EDIT_CONTACT_GROUP_CONTACTS(':id')}
            component={EditContacts}
        />
    </>
);
