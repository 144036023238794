import React, {SelectHTMLAttributes} from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type SelectInputProps = SelectHTMLAttributes<HTMLSelectElement> & {
    label: string;
};

const SelectInput: React.FC<SelectInputProps> = ({
    label,
    onChange,
    name,
    children,
}) => {
    return (
        <div className={styles.container}>
            <label className={classNames(styles.label, styles.labelUp)}>
                {label}
            </label>
            <select name={name} onChange={onChange} className={styles.select}>
                {children}
            </select>
        </div>
    );
};

export default SelectInput;
