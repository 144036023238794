import {confirmResettingPassword} from 'api/vendor.api';
import {Button} from 'components/html/button';

import {Input} from 'components/html/input';

import {Field, Form, Formik, FormikProps} from 'formik';
import React from 'react';

import I18n from 'services/I18n';
import {mapViolationsToFieldErrors} from 'utils/map-violations-to-field-errors';
import {resetPasswordSchema} from 'validators/vendor';
import {InferType} from 'yup';

import styles from './reset-password-form.module.scss';

export type ResetPasswordFormValues = Pick<
    InferType<typeof resetPasswordSchema>,
    'password' | 'confirmPassword'
>;

export type ResetPasswordFormProps = {
    token: string;
    onSuccess: () => void;
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
    token,
    onSuccess,
}) => (
    <Formik
        validationSchema={resetPasswordSchema}
        initialValues={{
            password: '',
            confirmPassword: '',
        }}
        onSubmit={(values, {setErrors, setSubmitting}) => {
            confirmResettingPassword(
                {
                    password: values.password,
                    token,
                },
                () => {
                    onSuccess();
                },
                (error) => {
                    if (error.code === '400') {
                        setErrors(mapViolationsToFieldErrors(error.violations));
                    } else {
                        console.error(error);
                    }
                    setSubmitting(false);
                },
            );
        }}
    >
        {({errors, isSubmitting}: FormikProps<ResetPasswordFormValues>) => (
            <Form>
                <Field
                    label={I18n.t('vendor.password')}
                    name={'password'}
                    type={'password'}
                    error={errors.password}
                    as={Input}
                    className={styles.field}
                />
                <Field
                    label={I18n.t('form.resetPassword.confirmPasswordLabel')}
                    name={'confirmPassword'}
                    type={'password'}
                    error={errors.confirmPassword}
                    as={Input}
                    className={styles.field}
                />
                <Button className={styles.submit} disabled={isSubmitting}>
                    {I18n.t('form.submit')}
                </Button>
            </Form>
        )}
    </Formik>
);
