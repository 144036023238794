import React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';

import {Start} from './routes/start';
import {ViewResults} from './routes/view-results';
import ROUTES from './routes';

export const Results: React.FC<RouteComponentProps> = () => (
    <>
        <Route exact path={ROUTES.START} component={Start} />
        <Route path={ROUTES.VIEW(':surveyId')} component={ViewResults} />
    </>
);
