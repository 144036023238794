import React, {useEffect, useState} from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';

import I18n from 'i18n-js';

import {activateVendor} from 'api/vendor.api';

import {Heading} from 'components/html/heading';
import {Button} from 'components/html/button';
import {Paragraph} from 'components/html/paragraph';
import {ErrorMessage} from 'components/error-message';
import {useModal, Modal} from 'components/modal';

const useVendorActivation = (token: string) => {
    const [activated, setActivated] = useState(false);
    const [showModal] = useModal();

    useEffect(() => {
        activateVendor(
            {
                confirmationToken: token,
            },
            () => {
                setActivated(true);
            },
            ({code}) => {
                if (/[53]../.test(code)) {
                    showModal({children: <ErrorMessage error={code} />});
                }
            },
        );
    });

    return activated;
};

export const ConfirmSignUp: React.FC<RouteComponentProps<{token: string}>> = ({
    match,
}) => {
    const isVendorActivated = useVendorActivation(match.params.token);

    return (
        <Modal size={'compact'}>
            {isVendorActivated ? (
                <>
                    <Heading element={'h1'}>{I18n.t('app.title')}</Heading>
                    <Heading element={'h3'} style={{marginTop: '2rem'}}>
                        {I18n.t('signUp.activation.successMessageHeading')}
                    </Heading>
                    <Paragraph>
                        {I18n.t('signUp.activation.successMessageParagraph')}
                    </Paragraph>
                    <Link to={'/login'}>
                        <Button style={{marginTop: '2rem'}}>
                            {I18n.t('vendor.logIn')}
                        </Button>
                    </Link>
                </>
            ) : (
                <ErrorMessage error={I18n.t('signUp.activation.failureMessage')} />
            )}
        </Modal>
    );
};
