import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {Modal} from 'components/modal';
import {Anchor} from 'components/html/anchor';
import {Icon} from 'components/icon';
import {SurveyForm} from 'components/forms/survey-form';

import TEMPLATES_ROUTES from '../../routes';

import styles from './create-template.module.scss';

export const CreateTemplate: React.FC<RouteComponentProps> = ({history}) => (
    <Modal size={'full'} className={styles.modal}>
        <Anchor
            onClick={() => {
                history.push(TEMPLATES_ROUTES.START);
            }}
            className={styles.closeButton}
            title={I18n.t('navigation.return')}
            transitions={false}
        >
            <Icon icon={'cross'} size={'medium'} />
        </Anchor>
        <SurveyForm
            onSuccess={() => {
                history.push(TEMPLATES_ROUTES.START);
            }}
            onCancel={() => {
                history.push(TEMPLATES_ROUTES.START);
            }}
            isTemplateForm={true}
        />
    </Modal>
);
