import {Survey} from 'iterfaces/survey';

import {useDispatch, useSelector} from 'react-redux';

import {Store} from '../';

import {setTemplates} from './action-creators';

export const useTemplates = (): [Survey[], (templates: Survey[]) => void] => {
    const dispatch = useDispatch();
    return [
        useSelector<Store, Survey[]>((state) => state.templates.templates),
        (templates: Survey[]) => dispatch(setTemplates(templates)),
    ];
};
