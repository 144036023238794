import {createQuestion, deleteQuestion, updateQuestion} from 'api/survey.api';
import classNames from 'classnames';
import {ErrorMessage} from 'components/error-message';
import {Anchor} from 'components/html/anchor';
import {Button} from 'components/html/button';
import {Input} from 'components/html/input';
import {Icon} from 'components/icon';
import {useModal} from 'components/modal';
import {FastField, Field, FieldArray, getIn} from 'formik';
import {FormPartial, FormPartialWithArrayHelpers} from 'iterfaces/form';
import {get} from 'lodash';
import React, {ChangeEvent, FocusEvent, RefObject, useState} from 'react';
import I18n from 'services/I18n';
import {hasErrors} from 'utils/forms';
import {questionInit} from '../../initial-values';

import {CreateSurveyFormValues} from '../../survey-form';
import styles from '../../survey-form.module.scss';
import {Answers} from '../answers';

export const Question: React.FC<
    {
        sectionId: number;
        path: string;
        locale?: string;
        index: number;
        questionsRef: RefObject<HTMLDivElement>;
    } & FormPartialWithArrayHelpers<CreateSurveyFormValues>
> = ({sectionId, path, locale = 'is', index, ...restParam}) => {
    const {
        values,
        errors,
        touched,
        handleBlur,
        remove,
        push,
        handleChange,
        validateField,
        questionsRef,
    } = restParam;
    const currentPath = `${path}[${index}]`;
    const initialId = getIn(values, `${currentPath}.id`) as number;
    const [questionCreated, setQuestionCreated] = useState(!!initialId);
    const [, setQuestionUpdated] = useState(false);
    const [questionId, setQuestionId] = useState(initialId);
    const [appendModal] = useModal();
    const disabled = !sectionId;
    const [isExpanded, setIsExpanded] = useState(true);

    const fieldName = `${currentPath}.name`;
    const fieldUpdater = sectionId
        ? {
              forceupdate: 'true',
          }
        : {};

    return (
        <div className={styles.questionContainer} key={index}>
            <div
                className={classNames(styles.question, {
                    [styles.questionHidden]: !isExpanded,
                })}
            >
                <div className={styles.inner}>
                    <div className={styles.inner2}>
                        <div className={styles.sectionInner}>
                            <div className={styles.row}>
                                <FastField
                                    label={I18n.t('form.survey.question.label')}
                                    name={fieldName}
                                    disabled={disabled || values.published}
                                    error={
                                        get(
                                            values,
                                            `${currentPath}.translations.${locale}.name`,
                                        )
                                            ? getIn(touched, fieldName) &&
                                              getIn(errors, fieldName)
                                            : locale !== 'is'
                                            ? 'Þýðingu vantar'
                                            : getIn(touched, fieldName) &&
                                              getIn(errors, fieldName)
                                    }
                                    as={Input}
                                    placeholder={get(
                                        values,
                                        `${currentPath}.translations.is.name`,
                                        '',
                                    )}
                                    title={
                                        disabled
                                            ? I18n.t(
                                                  'form.survey.question.notBeforeSectionTooltip',
                                              )
                                            : undefined
                                    }
                                    onFocus={() => {
                                        if (!questionCreated) {
                                            setQuestionCreated(true);
                                            createQuestion(
                                                {
                                                    section: sectionId,
                                                    newTranslations: {
                                                        [locale]: {
                                                            name: I18n.t(
                                                                'form.survey.question.label',
                                                            ),
                                                        },
                                                    },
                                                    multiple:
                                                        getIn(
                                                            values,
                                                            `${currentPath}.type`,
                                                        ) === 'multipleChoice',
                                                },
                                                ({data}) => {
                                                    setQuestionId(data.id);
                                                },
                                                ({code}) => {
                                                    setQuestionCreated(false);
                                                    if (/[53]../.test(code)) {
                                                        appendModal({
                                                            children: (
                                                                <ErrorMessage
                                                                    error={code}
                                                                />
                                                            ),
                                                        });
                                                    }
                                                },
                                            );
                                        }
                                    }}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        handleChange(event);
                                        validateField(fieldName);
                                    }}
                                    onBlur={(
                                        event: FocusEvent<HTMLInputElement>,
                                    ) => {
                                        handleBlur(event);
                                        if (!getIn(errors, fieldName)) {
                                            updateQuestion(
                                                questionId,
                                                {
                                                    newTranslations: {
                                                        [locale]: {
                                                            name: getIn(
                                                                values,
                                                                fieldName,
                                                            ),
                                                        },
                                                    },
                                                },
                                                () => {
                                                    setQuestionUpdated(true);
                                                },
                                                ({code}) => {
                                                    if (/[53]../.test(code)) {
                                                        appendModal({
                                                            children: (
                                                                <ErrorMessage
                                                                    error={code}
                                                                />
                                                            ),
                                                        });
                                                    }
                                                },
                                            );
                                        }
                                    }}
                                    autoComplete={'off'}
                                    {...fieldUpdater}
                                />
                                {locale !== 'is' ? (
                                    <Anchor
                                        className={styles.icon}
                                        data-tip={get(
                                            values,
                                            `${currentPath}.translations.is.name`,
                                            '',
                                        )}
                                        transitions={false}
                                    >
                                        <Icon icon={'preview'} size={'medium'} />
                                    </Anchor>
                                ) : null}
                            </div>
                            {locale === 'is' &&
                            getIn(values, path).length > 1 &&
                            !values.published ? (
                                <Anchor
                                    onClick={() => {
                                        if (questionId) {
                                            deleteQuestion(
                                                questionId,
                                                () => {
                                                    remove(index);
                                                },
                                                ({code}) => {
                                                    if (/[53]../.test(code)) {
                                                        appendModal({
                                                            children: (
                                                                <ErrorMessage
                                                                    error={code}
                                                                />
                                                            ),
                                                        });
                                                    }
                                                },
                                            );
                                        } else {
                                            remove(index);
                                        }
                                    }}
                                    title={I18n.t(
                                        'form.survey.question.deleteTooltip',
                                    )}
                                    transitions={false}
                                    className={styles.questionRemoveButton}
                                >
                                    <Icon icon={'minus'} size={'medium'} />
                                </Anchor>
                            ) : null}
                            {locale === 'is' ? (
                                <>
                                    <Field
                                        label={I18n.t(
                                            'form.survey.question.singleAnswerPossible',
                                        )}
                                        name={`${currentPath}.type`}
                                        type={'radio'}
                                        value={'singleChoice'}
                                        as={Input}
                                        onBlur={(
                                            event: FocusEvent<HTMLInputElement>,
                                        ) => {
                                            handleBlur(event);
                                            if (
                                                !getIn(errors, `${currentPath}.type`)
                                            ) {
                                                updateQuestion(
                                                    questionId,
                                                    {
                                                        multiple: false,
                                                    },
                                                    () => {
                                                        setQuestionUpdated(true);
                                                    },
                                                    ({code}) => {
                                                        if (/[53]../.test(code)) {
                                                            appendModal({
                                                                children: (
                                                                    <ErrorMessage
                                                                        error={code}
                                                                    />
                                                                ),
                                                            });
                                                        }
                                                    },
                                                );
                                            }
                                        }}
                                        disabled={
                                            !sectionId ||
                                            !questionId ||
                                            values.published
                                        }
                                        title={
                                            disabled
                                                ? I18n.t(
                                                      'form.survey.question.notBeforeSectionTooltip',
                                                  )
                                                : undefined
                                        }
                                    />
                                    <Field
                                        label={I18n.t(
                                            'form.survey.question.multipleAnswersPossible',
                                        )}
                                        name={`${currentPath}.type`}
                                        type={'radio'}
                                        value={'multipleChoice'}
                                        as={Input}
                                        onBlur={(
                                            event: FocusEvent<HTMLInputElement>,
                                        ) => {
                                            handleBlur(event);
                                            if (
                                                !getIn(errors, `${currentPath}.type`)
                                            ) {
                                                updateQuestion(
                                                    questionId,
                                                    {
                                                        multiple: true,
                                                    },
                                                    () => {
                                                        setQuestionUpdated(true);
                                                    },
                                                    ({code}) => {
                                                        if (/[53]../.test(code)) {
                                                            appendModal({
                                                                children: (
                                                                    <ErrorMessage
                                                                        error={code}
                                                                    />
                                                                ),
                                                            });
                                                        }
                                                    },
                                                );
                                            }
                                        }}
                                        disabled={
                                            !sectionId ||
                                            !questionId ||
                                            values.published
                                        }
                                        title={
                                            disabled
                                                ? I18n.t(
                                                      'form.survey.question.notBeforeSectionTooltip',
                                                  )
                                                : undefined
                                        }
                                    />
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Answers
                    path={`${currentPath}.answers`}
                    questionId={questionId}
                    locale={locale}
                    {...restParam}
                />
            </div>
            {locale === 'is' ? (
                <div className={styles.questionControls}>
                    <div
                        className={classNames(styles.questionControlsLabel, {
                            [styles.questionControlsLabelHidden]: isExpanded,
                            [styles.questionControlsLabelWarning]: hasErrors(
                                errors,
                                currentPath,
                            ),
                        })}
                    >
                        {getIn(values, fieldName) ? (
                            <>
                                <span>{I18n.t('form.survey.question.label')}:</span>{' '}
                                <span
                                    className={
                                        styles.questionControlsLabelQuestionPreview
                                    }
                                >
                                    {getIn(values, fieldName)}
                                </span>
                            </>
                        ) : (
                            <span>
                                {I18n.t('form.survey.question.unnamedQuestionLabel')}
                            </span>
                        )}
                        <span className={styles.questionControlsLabelAnswersCount}>
                            {I18n.t('form.survey.question.answersCountLabel', {
                                count: getIn(values, `${currentPath}.answers`)
                                    .length,
                            })}
                        </span>
                    </div>
                    <Button
                        variant={'secondary'}
                        transitionEffects={false}
                        icon={{
                            icon: 'preview',
                        }}
                        shadow={false}
                        fixedWidth={false}
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                        title={
                            isExpanded
                                ? I18n.t('form.survey.question.hideButton')
                                : I18n.t('form.survey.question.showButton')
                        }
                        transparent={true}
                        type={'button'}
                    >
                        {isExpanded
                            ? I18n.t('form.survey.question.hideButton')
                            : I18n.t('form.survey.question.showButton')}
                    </Button>
                    <Button
                        variant={'secondary'}
                        transitionEffects={false}
                        icon={{
                            icon: 'plus',
                        }}
                        shadow={false}
                        fixedWidth={false}
                        onClick={() => {
                            push(questionInit);
                            setTimeout(() => {
                                window.scrollTo({
                                    top: questionsRef?.current?.getBoundingClientRect()
                                        .bottom,
                                    behavior: 'smooth',
                                });
                            }, 1);
                        }}
                        title={I18n.t('form.survey.question.addTooltip')}
                        transparent={true}
                        type={'button'}
                        disabled={values.published}
                        style={{
                            marginLeft: '1rem',
                        }}
                    >
                        {I18n.t('form.survey.question.addTooltip')}
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export const Questions: React.FC<
    {
        sectionId: number;
        path: string;
        locale: string;
    } & FormPartial<CreateSurveyFormValues>
> = ({path, sectionId, locale = 'is', ...restParam}) => {
    const {values} = restParam;
    return (
        <FieldArray name={path} validateOnChange={false}>
            {(arrayHelpers) => {
                const questionsRef: RefObject<HTMLDivElement> = React.createRef();
                return (
                    <div className={styles.questions} ref={questionsRef}>
                        {getIn(values, path).map(
                            (
                                //eslint-disable-next-line
                                //@ts-ignore
                                question: CreateSurveyFormValues['sections'][number]['questions'][number],
                                index: number,
                            ) => (
                                <Question
                                    path={path}
                                    index={index}
                                    sectionId={sectionId}
                                    {...restParam}
                                    {...arrayHelpers}
                                    locale={locale}
                                    key={index}
                                    questionsRef={questionsRef}
                                />
                            ),
                        )}
                    </div>
                );
            }}
        </FieldArray>
    );
};
