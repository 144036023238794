import {useState} from 'react';

import {createMediaObject} from 'api/media-object.api';
import {initMediaObject, MediaObject} from 'iterfaces/media-object';

export const useUploadFile = (): [MediaObject, string, (file: File) => void] => {
    const [fileUrl, setFileUrl] = useState(initMediaObject);
    const [error, setError] = useState('');

    const uploadFile = (blob: File) => {
        const file = new FormData();
        file.append('file', blob, blob.name);
        createMediaObject(
            file,
            ({data}) => setFileUrl(data),
            ({message}) => setError(message),
        );
    };

    return [fileUrl, error, uploadFile];
};
