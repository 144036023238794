import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {useVendor} from 'store/user/hooks';

import {Modal} from 'components/modal';
import {CreateContactGroupForm} from 'components/forms/create-contact-group-form';
import {Heading} from 'components/html/heading';
import {Anchor} from 'components/html/anchor';
import {Icon} from 'components/icon';

import {useContactGroupsSubscription} from '../../';

import RECIPIENTS_ROUTES from '../../routes';

import styles from './create-contact-group.module.scss';

export const CreateContactGroup: React.FC<RouteComponentProps> = ({history}) => {
    const [vendor] = useVendor();
    const [, updateSubscription] = useContactGroupsSubscription();
    return (
        <Modal size={'full'} className={styles.container}>
            <Anchor
                onClick={() => {
                    history.push(RECIPIENTS_ROUTES.START);
                }}
                title={I18n.t('navigation.return')}
                transitions={false}
                className={styles.returnButton}
            >
                <Icon icon={'cross'} size={'medium'} />
            </Anchor>
            <Heading element={'h4'}>
                {I18n.t('dashboard.contacts.createContactGroup.title')}
            </Heading>
            <CreateContactGroupForm
                onSuccess={({id}) => {
                    updateSubscription(() => {
                        history.push(
                            RECIPIENTS_ROUTES.EDIT_CONTACT_GROUP_CONTACTS(id),
                        );
                    });
                }}
                onCancel={() => {
                    history.push(RECIPIENTS_ROUTES.START);
                }}
                vendor={vendor.id}
                className={styles.form}
            />
        </Modal>
    );
};
