import {Survey} from 'iterfaces/survey';
import {MediaObject, initMediaObject} from 'iterfaces/media-object';

import {CreateSurveyFormValues, surveyInit} from 'components/forms/survey-form';

import * as ACTIONS from './types';

export type SurveyStore = {
    surveys: Survey[];
    surveyForm: CreateSurveyFormValues;
    surveyLogo: MediaObject;
};

type Action = {
    type: string;
    payload: Partial<SurveyStore>;
};

const initialState: SurveyStore = {
    surveys: [],
    //eslint-disable-next-line
    //@ts-ignore
    surveyForm: surveyInit,
    surveyLogo: initMediaObject,
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case ACTIONS.SET_SURVEYS: {
            return {...state, surveys: action.payload.surveys};
        }
        case ACTIONS.SET_SURVEY_FORM: {
            return {...state, surveyForm: action.payload.surveyForm};
        }
        case ACTIONS.SET_SURVEY_LOGO: {
            return {...state, surveyLogo: action.payload.surveyLogo};
        }
        default:
            return state;
    }
};
