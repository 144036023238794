import React, {SVGAttributes} from 'react';

import join from 'lodash/join';

import {iconElements} from './';

import styles from './icon.module.scss';

export type IconProps = SVGAttributes<HTMLOrSVGElement> & {
    icon:
        | 'chart'
        | 'check'
        | 'cross'
        | 'minus'
        | 'pen'
        | 'template'
        | 'plus'
        | 'preview'
        | 'send'
        | 'settings'
        | 'logout';
    size?: 'small' | 'medium';
    variant?: 'dark' | 'light' | 'ghost';
};

export const Icon: React.FC<IconProps> = ({
    icon,
    size = 'small',
    variant = 'dark',
    className,
    ...restParam
}) => {
    const Icon = iconElements[icon];

    return (
        <Icon
            className={join(
                [
                    styles.icon,
                    styles[size],
                    styles[variant],
                    className ? className : '',
                ],
                ' ',
            )}
            {...restParam}
        />
    );
};
