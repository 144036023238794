import {updateVendor} from 'api/vendor.api';
import {Button} from 'components/html/button';
import {Input} from 'components/html/input';
import {Field, Form, Formik} from 'formik';
import {initVendor, Vendor} from 'iterfaces/Vendor';
import pick from 'lodash/pick';
import React, {FormHTMLAttributes} from 'react';

import I18n from 'services/I18n';
import {mapViolationsToFieldErrors} from 'utils/map-violations-to-field-errors';
import {editVendorSchema} from 'validators/vendor';
import {InferType} from 'yup';

import styles from './vendor-form.module.scss';

export type VendorFormValues = Pick<
    InferType<typeof editVendorSchema>,
    'firstName' | 'lastName' | 'password' | 'confirmPassword'
>;

export type VendorFormProps = {
    vendorId: number;
    onSuccess: (vendor: Vendor) => void;
    initialValues?: Partial<VendorFormValues>;
} & FormHTMLAttributes<HTMLFormElement>;

export const VendorForm: React.FC<VendorFormProps> = ({
    vendorId,
    onSuccess,
    initialValues = {},
    ...restProps
}) => (
    <Formik
        initialValues={
            {
                ...pick(initVendor, [
                    'firstName',
                    'lastName',
                    'password',
                    'confirmPassword',
                ]),
                ...initialValues,
            } as VendorFormValues
        }
        onSubmit={(values, {setSubmitting, setErrors}) => {
            updateVendor(
                vendorId,
                values,
                (response) => {
                    setSubmitting(false);
                    onSuccess(response.data);
                },
                (error) => {
                    if (/[53]../.test(error.code)) {
                        console.error(error);
                    } else {
                        setErrors(mapViolationsToFieldErrors(error.violations));
                    }
                },
            );
        }}
        validationSchema={editVendorSchema}
    >
        {({errors, isSubmitting}) => (
            <Form {...restProps}>
                <Field
                    name={'firstName'}
                    error={errors.firstName}
                    as={Input}
                    label={I18n.t('vendor.firstName')}
                />
                <Field
                    name={'lastName'}
                    error={errors.lastName}
                    as={Input}
                    label={I18n.t('vendor.lastName')}
                />
                <Field
                    name={'password'}
                    error={errors.password}
                    as={Input}
                    type={'password'}
                    label={I18n.t('form.vendor.newPasswordLabel')}
                />
                <Field
                    name={'confirmPassword'}
                    error={errors.confirmPassword}
                    as={Input}
                    type={'password'}
                    label={I18n.t('form.vendor.confirmNewPasswordLabel')}
                />
                <Button
                    type={'submit'}
                    disabled={isSubmitting}
                    className={styles.submit}
                >
                    {I18n.t('form.submit')}
                </Button>
            </Form>
        )}
    </Formik>
);
