import {Question, Answer} from 'iterfaces/survey';
import {QuestionResults} from 'iterfaces/results';

type ArrayResults = [string, number][];

const mapCountResultsToPercentResults = (rawResults: ArrayResults): ArrayResults => {
    const answersCount = rawResults.reduce((accumulator, value) => {
        return accumulator + value[1];
    }, 0);

    return rawResults.map((value) => [
        value[0],
        Math.floor((100 * value[1]) / answersCount || 0),
    ]);
};

export const calculateQuestionResults = (
    question: Question,
    givenAnswersIds: Answer['id'][],
): QuestionResults => {
    const countResults: ArrayResults = question.answers.map((answer) => {
        return [
            answer.id.toString(),
            givenAnswersIds.filter((id) => id === answer.id).length,
        ];
    });

    return Object.fromEntries(mapCountResultsToPercentResults(countResults));
};
