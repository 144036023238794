import Api, {ErrorCallback, ResponseCallback} from 'services/Api';
import {trackPromise} from 'react-promise-tracker';

import {Recipient} from 'iterfaces/recipient';

export const readRecipient = (
    token: string,
    onSuccess: ResponseCallback<Recipient>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<Recipient>(`recipients/${token}`, {
            onSuccess,
            onError,
        }),
    );
};

export const unsubscribeRecipient = (
    token: string,
    onSuccess: ResponseCallback<Recipient>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<Recipient>(`recipients/unsubscribe/${token}`, {
            onSuccess,
            onError,
        }),
    );
};
