import {publishSurvey, readSurvey, updateSurvey} from 'api/survey.api';
import {ConfirmationDialog} from 'components/confirmation-dialog';

import {ErrorMessage} from 'components/error-message';
import {SelectSurveyContactGroupsForm} from 'components/forms/select-survey-contact-groups-form';
import {Anchor} from 'components/html/anchor';
import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {Icon} from 'components/icon';
import {ImageUploadInput} from 'components/image-upload-input';

import {Modal, useModal} from 'components/modal';
import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {useSurveyForm, useSurveyLogo, useSurveys} from 'store/survey/hooks';

import DASHBOARD_ROUTES from '../../../../routes';

import {useContactGroupsSubscription} from '../../../recipients';
import SURVEYS_ROUTES from '../../routes';

import styles from './select-survey-contact-groups.module.scss';

export const SelectSurveyContactGroups: React.FC<RouteComponentProps> = ({
    history,
}) => {
    const [surveys, setSurveys] = useSurveys();
    const [surveyLogo] = useSurveyLogo();
    const [appendModal] = useModal();

    const [surveyForm] = useSurveyForm();

    useEffect(() => {
        console.log('refreshsed');
        readSurvey(
            surveyForm.id,
            ({data}) => {
                setSurveys(
                    surveys.map((survey) => {
                        if (survey.id === surveyForm.id) {
                            return data;
                        }
                        return survey;
                    }),
                );
            },
            ({code}) => {
                appendModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    }, []);

    const [contactGroupsSubscription] = useContactGroupsSubscription();

    return contactGroupsSubscription.length ? (
        <Modal size={'full'} className={styles.modal}>
            <Heading element={'h4'}>
                {I18n.t('dashboard.surveys.editContactGroups.title')}
            </Heading>
            <Anchor
                onClick={() => {
                    appendModal({
                        children: (
                            <ConfirmationDialog
                                onConfirm={() => {
                                    history.push(DASHBOARD_ROUTES.START);
                                }}
                                message={I18n.t(
                                    'dashboard.surveys.editContactGroups.cancelDialog.message',
                                )}
                            />
                        ),
                    });
                }}
                className={styles.closeButton}
                title={I18n.t('navigation.return')}
                transitions={false}
            >
                <Icon icon={'cross'} size={'medium'} />
            </Anchor>
            <Paragraph className={styles.description} fontSize={'small'}>
                {I18n.t('dashboard.surveys.editContactGroups.description')}
            </Paragraph>
            {surveyLogo.contentUrl ? (
                <ImageUploadInput
                    mediaObject={surveyLogo}
                    allowChange={false}
                    className={styles.logo}
                />
            ) : null}
            <SelectSurveyContactGroupsForm
                initialValues={{
                    selectedContactGroup:
                        (contactGroupsSubscription &&
                            contactGroupsSubscription[0] &&
                            contactGroupsSubscription[0].id.toString()) ||
                        '0',
                    contactGroups: contactGroupsSubscription,
                }}
                className={styles.form}
                onSaveAsDraft={(values) => {
                    updateSurvey(
                        surveyForm.id as number,
                        {
                            contactGroups: values.contactGroups,
                            ...(surveyLogo &&
                                surveyLogo.id && {logo: surveyLogo.id}),
                        },
                        () => {
                            history.push(SURVEYS_ROUTES.START);
                        },
                        ({code}) => {
                            appendModal({
                                children: <ErrorMessage error={code} />,
                            });
                        },
                    );
                }}
                onSend={(values) => {
                    updateSurvey(
                        surveyForm.id as number,
                        {
                            contactGroups: values.contactGroups,
                        },
                        () => {
                            publishSurvey(
                                {
                                    survey: surveyForm.id,
                                },
                                () => {
                                    appendModal({
                                        children: (
                                            <ConfirmationDialog
                                                onConfirm={() => {
                                                    history.push(
                                                        DASHBOARD_ROUTES.START,
                                                    );
                                                }}
                                                title={I18n.t(
                                                    'dashboard.surveys.editContactGroups.publishedModal.title',
                                                )}
                                                message={I18n.t(
                                                    'dashboard.surveys.editContactGroups.publishedModal.message',
                                                )}
                                                confirmButtonLabel={I18n.t(
                                                    'dashboard.surveys.editContactGroups.publishedModal.confirmButton',
                                                )}
                                                displayCancelButton={false}
                                            />
                                        ),
                                    });
                                },
                                ({code}) => {
                                    appendModal({
                                        children: <ErrorMessage error={code} />,
                                    });
                                },
                            );
                        },
                        ({code}) => {
                            appendModal({
                                children: <ErrorMessage error={code} />,
                            });
                        },
                    );
                }}
            />
        </Modal>
    ) : (
        <Modal size={'compact'} isFixed={true}>
            <ConfirmationDialog
                title={I18n.t(
                    'dashboard.surveys.editContactGroups.noContactGroupsDialog.title',
                )}
                onConfirm={() => {
                    history.push(DASHBOARD_ROUTES.START);
                }}
                message={I18n.t(
                    'dashboard.surveys.editContactGroups.noContactGroupsDialog.message',
                )}
                confirmButtonLabel={I18n.t(
                    'dashboard.surveys.editContactGroups.noContactGroupsDialog.confirmButton',
                )}
                displayCancelButton={false}
            />
        </Modal>
    );
};
