import {useDispatch, useSelector} from 'react-redux';

import {ContactGroup} from 'iterfaces/contact-group';

import {Store} from '../';

import {setContactGroups} from './action-creators';

export const useContactGroups = (): [
    ContactGroup[],
    (contactGroups: ContactGroup[]) => void,
] => {
    const dispatch = useDispatch();
    return [
        useSelector<Store, ContactGroup[]>(
            (state) => state.contactGroups.contactGroups,
        ),
        (contactGroups: ContactGroup[]) => dispatch(setContactGroups(contactGroups)),
    ];
};
