import Api, {ErrorCallback, ResponseCallback} from 'services/Api';
import {trackPromise} from 'react-promise-tracker';

import {MediaObject} from 'iterfaces/media-object';

export const createMediaObject = (
    data: FormData,
    onSuccess: ResponseCallback<MediaObject>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.post<FormData, MediaObject>('media_objects', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onSuccess,
            onError,
        }),
    );
};

export const readMediaObject = (
    id: number,
    onSuccess: ResponseCallback<MediaObject>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<MediaObject>(`media_objects/${id}`, {
            onSuccess,
            onError,
        }),
    );
};
