import {
    mapSurveyToCreateSurveyFormValues,
    SurveyForm,
} from 'components/forms/survey-form';
import {Anchor} from 'components/html/anchor';
import {Icon} from 'components/icon';

import {Modal} from 'components/modal';
import useTemplate from 'containers/dashboard/routes/templates/hooks/useTemplate';
import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import DASHBOARD_ROUTES from '../../../../routes';
import SURVEYS_ROUTES from '../../routes';
import styles from './edit-survey.module.scss';

export const EditSurvey: React.FC<
    RouteComponentProps<{
        id: string;
        locale: string;
    }>
> = ({history, match}) => {
    const [survey, setSurvey] = useTemplate();
    const currentSurveyId = parseInt(match.params.id);
    const currentLocale = match.params.locale;

    useEffect(() => {
        setSurvey(currentSurveyId);
    }, [match.params.id]);

    return survey ? (
        <Modal size={'full'} className={styles.modal}>
            <Anchor
                onClick={() => {
                    history.push(SURVEYS_ROUTES.START);
                }}
                className={styles.closeButton}
                title={I18n.t('navigation.return')}
                transitions={false}
            >
                <Icon icon={'cross'} size={'medium'} />
            </Anchor>
            <SurveyForm
                onSuccess={() => {
                    history.push(SURVEYS_ROUTES.EDIT_CONTACTS);
                }}
                onCancel={() => {
                    history.push(DASHBOARD_ROUTES.START);
                }}
                initialValues={mapSurveyToCreateSurveyFormValues(
                    survey,
                    currentLocale,
                )}
                locale={currentLocale}
                isTemplateForm={false}
            />
        </Modal>
    ) : (
        <span>No survey found.</span>
    );
};
