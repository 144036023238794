import React, {HTMLAttributes} from 'react';

import join from 'lodash/join';

import styles from './heading.module.scss';

export type HeadingProps = HTMLAttributes<HTMLHeadingElement> & {
    element: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const Heading: React.FC<HeadingProps> = ({
    element,
    className,
    children,
    ...restParam
}) => {
    const Tag = element;

    return (
        <Tag
            className={join(
                [styles.heading, styles[element], className ? className : ''],
                ' ',
            )}
            {...restParam}
        >
            {children}
        </Tag>
    );
};
