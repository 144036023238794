import {MediaObject} from 'iterfaces/media-object';

export type Vendor = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    roles: string[];
    active: boolean;
    createdAt: string;
    lastLoggedAt: string | null;
    logo?: MediaObject;
};

export type CreateVendorRequest = Pick<
    Vendor,
    'email' | 'firstName' | 'lastName'
> & {
    password: string;
};

export type UpdateVendorRequest = Omit<Partial<Vendor>, 'logo'> &
    Partial<{
        logo: number;
    }>;

export interface VendorAuthForm {
    agree: boolean;
    email: string;
    password: string;
    referral: string;
}

export interface VendorInfoForm {
    companyName: string;
}

export interface VendorForm extends VendorAuthForm, VendorInfoForm {}

export type ConfirmVendorRequest = {
    confirmationToken: string;
};

export type ResetPasswordRequest = {
    email: string;
};

export type ConfirmResettingPasswordRequest = {
    token: string;
    password: string;
};

export const initVendorAuthForm: VendorAuthForm = {
    agree: false,
    email: '',
    password: '',
    referral: '',
};

export const initVendorInfoForm: VendorInfoForm = {
    companyName: '',
};

export const initVendor: Vendor = {
    id: 0,
    email: '',
    createdAt: '',
    lastLoggedAt: '',
    firstName: '',
    lastName: '',
    active: false,
    roles: ['ROLE_USER'],
};

export const initVendorForm = {...initVendorAuthForm, ...initVendorInfoForm};
