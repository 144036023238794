import {
    ConfirmResettingPasswordRequest,
    ConfirmVendorRequest,
    CreateVendorRequest,
    ResetPasswordRequest,
    UpdateVendorRequest,
    Vendor,
} from 'iterfaces/Vendor';
import {trackPromise} from 'react-promise-tracker';
import Api, {ErrorCallback, ObjectType, ResponseCallback} from 'services/Api';

const VENDORS_ENDPOINT = 'vendors';

export type CreateVendorResponse = Omit<Vendor, 'password'>;

export const createVendor = (
    data: CreateVendorRequest,
    onSuccess: ResponseCallback<CreateVendorResponse>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.post<CreateVendorRequest, CreateVendorResponse>(VENDORS_ENDPOINT, data, {
            onSuccess,
            onError,
        }),
    );
};

export const getMe = (
    onSuccess: ResponseCallback<Vendor>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<Vendor>('vendors/me', {
            onSuccess,
            onError,
        }),
    );
};

export const readVendors = (
    onSuccess: ResponseCallback<Vendor[]>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<Vendor[]>(`${VENDORS_ENDPOINT}?page=1&itemsPerPage=1000`, {
            onSuccess,
            onError,
        }),
    );
};

export const updateVendor = (
    id: Vendor['id'],
    data: UpdateVendorRequest,
    onSuccess: ResponseCallback<Vendor>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.put<UpdateVendorRequest, Vendor>(`${VENDORS_ENDPOINT}/${id}`, data, {
            onSuccess,
            onError,
        }),
    );
};

export const deleteVendor = (
    id: number,
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    Api.delete(`vendors/${id}`, {
        onSuccess,
        onError,
    });
};

export const activateVendor = (
    data: ConfirmVendorRequest,
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    Api.put<ConfirmVendorRequest, ObjectType>('vendor_verify', data, {
        onSuccess,
        onError,
    });
};

export const requestResettingPassword = (
    data: ResetPasswordRequest,
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.post<ResetPasswordRequest, ObjectType>(
            `${VENDORS_ENDPOINT}/reset_password`,
            data,
            {
                onSuccess,
                onError,
            },
        ),
    );
};

export const confirmResettingPassword = (
    data: ConfirmResettingPasswordRequest,
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.post<ConfirmResettingPasswordRequest, ObjectType>(
            `${VENDORS_ENDPOINT}/reset_password_confirm`,
            data,
            {
                onSuccess,
                onError,
            },
        ),
    );
};
