import {applyMiddleware, combineReducers, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import {NODE_ENV} from '../services/Config';

import app, {AppStore} from './app/reducer';
import user, {UserStore} from './user/reducer';
import survey, {SurveyStore} from './survey/reducer';
import templates, {TemplatesStore} from './templates/reducer';
import contactGroups, {ContactGroupsStore} from './contact-groups/reducer';

const reducers = combineReducers({
    app,
    user,
    survey,
    templates,
    contactGroups,
});

export type Store = {
    app: AppStore;
    user: UserStore;
    survey: SurveyStore;
    templates: TemplatesStore;
    contactGroups: ContactGroupsStore;
};

export default createStore(
    reducers,
    NODE_ENV === 'development'
        ? composeWithDevTools(applyMiddleware(thunk, logger))
        : applyMiddleware(thunk),
);
