import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {Survey} from 'iterfaces/survey';
import {useTemplates} from 'store/templates/hooks';
import {readSurveys} from 'api/survey.api';

import {ErrorMessage} from 'components/error-message';
import {Heading} from 'components/html/heading';
import {ActionDataTable} from 'components/action-data-table';
import {Modal, useModal} from 'components/modal';

import DASHBOARD_ROUTES from '../../../../routes';
import RESULTS_ROUTES from '../../../results/routes';

import styles from './start.module.scss';

const useTemplatesSubscription = (
    page = 1,
): [
    Survey[],
    (callback?: () => void) => void,
    (callback?: () => void) => void,
    boolean,
] => {
    const [_templates, _setTemplates] = useTemplates();
    const [templates, setTemplates] = useState<Survey[]>([]);
    const [_page, setPage] = useState<number>(page);
    const [noMore, setNoMore] = useState<boolean>(false);
    const [showModal] = useModal();

    const updateValues = (callback?: () => void) => {
        readSurveys(
            {published: true, template: false, page: _page},
            ({data}) => {
                setTemplates(data);
                _setTemplates([..._templates, ...data]);
                setPage(_page + 1);
                if (callback) {
                    callback();
                }
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    const loadMore = (callback?: () => void) => {
        readSurveys(
            {published: true, template: false, page: _page},
            ({data}) => {
                setTemplates([...templates, ...data]);
                _setTemplates([..._templates, ...data]);
                setPage(_page + 1);
                if (data.length === 0) {
                    setNoMore(true);
                }
                window.scrollTo({top: 999999999, behavior: 'smooth'});
                callback?.();
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    useEffect(() => {
        updateValues();
    }, []);

    return [templates, updateValues, loadMore, noMore];
};

export const Start: React.FC<RouteComponentProps> = ({history}) => {
    //eslint-disable-next-line
    const [subscription, updateSubscription, loadMore, noMore] =
        useTemplatesSubscription(1);

    return (
        <Modal
            size={'full'}
            hasCloseIcon={true}
            closeIconClickHandler={() => {
                history.push(DASHBOARD_ROUTES.START);
            }}
        >
            <Heading element={'h4'} className={styles.heading}>
                {I18n.t('dashboard.results.title')}
            </Heading>
            <ActionDataTable<Survey>
                data={subscription}
                columns={[
                    {
                        readableName: 'name',
                        value: 'translations.is.name',
                    },
                    'publishedDate',
                ]}
                mapHandlers={{
                    published: (value) => {
                        if (value) {
                            return I18n.t(
                                'dashboard.surveys.table.isPublishedLabel',
                            );
                        }
                        return I18n.t('dashboard.surveys.table.isNotPublishedLabel');
                    },
                    publishedDate: (value) => {
                        if (value) {
                            const date = new Date(value);
                            if (date) {
                                return date.toISOString().split('T')[0];
                            }
                        }
                        return '-';
                    },
                }}
                actions={[
                    {
                        button: {
                            icon: {
                                icon: 'preview',
                            },
                            title: I18n.t('dashboard.results.table.viewButton'),
                            children: I18n.t('dashboard.results.table.viewButton'),
                        },
                        onClick: ({id}) => {
                            history.push(RESULTS_ROUTES.VIEW(id));
                        },
                    },
                ]}
                readableNames={{
                    name: I18n.t('survey.name'),
                    publishedDate: I18n.t('survey.publishedDate'),
                }}
                className={styles.table}
            />
            {!noMore ? (
                <div className={styles.loadMore} onClick={() => loadMore()}>
                    {I18n.t('dashboard.templates.loadMore')}
                </div>
            ) : (
                <span className={styles.noMoreItems}>
                    {I18n.t('dashboard.templates.noMoreItems')}
                </span>
            )}
        </Modal>
    );
};
