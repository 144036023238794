const START_ROUTE = '/dashboard';

export default {
    START: window.location.pathname === '/' ? '/' : START_ROUTE,
    VENDORS: `${START_ROUTE}/vendors`,
    TEMPLATES: `${START_ROUTE}/templates`,
    RECIPIENTS: `${START_ROUTE}/recipients`,
    SURVEYS: `${START_ROUTE}/surveys`,
    RESULTS: `${START_ROUTE}/results`,
    SETTINGS: `${START_ROUTE}/settings`,
};
