import {createVendor} from 'api/vendor.api';

import {SignUpForm} from 'components/forms/sign-up-form';
import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {pathnames} from '../../';

export const SignUpAction: React.FC<RouteComponentProps> = ({history}) => (
    <SignUpForm
        initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
        }}
        validateOnBlur={false}
        validateOnChange={false}
        apiCallback={createVendor}
        onSuccess={() => {
            history.push(pathnames.signUpSuccess);
        }}
    />
);
