import React, {HTMLAttributes} from 'react';

import join from 'lodash/join';

import {Icon} from 'components/icon';
import {Anchor} from 'components/html/anchor';

import {Heading} from 'components/html/heading';

import styles from './modal.module.scss';

export type ModalProps = HTMLAttributes<HTMLDivElement> & {
    size?: 'full' | 'compact';
    isFixed?: boolean;
    concreteBackground?: boolean;
    hasCloseIcon?: boolean;
    closeIconClickHandler?: () => void;
    title?: string;
    scroll?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
    size = 'full',
    isFixed = false,
    concreteBackground = false,
    hasCloseIcon = false,
    closeIconClickHandler,
    title,
    scroll = false,
    className,
    children,
    ...restParam
}) => {
    const modalElement = (
        <div
            {...restParam}
            className={join(
                [
                    styles.modal,
                    styles[size],
                    isFixed ? styles.fixed : '',
                    scroll ? styles.modalWithScroll : '',
                    className ? className : '',
                ],
                ' ',
            )}
        >
            {hasCloseIcon ? (
                <Anchor
                    className={styles.closeIcon}
                    onClick={() => {
                        closeIconClickHandler?.();
                    }}
                    role={'button'}
                    transitions={false}
                >
                    <Icon icon={'cross'} size={'medium'} />
                </Anchor>
            ) : null}
            {title ? <Heading element={'h3'}>{title}</Heading> : null}
            <div
                className={join(
                    [styles.container, scroll ? styles.containerWithScroll : ''],
                    ' ',
                )}
            >
                {children}
            </div>
        </div>
    );

    return isFixed ? (
        <div
            className={join(
                [
                    styles.wrapper,
                    concreteBackground ? styles.concreteBackground : '',
                ],
                ' ',
            )}
        >
            {modalElement}
        </div>
    ) : (
        modalElement
    );
};
