import React from 'react';
import classNames from 'classnames';

import I18n from 'services/I18n';

import styles from './footer.module.scss';

export type FooterProps = {
    logo: string;
    link: string;
    className?: string;
};

export const Footer: React.FC<FooterProps> = ({logo, link, className}) => {
    return (
        <div className={classNames(styles.footer, className)}>
            <p className={styles.annotation}>{I18n.t('header.poweredBy')}</p>
            <a href={link} target="_blank" rel="noreferrer">
                <img src={logo} className={styles.logo} alt={I18n.t('app.title')} />
            </a>
        </div>
    );
};
