import React, {ReactElement, useState, useCallback, useMemo} from 'react';

import {Modal, ModalProps} from './';

export type ModalContextType = {
    showModal: (modal: ModalProps) => void;
    hideModal: () => void;
};

const invariantViolation = () => {
    throw new Error(
        'Attempted to use Modal context outside of Modal context provider',
    );
};

export const ModalContext = React.createContext<ModalContextType>({
    showModal: invariantViolation,
    hideModal: invariantViolation,
});

export const ModalProvider: React.FC = ({children}) => {
    const [modal, setModal] = useState<ReactElement<ModalProps> | null>(null);

    const hideModal = useCallback(() => {
        setModal(null);
    }, []);

    const showModal = useCallback((modal: ModalProps) => {
        setModal(
            <Modal
                closeIconClickHandler={modal.hasCloseIcon ? hideModal : undefined}
                {...modal}
                isFixed={true}
                size={'compact'}
            />,
        );
    }, []);

    const contextValue = useMemo(() => ({showModal, hideModal}), []);

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
            {modal}
        </ModalContext.Provider>
    );
};
