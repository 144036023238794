import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {ContactGroup} from 'iterfaces/contact-group';
import {deleteContactGroup} from 'api/contact-group.api';

import {Anchor} from 'components/html/anchor';
import {Icon} from 'components/icon';
import {Heading} from 'components/html/heading';
import {Modal, useModal} from 'components/modal';
import {Button} from 'components/html/button';
import {ActionDataTable} from 'components/action-data-table';
import {Paragraph} from 'components/html/paragraph';
import {ErrorMessage} from 'components/error-message';

import {useContactGroupsSubscription} from '../../';

import DASHBOARD_ROUTES from '../../../../routes';
import RECIPIENTS_ROUTES from '../../routes';

import styles from './start.module.scss';

export const Start: React.FC<RouteComponentProps> = ({history}) => {
    const [subscription, updateSubscription] = useContactGroupsSubscription();
    const [showModal] = useModal();

    const subscriptionParsed = subscription.map((item) => {
        return {
            ...item,
            name: `[ ${item.locale.toUpperCase()} ] ${item.name}`,
        };
    });

    return (
        <div className={styles.container}>
            <Modal size={'full'} className={styles.modal}>
                <Heading element={'h4'} className={styles.heading}>
                    {I18n.t('dashboard.contacts.contactGroups.title')}
                </Heading>
                <Paragraph
                    className={styles.description}
                    fontSize={'small'}
                    muted={true}
                >
                    {I18n.t('dashboard.contacts.contactGroups.description')}
                </Paragraph>
                <Anchor
                    onClick={() => {
                        history.push(DASHBOARD_ROUTES.START);
                    }}
                    className={styles.button}
                    title={I18n.t('navigation.return')}
                    transitions={false}
                >
                    <Icon icon={'cross'} size={'medium'} />
                </Anchor>
                <ActionDataTable<ContactGroup>
                    data={subscriptionParsed}
                    columns={['name']}
                    columnWidths={{
                        name: '60%',
                    }}
                    actions={[
                        {
                            button: {
                                icon: {
                                    icon: 'minus',
                                },
                                children: I18n.t(
                                    'dashboard.contacts.contactGroups.table.deleteButton',
                                ),
                            },
                            onClick: ({id}) => {
                                deleteContactGroup(
                                    id,
                                    () => {
                                        updateSubscription();
                                    },
                                    ({code}) => {
                                        showModal({
                                            children: <ErrorMessage error={code} />,
                                        });
                                    },
                                );
                            },
                        },
                        {
                            button: {
                                icon: {
                                    icon: 'pen',
                                },
                                children: I18n.t(
                                    'dashboard.contacts.contactGroups.table.editButton',
                                ),
                            },
                            onClick: (value) => {
                                history.push(
                                    RECIPIENTS_ROUTES.EDIT_CONTACT_GROUP_CONTACTS(
                                        value.id,
                                    ),
                                );
                            },
                        },
                    ]}
                    showTableHeader={false}
                    className={styles.table}
                />
            </Modal>
            <div className={styles.navigation}>
                <Button
                    onClick={() => {
                        history.push(RECIPIENTS_ROUTES.CREATE_CONTACT_GROUP);
                    }}
                    size={'big'}
                    variant={'primary'}
                    icon={{icon: 'template', size: 'medium', variant: 'light'}}
                    className={styles.button}
                    title={I18n.t('dashboard.contacts.newRecipientGroupButton')}
                >
                    {I18n.t('dashboard.contacts.newRecipientGroupButton')}
                </Button>
            </div>
        </div>
    );
};
