import * as yup from 'yup';
import I18n from 'services/I18n';

import {emailSchema} from './email';

export const nameSchema = yup
    .string()
    .max(360, I18n.t('validation.contact.name.tooLong', {number: 360}));

export const createContactFormSchema = yup.object().shape({
    name: nameSchema.required(I18n.t('validation.contact.name.missing')),
    email: emailSchema.required(I18n.t('validation.email.missing')),
});

export const contactsImportFileSchema = yup.array().of(
    yup.object().shape({
        name: nameSchema.required(I18n.t('validation.contact.name.missing')),
        email: emailSchema.required(I18n.t('validation.email.missing')),
    }),
);
