import {Button} from 'components/html/button';
import React, {useState} from 'react';
import I18n from 'i18n-js';
import {useParams} from 'react-router-dom';

import {unsubscribeRecipient} from 'api/recipient.api';

import s from './styles.module.scss';

export const Unsubscribe = () => {
    const {token} = useParams<{token: string}>();
    const [unsubscribed, setUnsubscribed] = useState<boolean>(false);

    const unsubscribe = () => {
        unsubscribeRecipient(
            token,
            () => setUnsubscribed(true),
            () => null,
        );
    };

    return (
        <div className={s.container}>
            <h1>{I18n.t('unsubscribe.title')}</h1>
            {!unsubscribed ? (
                <Button size="big" onClick={unsubscribe}>
                    {I18n.t('unsubscribe.unsubscribe')}
                </Button>
            ) : (
                I18n.t('unsubscribe.success')
            )}
        </div>
    );
};
