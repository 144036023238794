import {Anchor} from 'components/html/anchor';
import {Button} from 'components/html/button';
import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {Footer} from 'components/html/footer';
import {Icon, IconProps} from 'components/icon';
import {Modal} from 'components/modal';
import I18n from 'i18n-js';
import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useVendor} from 'store/user/hooks';
import DASHBOARD_ROUTES from '../../routes';
import SURVEYS_ROUTES from '../surveys/routes';
import styles from './start.module.scss';
import logoFooter from '../../../../static/logo-footer.png';

const Tile: React.FC<{
    handler: () => void;
    title: string;
    description: string;
    icon: IconProps['icon'];
}> = ({handler, title, description, icon}) => (
    <Modal
        className={styles.tile}
        onClick={() => {
            handler();
        }}
    >
        <Heading element={'h4'}>{title}</Heading>
        <Paragraph fontSize={'small'} className={styles.tileParagraph}>
            {description}
        </Paragraph>
        <Anchor
            onClick={() => {
                handler();
            }}
            transitions={false}
            className={styles.tileButton}
        >
            <Icon icon={icon} size={'medium'} />
        </Anchor>
    </Modal>
);

export const Start: React.FC<RouteComponentProps> = ({history}) => {
    const [vendor] = useVendor();

    return (
        <div className={styles.content}>
            <div className={styles.container}>
                <div className={styles.tiles}>
                    <Tile
                        title={I18n.t('dashboard.start.tiles.settings.title')}
                        description={I18n.t(
                            'dashboard.start.tiles.settings.description',
                        )}
                        icon={'pen'}
                        handler={() => {
                            history.push(DASHBOARD_ROUTES.SETTINGS);
                        }}
                    />
                    {vendor.roles.includes('ROLE_ADMIN') ? (
                        <Tile
                            title={I18n.t('dashboard.start.tiles.vendors.title')}
                            description={I18n.t(
                                'dashboard.start.tiles.vendors.description',
                            )}
                            icon={'pen'}
                            handler={() => {
                                history.push(DASHBOARD_ROUTES.VENDORS);
                            }}
                        />
                    ) : null}
                    {vendor.roles.includes('ROLE_USER') &&
                    !vendor.roles.includes('ROLE_ADMIN') ? (
                        <Tile
                            title={I18n.t('dashboard.start.tiles.results.title')}
                            description={I18n.t(
                                'dashboard.start.tiles.results.description',
                            )}
                            icon={'chart'}
                            handler={() => {
                                history.push(DASHBOARD_ROUTES.RESULTS);
                            }}
                        />
                    ) : null}
                    {vendor.roles.includes('ROLE_USER') &&
                    !vendor.roles.includes('ROLE_ADMIN') ? (
                        <Tile
                            title={I18n.t('dashboard.start.tiles.recipients.title')}
                            description={I18n.t(
                                'dashboard.start.tiles.recipients.description',
                            )}
                            icon={'pen'}
                            handler={() => {
                                history.push(DASHBOARD_ROUTES.RECIPIENTS);
                            }}
                        />
                    ) : null}
                </div>
                <div className={styles.buttons}>
                    {vendor.roles.includes('ROLE_USER') &&
                    !vendor.roles.includes('ROLE_ADMIN') ? (
                        <Button
                            onClick={() => {
                                history.push(SURVEYS_ROUTES.CREATE);
                            }}
                            size={'big'}
                            variant={'primary'}
                            icon={{icon: 'plus', size: 'medium', variant: 'light'}}
                            className={styles.button}
                            title={I18n.t('dashboard.start.newSurveyButton')}
                        >
                            {I18n.t('dashboard.start.newSurveyButton')}
                        </Button>
                    ) : null}
                    {vendor.roles.includes('ROLE_USER') &&
                    !vendor.roles.includes('ROLE_ADMIN') ? (
                        <Button
                            onClick={() => {
                                history.push(DASHBOARD_ROUTES.SURVEYS);
                            }}
                            size={'big'}
                            variant={'primary'}
                            className={styles.button}
                            icon={{
                                icon: 'template',
                                size: 'medium',
                                variant: 'light',
                            }}
                            title={I18n.t('dashboard.start.surveysButton')}
                        >
                            {I18n.t('dashboard.start.surveysButton')}
                        </Button>
                    ) : null}
                    {vendor.roles.includes('ROLE_ADMIN') ||
                    vendor.roles.includes('ROLE_USER') ? (
                        <Button
                            onClick={() => {
                                history.push(DASHBOARD_ROUTES.TEMPLATES);
                            }}
                            size={'big'}
                            variant={'primary'}
                            className={styles.button}
                            icon={{
                                icon: 'template',
                                size: 'medium',
                                variant: 'light',
                            }}
                            title={I18n.t('dashboard.start.templatesButton')}
                        >
                            {I18n.t('dashboard.start.templatesButton')}
                        </Button>
                    ) : null}
                </div>
            </div>
            <Footer logo={logoFooter} link={'https://haefni.is'} />
        </div>
    );
};
