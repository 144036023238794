import * as ACTIONS from './types';

export interface AppStore {
    menuOpen: boolean;
}

export interface Action {
    type: string;
    payload: Partial<AppStore>;
}

const initialState: AppStore = {
    menuOpen: false,
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case ACTIONS.SET_MENU_IS_OPEN: {
            return {...state, menuOpen: action.payload.menuOpen};
        }
        default:
            return state;
    }
};
