import {deleteVendor, readVendors, updateVendor} from 'api/vendor.api';

import {AxiosResponse} from 'axios';

import {ActionDataTable} from 'components/action-data-table';
import {ErrorMessage} from 'components/error-message';
import {Anchor} from 'components/html/anchor';
import {Heading} from 'components/html/heading';
import {Icon} from 'components/icon';
import {Modal, useModal} from 'components/modal';

import {Vendor} from 'iterfaces/Vendor';
import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';
import DASHBOARD_ROUTES from '../../routes';

import styles from './vendors.module.scss';

const useVendorsSubscription = (): [Vendor[], () => void] => {
    const [values, setValues] = useState<Vendor[]>([]);
    const [showModal] = useModal();

    const updateValues = () => {
        readVendors(
            (response: AxiosResponse<Vendor[]>) => {
                setValues(response.data);
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    useEffect(() => {
        updateValues();
    }, []);

    return [values, updateValues];
};

export const Vendors: React.FC<RouteComponentProps> = ({history}) => {
    const [subscription, updateSubscription] = useVendorsSubscription();
    const [showModal] = useModal();

    return (
        <Modal size={'full'} className={styles.modal}>
            <Heading element={'h4'} className={styles.heading}>
                {I18n.t('dashboard.vendors.title')}
            </Heading>
            <ActionDataTable<Vendor>
                data={subscription}
                columns={['firstName', 'lastName', 'email', 'active']}
                readableNames={{
                    firstName: I18n.t('vendor.firstName'),
                    lastName: I18n.t('vendor.lastName'),
                    email: I18n.t('vendor.email'),
                    active: I18n.t('dashboard.vendors.list.status'),
                }}
                columnWidths={{
                    firstName: '17%',
                    lastName: '17%',
                    email: '36%',
                    active: '30%',
                }}
                actionColumns={{
                    active: [
                        {
                            test: (value) => value,
                            button: {
                                icon: {
                                    icon: 'check',
                                },
                                children: I18n.t(
                                    'dashboard.vendors.list.activeVendor',
                                ),
                                title: I18n.t(
                                    'dashboard.vendors.list.disableVendor',
                                ),
                            },
                            onClick: ({id}) => {
                                updateVendor(
                                    id,
                                    {active: false},
                                    () => {
                                        updateSubscription();
                                    },
                                    ({code}) => {
                                        showModal({
                                            children: <ErrorMessage error={code} />,
                                        });
                                    },
                                );
                            },
                        },
                        {
                            test: (value) => !value,
                            button: {
                                icon: {
                                    icon: 'minus',
                                },
                                children: I18n.t(
                                    'dashboard.vendors.list.disabledVendor',
                                ),
                                title: I18n.t(
                                    'dashboard.vendors.list.activateVendor',
                                ),
                            },
                            onClick: ({id}) => {
                                updateVendor(
                                    id,
                                    {active: true},
                                    () => {
                                        updateSubscription();
                                    },
                                    ({code}) => {
                                        showModal({
                                            children: <ErrorMessage error={code} />,
                                        });
                                    },
                                );
                            },
                        },
                    ],
                }}
                actions={[
                    {
                        button: {
                            icon: {
                                icon: 'minus' as const,
                            },
                            title: I18n.t('dashboard.vendors.list.deleteButton'),
                            children: I18n.t('dashboard.vendors.list.deleteButton'),
                        },
                        onClick: (vendor) => {
                            deleteVendor(
                                vendor.id,
                                () => {
                                    updateSubscription();
                                },
                                console.error,
                            );
                        },
                    },
                ]}
                className={styles.table}
            />
            <Anchor
                onClick={() => {
                    history.push(DASHBOARD_ROUTES.START);
                }}
                className={styles.button}
                title={I18n.t('navigation.return')}
                transitions={false}
            >
                <Icon icon={'cross'} size={'medium'} />
            </Anchor>
        </Modal>
    );
};
