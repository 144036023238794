import I18n from 'services/I18n';

export const answerInit = {
    id: 0,
    name: '',
    translations: {},
};

export const questionInit = {
    id: 0,
    name: '',
    type: 'singleChoice',
    answers: [answerInit],
    translations: {},
};

export const sectionInit = {
    id: 0,
    name: '',
    questions: [questionInit],
    translations: {},
};

export const surveyTemplateInit = {
    id: 0,
    name: I18n.t('form.survey.defaultValues.name'),
    description: I18n.t('form.survey.defaultValues.description'),
    sections: [sectionInit],
    translations: {},
};

export const logoInit = {
    id: 0,
    contentUrl: '',
};

export const surveyInit = Object.assign(
    {
        logo: logoInit,
    },
    surveyTemplateInit,
);
