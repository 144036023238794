import DASHBOARD_ROUTES from '../../routes';

export default {
    START: DASHBOARD_ROUTES.SURVEYS,
    CREATE: `${DASHBOARD_ROUTES.SURVEYS}/create`,
    EDIT_CONTACTS: `${DASHBOARD_ROUTES.SURVEYS}/select-recipients`,
    EDIT_SURVEY: (id: number | string, locale = 'is') =>
        `${DASHBOARD_ROUTES.SURVEYS}/edit/${id}${
            locale !== 'is' ? `/${locale}` : ''
        }`,
};
