import React, {HTMLAttributes} from 'react';

import {join} from 'lodash';

import styles from './paragraph.module.scss';

export type ParagraphProps = HTMLAttributes<HTMLParagraphElement> & {
    fontSize?: 'normal' | 'small';
    muted?: boolean;
};

export const Paragraph: React.FC<ParagraphProps> = ({
    fontSize = 'normal',
    muted = false,
    className,
    ...restParam
}) => {
    return (
        <p
            className={join(
                [
                    styles.paragraph,
                    fontSize !== 'normal' ? styles.smallFontSize : '',
                    muted ? styles.muted : '',
                    className ? className : '',
                ],
                ' ',
            )}
            {...restParam}
        >
            {restParam.children}
        </p>
    );
};
