import {get} from 'lodash';
import {ActionDataTableProps} from '../action-data-table';

export const extractColumnValue = <T>(
    column: ActionDataTableProps<T>['columns'][0],
    data: T,
) => {
    if (typeof column === 'object') {
        return get(data, column.value, '-');
    } else {
        return get(data, column, '-');
    }
};

export const extractColumnMapHandler = <T>(
    column: ActionDataTableProps<T>['columns'][0],
    data: ActionDataTableProps<T>['mapHandlers'],
) => {
    if (typeof column === 'object') {
        return get(data, column.readableName);
    } else {
        return get(data, column);
    }
};

export const extractColumnReadableName = <T>(
    column: ActionDataTableProps<T>['columns'][0],
    data: ActionDataTableProps<T>['readableNames'],
) => {
    if (typeof column === 'object') {
        return get(data, column.readableName, column.readableName);
    } else {
        return get(data, column, column);
    }
};

export const extractColumnWidth = <T>(
    column: ActionDataTableProps<T>['columns'][0],
    data: ActionDataTableProps<T>['columnWidths'],
) => {
    if (typeof column === 'object') {
        return get(data, column.readableName);
    } else {
        return get(data, column);
    }
};

export const extractColumnName = <T>(
    column: ActionDataTableProps<T>['columns'][0],
) => {
    if (typeof column === 'object') {
        return column.readableName;
    } else {
        return column;
    }
};
