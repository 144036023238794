import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {Button} from 'components/html/button';

import {pathnames} from '../../log-in';

import styles from './sign-up-success.module.scss';

export const SignUpSuccess: React.FC<RouteComponentProps> = ({history}) => (
    <>
        <Heading element={'h3'}>
            {I18n.t('form.signUp.successMessageHeading')}
        </Heading>
        <Paragraph>{I18n.t('form.signUp.successMessageParagraph')}</Paragraph>
        <Button
            onClick={() => {
                history.push(pathnames.logInAction);
            }}
            className={styles.button}
        >
            {I18n.t('vendor.logIn')}
        </Button>
    </>
);
