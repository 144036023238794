import {CreateRecipientAnswerRequest} from 'iterfaces/recipient-answer';
import Api, {ErrorCallback, ObjectType, ResponseCallback} from 'services/Api';

export const createRecipientAnswer = (
    data: CreateRecipientAnswerRequest,
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    Api.post<CreateRecipientAnswerRequest, ObjectType>('recipient_answers', data, {
        onSuccess,
        onError,
    });
};
