import React, {useEffect} from 'react';

import {ContactGroup} from 'iterfaces/contact-group';
import {readContactGroups} from 'api/contact-group.api';

import {useContactGroups} from 'store/contact-groups/hooks';
import {useModal} from 'components/modal';
import {ErrorMessage} from 'components/error-message';

export const useContactGroupsSubscription = (): [
    ContactGroup[],
    (callback?: () => void) => void,
] => {
    const [contactGroups, setContactGroups] = useContactGroups();
    const [showModal] = useModal();

    const updateValues = (callback?: () => void) => {
        readContactGroups(
            (response) => {
                setContactGroups(response.data);
                if (callback) {
                    callback();
                }
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    useEffect(() => {
        updateValues();
    }, []);

    return [contactGroups, updateValues];
};
