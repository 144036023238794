import {CreateSurveyFormValues} from '../';

import * as initialValues from '../initial-values';

export const fillCreateSurveyFormInitialValues = (
    values?: CreateSurveyFormValues,
): CreateSurveyFormValues | undefined => {
    if (values) {
        return {
            ...values,
            //eslint-disable-next-line
            //@ts-ignore
            sections: (values.sections.length > 0 && values.sections) || [
                initialValues.sectionInit,
            ],
            logo: values.logo || initialValues.logoInit,
        };
    }
};
