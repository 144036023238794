import {useDispatch, useSelector} from 'react-redux';

import {setApiAuthorization} from '../../api/authorization.api';
import Auth, {initAuth} from '../../iterfaces/Auth';

import {Vendor, VendorForm} from '../../iterfaces/Vendor';

import {Store} from '../index';

import {setAuth as setAuthAction, setVendor, setVendorForm} from './actionCreators';
import {removeAuth, setAuth} from './auth';

export const useAuth = (): [Auth, (auth: Partial<Auth>) => void, () => void] => {
    const dispatch = useDispatch();

    const _setAuth = (auth: Partial<Auth>) => {
        if (auth.token) {
            setApiAuthorization(auth.token);
        }
        setAuth(auth);
        dispatch(setAuthAction({...initAuth, ...auth}));
    };

    const _removeAuth = () => {
        setApiAuthorization();
        removeAuth();
        dispatch(setAuthAction(initAuth));
    };

    return [
        useSelector<Store, Auth>(({user}) => ({
            vendor: user.vendor,
            token: user.token,
        })),
        _setAuth,
        _removeAuth,
    ];
};

export const useVendor = (): [Vendor, (vendor: Vendor) => void] => {
    const token = useSelector<Store, string>(({user}) => user.token);
    const dispatch = useDispatch();

    const setUser = (vendor: Vendor) => {
        setAuthAction({token, vendor});
        dispatch(setVendor(vendor));
    };

    return [useSelector<Store, Vendor>(({user}) => user.vendor), setUser];
};

export const useUserIsLoggedIn = (): boolean => {
    const [user] = useVendor();
    const [token] = useAuth();
    return !!(user.id && token);
};

export const useRegistrationForm = (): [
    VendorForm,
    (vendorForm: Partial<VendorForm>) => void,
] => {
    const dispatch = useDispatch();

    return [
        useSelector<Store, VendorForm>((state) => state.user.vendorForm),
        (vendorForm: Partial<VendorForm>) => dispatch(setVendorForm(vendorForm)),
    ];
};
