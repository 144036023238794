import React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import ROUTES from './routes';
import {CreateTemplate} from './routes/create-template';
import {EditTemplate} from './routes/edit-template';
import {Start} from './routes/start';

export const Templates: React.FC<RouteComponentProps> = () => {
    return (
        <>
            <Route exact path={ROUTES.START} component={Start} />
            <Route path={ROUTES.CREATE_TEMPLATE} component={CreateTemplate} />
            <Route
                path={ROUTES.EDIT_TEMPLATE(':id')}
                component={EditTemplate}
                exact
            />
            <Route
                path={ROUTES.EDIT_TEMPLATE(':id', ':locale')}
                component={EditTemplate}
                exact
            />
        </>
    );
};
