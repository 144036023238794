import React from 'react';
import {
    MemoryRouter as Router,
    Route,
    Switch,
    RouteComponentProps,
} from 'react-router-dom';

import classNames from 'classnames';

import I18n from 'services/I18n';

import {Heading} from 'components/html/heading';
import {Navigation} from 'components/html/navigation';
import {Modal} from 'components/modal';

import {LogInAction} from './routes/log-in-action';
import {SignUpAction} from './routes/sign-up-action';
import {SignUpSuccess} from './routes/sign-up-success';
import {ResetPasswordAction} from './routes/reset-password-action';
import {ResetPasswordSuccess} from './routes/reset-password-success';

import styles from './log-in.module.scss';

export const pathnames = {
    logInAction: '/log-in-action',
    signUpAction: '/sign-up-action',
    signUpSuccess: '/sign-up-success',
    resetPasswordAction: '/reset-password-action',
    resetPasswordSuccess: '/reset-password-success',
};

export const LogIn: React.FC<RouteComponentProps> = () => (
    <div className={styles.container}>
        <div className={styles.wrapper}>
            <Router initialEntries={Object.values(pathnames)} initialIndex={0}>
                <Route>
                    {({history, location}: RouteComponentProps) =>
                        location.pathname !== pathnames.signUpSuccess ? (
                            <Navigation
                                buttons={[
                                    {
                                        children: I18n.t('vendor.logIn'),
                                        onClick: () => {
                                            history.push('/log-in-action');
                                        },
                                        transitionEffects: false,
                                        isActive:
                                            location.pathname ===
                                            pathnames.logInAction,
                                    },
                                    {
                                        children: I18n.t('vendor.signUp'),
                                        onClick: () => {
                                            history.push('/sign-up-action');
                                        },
                                        transitionEffects: false,
                                        isActive:
                                            location.pathname ===
                                            pathnames.signUpAction,
                                    },
                                ]}
                                className={classNames(styles.navigation, {
                                    [styles.navigationSelected]:
                                        location.pathname !==
                                            pathnames.logInAction &&
                                        location.pathname !== pathnames.signUpAction,
                                })}
                            />
                        ) : null
                    }
                </Route>
                <Modal size={'compact'} style={{marginBottom: '4rem'}}>
                    <Heading element={'h1'} className={styles.heading}>
                        {I18n.t('app.title')}
                    </Heading>
                    <Switch>
                        <Route
                            path={pathnames.logInAction}
                            component={LogInAction}
                        />
                        <Route
                            path={pathnames.signUpAction}
                            component={SignUpAction}
                        />
                        <Route
                            path={pathnames.signUpSuccess}
                            component={SignUpSuccess}
                        />
                        <Route
                            path={pathnames.resetPasswordAction}
                            component={ResetPasswordAction}
                        />
                        <Route
                            path={pathnames.resetPasswordSuccess}
                            component={ResetPasswordSuccess}
                        />
                    </Switch>
                </Modal>
            </Router>
        </div>
    </div>
);
