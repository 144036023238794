import {useContext} from 'react';

import {ModalContext, ModalContextType} from './index';

export const useModal = (): [
    ModalContextType['showModal'],
    ModalContextType['hideModal'],
] => {
    const context = useContext(ModalContext);

    return [context.showModal, context.hideModal];
};
