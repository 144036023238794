import React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import ROUTES from './routes';
import {Create} from './routes/create';
import {EditSurvey} from './routes/edit-survey';
import {SelectSurveyContactGroups} from './routes/select-survey-contact-groups';
import {Start} from './routes/start';

export const Surveys: React.FC<RouteComponentProps> = () => (
    <>
        <Route exact path={ROUTES.START} component={Start} />
        <Route path={ROUTES.CREATE} component={Create} />
        <Route path={ROUTES.EDIT_SURVEY(':id')} component={EditSurvey} exact />
        <Route
            path={ROUTES.EDIT_SURVEY(':id', ':locale')}
            component={EditSurvey}
            exact
        />
        <Route path={ROUTES.EDIT_CONTACTS} component={SelectSurveyContactGroups} />
    </>
);
