import React from 'react';

import I18n from 'services/I18n';

import {Formik, Form, FieldArray, FormikConfig} from 'formik';

import {answersSurveySchema} from 'validators/survey';

import {Recipient} from 'iterfaces/recipient';

import {Button} from 'components/html/button';
import {Heading} from 'components/html/heading';

import {Questions} from './partials/questions';

import styles from './recipient-form.module.scss';

export type SingleSelectedAnswer = {
    multiple: false;
    questionId: number;
    answerId: string;
};

export type MultipleSelectedAnswer = {
    multiple: true;
    questionId: number;
    answerId: number;
    isSelected: boolean;
};

export type SingleOrMultipleSelectedAnswer =
    | SingleSelectedAnswer
    | MultipleSelectedAnswer;

export type RecipientFormValues = {
    locale: Recipient['locale'];
    sections: Recipient['survey']['sections'];
    selectedAnswers: SingleOrMultipleSelectedAnswer[];
};

export type RecipientFormQuestionsValues =
    RecipientFormValues['sections'][number]['questions'];

export type RecipientFormProps = Partial<FormikConfig<RecipientFormValues>> & {
    initialValues: RecipientFormValues;
    onSuccess?: (values: RecipientFormValues) => void;
    editable?: boolean;
    className?: string;
};

export const RecipientForm: React.FC<RecipientFormProps> = ({
    initialValues,
    onSuccess,
    editable = true,
    className,
    ...restParam
}) => {
    const {locale} = initialValues;
    return (
        <Formik
            validationSchema={answersSurveySchema}
            initialValues={initialValues}
            onSubmit={(values) => {
                onSuccess?.(values);
            }}
            validateOnChange={false}
            validateOnBlur={false}
            {...restParam}
        >
            {(formikProps) => {
                const {values} = formikProps;
                return (
                    <Form className={className}>
                        <FieldArray name={'selectedAnswers'}>
                            {() =>
                                values.sections.map((section, sectionIndex) => (
                                    <div
                                        className={styles.section}
                                        key={sectionIndex}
                                    >
                                        <Heading element={'h2'}>
                                            {section.translations[locale].name}
                                        </Heading>
                                        <Questions
                                            editable={editable}
                                            questions={section.questions}
                                            {...formikProps}
                                        />
                                    </div>
                                ))
                            }
                        </FieldArray>
                        {editable ? (
                            <div className={styles.controls}>
                                <Button
                                    size={'big'}
                                    variant={'primary'}
                                    icon={{
                                        icon: 'check',
                                        size: 'medium',
                                        variant: 'light',
                                    }}
                                    title={I18n.t(
                                        'form.recipient.controls.sendButton',
                                    )}
                                    type={'submit'}
                                >
                                    {I18n.t('form.recipient.controls.sendButton')}
                                </Button>
                            </div>
                        ) : null}
                    </Form>
                );
            }}
        </Formik>
    );
};
