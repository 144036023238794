import * as yup from 'yup';
import I18n from 'services/I18n';

export const nameSchema = yup
    .string()
    .max(100, I18n.t('validation.contactGroup.name.tooLong', {number: 100}));

export const descriptionSchema = yup
    .string()
    .max(500, I18n.t('validation.contactGroup.description.tooLong', {number: 500}));

export const localeSchema = yup.string().max(3);

export const createContactGroupFormSchema = yup.object().shape({
    name: nameSchema.required(I18n.t('validation.contactGroup.name.missing')),
    description: descriptionSchema.required(
        I18n.t('validation.contactGroup.description.missing'),
    ),
    locale: localeSchema.required(),
});

export const selectContactGroupsFormSchema = yup.object().shape({
    selectedContactGroup: yup
        .string()
        .required(
            I18n.t('validation.survey.contactGroups.selectedContactGroup.missing'),
        ),
});
