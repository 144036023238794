import {SurveyForm, surveyInit} from 'components/forms/survey-form';
import {Anchor} from 'components/html/anchor';
import {Icon} from 'components/icon';
import {Modal} from 'components/modal';
import {MediaObject} from 'iterfaces/media-object';
import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {useVendor} from 'store/user/hooks';

import DASHBOARD_ROUTES from '../../../../routes';
import SURVEYS_ROUTES from '../../routes';

import styles from './create.module.scss';

export const Create: React.FC<RouteComponentProps> = ({history}) => {
    const [vendor] = useVendor();
    return (
        <Modal size={'full'} className={styles.modal}>
            <Anchor
                onClick={() => {
                    history.push(DASHBOARD_ROUTES.START);
                }}
                className={styles.closeButton}
                title={I18n.t('navigation.return')}
                transitions={false}
            >
                <Icon icon={'cross'} size={'medium'} />
            </Anchor>
            <SurveyForm
                onSuccess={() => {
                    history.push(SURVEYS_ROUTES.EDIT_CONTACTS);
                }}
                onCancel={() => {
                    history.push(DASHBOARD_ROUTES.START);
                }}
                isTemplateForm={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                initialValues={{...surveyInit, logo: vendor.logo as MediaObject}}
            />
        </Modal>
    );
};
