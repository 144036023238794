import join from 'lodash/join';

import React, {TextareaHTMLAttributes, useState} from 'react';
import styles from './textarea.module.scss';

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
    help?: string;
    error?: string;
    fixedWidth?: boolean;
};

export const TextArea: React.FC<TextAreaProps> = ({
    fixedWidth = true,
    label,
    help,
    error,
    className,
    onFocus,
    onBlur,
    ...restParam
}) => {
    const [isFocus, setIsFocus] = useState(false);
    const isPlaceholder =
        restParam.placeholder && restParam.placeholder.trim().length > 0;

    return (
        <div className={styles.container}>
            {label ? (
                <label
                    className={join(
                        [
                            styles.label,
                            restParam.value || isFocus || isPlaceholder
                                ? styles.labelUp
                                : '',
                        ],
                        ' ',
                    )}
                >
                    {label}
                </label>
            ) : null}
            <textarea
                {...restParam}
                onFocus={(event) => {
                    setIsFocus(true);
                    if (onFocus) {
                        onFocus(event);
                    }
                }}
                onBlur={(event) => {
                    setIsFocus(false);
                    if (onBlur) {
                        onBlur(event);
                    }
                }}
                className={join(
                    [
                        styles.textarea,
                        fixedWidth ? styles.fixedWidth : '',
                        className,
                    ],
                    ' ',
                )}
            />
            <div
                className={join([styles.annotation, error ? styles.error : ''], ' ')}
            >
                {error || help}
            </div>
        </div>
    );
};
