import {cloneSurvey, deleteDurvey, readSurveys} from 'api/survey.api';
import {ActionDataTable} from 'components/action-data-table';
import {ErrorMessage} from 'components/error-message';
import {Anchor} from 'components/html/anchor';
import {Button} from 'components/html/button';
import {Heading} from 'components/html/heading';
import {Icon} from 'components/icon';
import {Modal, useModal} from 'components/modal';
import {Survey} from 'iterfaces/survey';
import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import I18n from 'services/I18n';
import {useTemplates} from 'store/templates/hooks';
import {useVendor} from 'store/user/hooks';
import DASHBOARD_ROUTES from '../../../../routes';
import SURVEYS_ROUTES from '../../../surveys/routes';
import TEMPLATES_ROUTES from '../../routes';
import styles from './start.module.scss';

const useTemplatesSubscription = (
    page = 1,
): [
    Survey[],
    (callback?: () => void) => void,
    (callback?: () => void) => void,
    boolean,
    (callback?: () => void) => void,
] => {
    const [_templates, _setTemplates] = useTemplates();
    const [templates, setTemplates] = useState<Survey[]>([]);
    const [_page, setPage] = useState<number>(page);
    const [noMore, setNoMore] = useState<boolean>(false);
    const [showModal] = useModal();

    const updateValues = (callback?: () => void) => {
        readSurveys(
            {published: false, template: true, page: _page},
            ({data}) => {
                setTemplates(data);
                _setTemplates([..._templates, ...data]);
                setPage(_page + 1);
                if (callback) {
                    callback();
                }
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    const refresh = (callback?: () => void) => {
        readSurveys(
            {published: false, template: true, page: 1},
            ({data}) => {
                setTemplates(data);
                _setTemplates([..._templates, ...data]);
                setPage(2);
                if (callback) {
                    callback();
                }
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    const loadMore = (callback?: () => void) => {
        readSurveys(
            {published: false, template: true, page: _page},
            ({data}) => {
                setTemplates([...templates, ...data]);
                _setTemplates([..._templates, ...data]);
                setPage(_page + 1);
                if (data.length === 0) {
                    setNoMore(true);
                }
                window.scrollTo({top: 999999999, behavior: 'smooth'});
                callback?.();
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    useEffect(() => {
        updateValues();
    }, []);

    return [templates, updateValues, loadMore, noMore, refresh];
};

export const Start: React.FC<RouteComponentProps> = ({history}) => {
    const [subscription, updateSubscription, loadMore, noMore, refresh] =
        useTemplatesSubscription(1);
    const [showModal] = useModal();
    const [vendor] = useVendor();

    return (
        <div className={styles.container}>
            <Modal size={'full'} className={styles.modal}>
                <Heading element={'h4'} className={styles.heading}>
                    {I18n.t('dashboard.templates.title')}
                </Heading>
                <Anchor
                    onClick={() => {
                        history.push(DASHBOARD_ROUTES.START);
                    }}
                    className={styles.button}
                    title={I18n.t('navigation.return')}
                    transitions={false}
                >
                    <Icon icon={'cross'} size={'medium'} />
                </Anchor>
                <ActionDataTable<Survey>
                    data={subscription}
                    columns={[
                        {
                            readableName: 'name',
                            value: 'translations.is.name',
                        },
                    ]}
                    languages={
                        vendor.roles.includes('ROLE_ADMIN')
                            ? [
                                  {
                                      locale: 'en',
                                      onClick: ({id}) => {
                                          history.push(
                                              TEMPLATES_ROUTES.EDIT_TEMPLATE(
                                                  id,
                                                  'en',
                                              ),
                                          );
                                      },
                                  },
                                  {
                                      locale: 'pl',
                                      onClick: ({id}) => {
                                          history.push(
                                              TEMPLATES_ROUTES.EDIT_TEMPLATE(
                                                  id,
                                                  'pl',
                                              ),
                                          );
                                      },
                                  },
                              ]
                            : undefined
                    }
                    actions={[
                        {
                            button: {
                                icon: {
                                    icon: 'pen',
                                },
                                title: I18n.t(
                                    'dashboard.templates.table.editButton',
                                ),
                                // children: I18n.t(
                                //     'dashboard.templates.table.editButton',
                                // ),
                                children: '',
                            },
                            test: () => vendor.roles.includes('ROLE_ADMIN'),
                            onClick: ({id}) => {
                                history.push(TEMPLATES_ROUTES.EDIT_TEMPLATE(id));
                            },
                        },
                        {
                            button: {
                                icon: {
                                    icon: 'plus',
                                },
                                title: I18n.t(
                                    'dashboard.templates.table.createSurveyButton',
                                ),
                                children: I18n.t(
                                    'dashboard.templates.table.createSurveyButton',
                                ),
                            },
                            test: () =>
                                vendor.roles.includes('ROLE_USER') &&
                                !vendor.roles.includes('ROLE_ADMIN'),
                            onClick: ({id}) => {
                                cloneSurvey(
                                    {
                                        survey: id,
                                    },
                                    ({data}) => {
                                        updateSubscription(() => {
                                            history.push(
                                                SURVEYS_ROUTES.EDIT_SURVEY(data.id),
                                            );
                                        });
                                    },
                                    ({code}) => {
                                        showModal({
                                            children: <ErrorMessage error={code} />,
                                        });
                                    },
                                );
                            },
                        },
                        {
                            button: {
                                icon: {
                                    icon: 'minus',
                                },
                                title: I18n.t(
                                    'dashboard.templates.table.deleteButton',
                                ),
                                // children: I18n.t(
                                //     'dashboard.templates.table.deleteButton',
                                // ),
                                children: '',
                            },
                            test: () => vendor.roles.includes('ROLE_ADMIN'),
                            onClick: ({id}) => {
                                if (
                                    window.confirm(
                                        'Ertu viss um að þú viljir eyða þessu sniðmáti að fullu?',
                                    )
                                ) {
                                    deleteDurvey(
                                        id,
                                        () => {
                                            refresh();
                                        },
                                        ({code}) => {
                                            showModal({
                                                children: (
                                                    <ErrorMessage error={code} />
                                                ),
                                            });
                                        },
                                    );
                                }
                            },
                        },
                    ]}
                    readableNames={{
                        name: I18n.t('survey.name'),
                    }}
                    className={styles.table}
                />
                {!noMore ? (
                    <div className={styles.loadMore} onClick={() => loadMore()}>
                        {I18n.t('dashboard.templates.loadMore')}
                    </div>
                ) : (
                    <span className={styles.noMoreItems}>
                        {I18n.t('dashboard.templates.noMoreItems')}
                    </span>
                )}
            </Modal>
            <div className={styles.navigation}>
                <Button
                    onClick={() => {
                        history.push(TEMPLATES_ROUTES.CREATE_TEMPLATE);
                    }}
                    size={'big'}
                    variant={'primary'}
                    icon={{icon: 'template', size: 'medium', variant: 'light'}}
                    className={styles.button}
                >
                    {I18n.t('dashboard.survey.newTemplate')}
                </Button>
            </div>
        </div>
    );
};
