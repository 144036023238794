import {
    Vendor,
    initVendor,
    initVendorForm,
    VendorForm,
} from '../../iterfaces/Vendor';

import * as ACTIONS from './types';

export interface UserStore {
    vendor: Vendor;
    vendorForm: VendorForm;
    token: string;
}

export interface Action {
    type: string;
    payload: Partial<UserStore>;
}

const initialState: UserStore = {
    vendor: initVendor,
    vendorForm: initVendorForm,
    token: '',
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case ACTIONS.SET_AUTH_DATA: {
            return {
                ...state,
                token: action.payload.token,
                vendor: action.payload.vendor,
            };
        }
        case ACTIONS.SET_VENDOR: {
            return {...state, vendor: action.payload.vendor};
        }
        case ACTIONS.SET_REGISTER_FORM: {
            return {
                ...state,
                vendorForm: {
                    ...state.vendorForm,
                    ...action.payload.vendorForm,
                },
            };
        }
        default:
            return state;
    }
};
