import {ForgotPasswordForm} from 'components/forms/forgot-password-form';

import {Paragraph} from 'components/html/paragraph';

import I18n from 'i18n-js';
import React from 'react';
import {RouteComponentProps} from 'react-router';

import {pathnames} from '../../log-in';

import styles from './reset-password-action.module.scss';

export const ResetPasswordAction: React.FC<RouteComponentProps> = ({history}) => {
    return (
        <div>
            <Paragraph>{I18n.t('form.forgotPassword.description')}</Paragraph>
            <div className={styles.formContainer}>
                <ForgotPasswordForm
                    onSuccess={() => {
                        history.push(pathnames.resetPasswordSuccess);
                    }}
                />
            </div>
        </div>
    );
};
