import Auth from '../../iterfaces/Auth';
import {Vendor, VendorForm} from '../../iterfaces/Vendor';

import * as ACTIONS from './types';

export const setVendor = (vendor: Vendor) => ({
    payload: {vendor},
    type: ACTIONS.SET_VENDOR,
});

export const setAuth = (auth: Auth) => ({
    payload: {...auth},
    type: ACTIONS.SET_AUTH_DATA,
});

export const setVendorForm = (vendorForm: Partial<VendorForm>) => ({
    payload: {vendorForm},
    type: ACTIONS.SET_REGISTER_FORM,
});
