import React from 'react';

import classNames from 'classnames';

import styles from './hamburger.module.scss';

export type HamburgerProps = {
    open?: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    className?: string;
};

export const Hamburger: React.FC<HamburgerProps> = ({
    open = false,
    setOpen,
    className,
}) => {
    return (
        <div
            className={
                open
                    ? classNames(styles.hamburger, styles.hamburger__open, className)
                    : classNames(styles.hamburger, className)
            }
            onClick={() => setOpen(!open)}
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};
