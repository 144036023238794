import classNames from 'classnames';
import {Button, ButtonProps} from 'components/html/button';

import {Table, TableProps} from 'components/html/table';

import join from 'lodash/join';
import React, {isValidElement} from 'react';
import Modal from 'react-modal';

import I18n from 'services/I18n';
import langEn from 'static/lang-en.png';
import langPl from 'static/lang-pl.png';
import {ObjectType} from '../../services/Api';
import styles from './action-data-table.module.scss';
import {
    extractColumnMapHandler,
    extractColumnName,
    extractColumnReadableName,
    extractColumnValue,
    extractColumnWidth,
} from './helpers/extractColumnValue';
import isSurveyTranslated, {TranslationStatus} from './helpers/isSurveyTranslated';
import {Input} from 'components/html/input';
import {deleteContact, updateContact} from 'api/contact.api';
import {Heading} from 'components/html/heading';

const actionData = <T extends ObjectType>(
    actionColumn: ActionDataTableProps<T>['actionColumns'],
    column: ActionDataTableProps<T>['columns'][number],
    value: T[keyof T],
): Action<T, keyof T> | undefined => {
    if (actionColumn && actionColumn[extractColumnName(column)]) {
        return actionColumn[extractColumnName(column)]?.find((innerValue) =>
            innerValue.test(value),
        );
    }
};

export type Action<T, K extends keyof T> = {
    test: (value: T[K]) => boolean;
    button: ButtonProps;
    onClick: (value: T) => void;
};

export type ActionDataTableProps<T> = TableProps & {
    data: T[];
    columns: (
        | keyof T
        | {
              readableName: keyof T;
              value: string;
          }
    )[];
    actions?: Array<{
        button: ButtonProps;
        onClick: (value: T) => void;
        test?: (value: T) => boolean;
    }>;
    languages?: Array<{
        locale: string;
        onClick: (value: T) => void;
    }>;
    readableNames?: {[K in keyof T]?: string};
    actionColumns?: {
        [K in keyof T]?: Action<T, K>[];
    };
    mapHandlers?: {
        [K in keyof T]?: (value: T[K]) => string | JSX.Element | undefined;
    };
    columnWidths?: {
        [K in keyof T]?: string;
    };
    showTableHeader?: boolean;
    isContactTable?: boolean;
    callback?: () => void;
};

const showTooltip = (status: TranslationStatus) => {
    switch (status) {
        case TranslationStatus.NOT_TRANSLATED:
            return 'Not translated';
        case TranslationStatus.PARTIALLY_TRANSLATED:
            return 'Partially translated';
        case TranslationStatus.TRANSLATED:
            return 'Fully translated';
        default:
            return '';
    }
};

export const ActionDataTable = <T extends ObjectType>({
    showTableHeader = true,
    data,
    columns,
    languages,
    actions,
    readableNames,
    actionColumns,
    columnWidths,
    mapHandlers,
    children,
    callback = () => null,
    ...restParam
}: ActionDataTableProps<T>) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [recipientId, setRecipientId] = React.useState<number | undefined>();
    const [formValue, setFormValue] = React.useState<string | undefined>();
    function openModal(id: number) {
        setFormValue(undefined);
        setRecipientId(id);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        switch (formValue) {
            case 'delete':
                if (recipientId) {
                    deleteContact(
                        recipientId,
                        () => {
                            closeModal();
                            callback();
                        },
                        (e) => console.warn(e),
                    );
                }
                break;
            case 'activate':
                if (recipientId) {
                    updateContact(
                        recipientId,
                        {active: true},
                        () => {
                            closeModal();
                            callback();
                        },
                        (e) => console.warn(e),
                    );
                }
                break;
            default:
                null;
        }
    }

    return (
        <>
            {/* <ReactTooltip /> */}
            <Table {...restParam}>
                {showTableHeader ? (
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index}>
                                    {extractColumnReadableName(
                                        column,
                                        readableNames,
                                    )}
                                </th>
                            ))}
                            {actions ? <th>{I18n.t('actions')}</th> : null}
                            {languages ? <th>{I18n.t('languages')}</th> : null}
                        </tr>
                    </thead>
                ) : null}
                <tbody>
                    {data.map((value, index) => (
                        <tr
                            className={classNames({
                                [styles.unsubscribed]:
                                    //eslint-disable-next-line
                                    //@ts-ignore
                                    'active' in value && !value.active,
                            })}
                            key={index}
                            onClick={
                                //eslint-disable-next-line
                                //@ts-ignore
                                'active' in value && !value.active
                                    ? //eslint-disable-next-line
                                      //@ts-ignore
                                      () => openModal(value.id)
                                    : () => null
                            }
                        >
                            {columns.map((column, index) => {
                                const action =
                                    actionColumns &&
                                    actionData<T>(
                                        actionColumns,
                                        column,
                                        extractColumnValue(column, value),
                                    );
                                if (action) {
                                    return (
                                        <td
                                            key={index}
                                            data-column={extractColumnReadableName(
                                                column,
                                                readableNames,
                                            )}
                                            style={{
                                                width: extractColumnWidth(
                                                    column,
                                                    columnWidths,
                                                ),
                                            }}
                                        >
                                            <Button
                                                transitionEffects={false}
                                                variant={'secondary'}
                                                shadow={false}
                                                fixedWidth={false}
                                                {...action.button}
                                                onClick={() => {
                                                    action?.onClick(value);
                                                }}
                                            />
                                        </td>
                                    );
                                } else {
                                    const cellValue = extractColumnValue(
                                        column,
                                        value,
                                    );
                                    const mapHandler = extractColumnMapHandler(
                                        column,
                                        mapHandlers,
                                    );
                                    const cellValue_ = mapHandler
                                        ? mapHandler(cellValue)
                                        : cellValue;
                                    return (
                                        <td
                                            key={index}
                                            data-column={extractColumnReadableName(
                                                column,
                                                readableNames,
                                            )}
                                            style={{
                                                width: extractColumnWidth(
                                                    column,
                                                    columnWidths,
                                                ),
                                            }}
                                            className={classNames(styles.dataCell, {
                                                [styles.dataCellWithElement]:
                                                    isValidElement(cellValue_),
                                            })}
                                        >
                                            {cellValue_}
                                        </td>
                                    );
                                }
                            })}
                            {actions ? (
                                <td data-column={I18n.t('actions')}>
                                    {actions.map(
                                        ({button, onClick, test}, index) => {
                                            if (!test || test(value)) {
                                                return (
                                                    <Button
                                                        transitionEffects={false}
                                                        variant={'secondary'}
                                                        shadow={false}
                                                        fixedWidth={false}
                                                        {...button}
                                                        className={join(
                                                            [
                                                                styles.actionButton,
                                                                button.className
                                                                    ? button.className
                                                                    : '',
                                                            ],
                                                            ' ',
                                                        )}
                                                        onClick={() => {
                                                            onClick(value);
                                                        }}
                                                        key={index}
                                                    />
                                                );
                                            }
                                        },
                                    )}
                                </td>
                            ) : null}
                            {languages ? (
                                <td data-column={I18n.t('languages')}>
                                    <div className={styles.languages}>
                                        {languages.map(
                                            ({locale, onClick}, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => {
                                                        onClick(value);
                                                    }}
                                                >
                                                    {
                                                        <img
                                                            alt={'Flag'}
                                                            data-tip={showTooltip(
                                                                isSurveyTranslated(
                                                                    // Bug in react-minimal-pie-chart typings
                                                                    //eslint-disable-next-line
                                                                    //@ts-ignore
                                                                    value,
                                                                    locale,
                                                                ),
                                                            )}
                                                            className={classNames(
                                                                styles.language,
                                                                {
                                                                    [styles.languageNotTranslated]:
                                                                        isSurveyTranslated(
                                                                            // Bug in react-minimal-pie-chart typings
                                                                            //eslint-disable-next-line
                                                                            //@ts-ignore
                                                                            value,
                                                                            locale,
                                                                        ) ===
                                                                        TranslationStatus.NOT_TRANSLATED,
                                                                    [styles.languagePartiallyTranslated]:
                                                                        isSurveyTranslated(
                                                                            // Bug in react-minimal-pie-chart typings
                                                                            //eslint-disable-next-line
                                                                            //@ts-ignore
                                                                            value,
                                                                            locale,
                                                                        ) ===
                                                                        TranslationStatus.PARTIALLY_TRANSLATED,
                                                                    [styles.languageTranslated]:
                                                                        isSurveyTranslated(
                                                                            // Bug in react-minimal-pie-chart typings
                                                                            //eslint-disable-next-line
                                                                            //@ts-ignore
                                                                            value,
                                                                            locale,
                                                                        ) ===
                                                                        TranslationStatus.TRANSLATED,
                                                                },
                                                            )}
                                                            src={
                                                                locale === 'pl'
                                                                    ? langPl
                                                                    : langEn
                                                            }
                                                        />
                                                    }
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </td>
                            ) : null}
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                className={styles.modal}
            >
                <div className={styles.modalContainer}>
                    <div className={styles.closeButton}>
                        <Heading element={'h4'}>
                            {I18n.t('recipientModal.title')}
                        </Heading>
                        <svg onClick={closeModal}>
                            <g>
                                <path
                                    className="st0"
                                    d="M11,0.2C5.1,0.2,0.2,5.1,0.2,11S5.1,21.8,11,21.8S21.8,16.9,21.8,11S16.9,0.2,11,0.2z M11,20.2 c-5.1,0-9.2-4.1-9.2-9.2S5.9,1.8,11,1.8s9.2,4.1,9.2,9.2S16.1,20.2,11,20.2z"
                                />
                                <polygon
                                    className="st0"
                                    points="15,8.2 13.8,7 11,9.9 8.2,7 7,8.2 9.9,11 7,13.8 8.2,15 11,12.1 13.8,15 15,13.8 12.1,11  "
                                />
                            </g>
                        </svg>
                    </div>
                    <form
                        onSubmit={onFormSubmit}
                        //eslint-disable-next-line
                        //@ts-ignore
                        onChange={(e) => setFormValue(e.target.value)}
                    >
                        <Input
                            type="radio"
                            name="recipient"
                            value="delete"
                            label={I18n.t('recipientModal.delete')}
                        />
                        <Input
                            type="radio"
                            name="recipient"
                            value="activate"
                            label={I18n.t('recipientModal.activate')}
                        />
                        <Button>Ok</Button>
                    </form>
                </div>
            </Modal>
        </>
    );
};
