import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {useVendor} from 'store/user/hooks';

import I18n from 'i18n-js';

import {useAuth} from 'store/user/hooks';

import {Icon} from 'components/icon';
import {Anchor} from 'components/html/anchor';

import classNames from 'classnames';

import styles from './sidebar.module.scss';
import START_ROUTE from '../../containers/dashboard/routes';
import SURVEY_ROUTES from '../../containers/dashboard/routes/surveys/routes';

export type SidebarProps = {
    open?: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    className?: string;
};

export const Sidebar: React.FC<SidebarProps> = ({open, setOpen, className}) => {
    const [, , logOut] = useAuth();
    const [vendor] = useVendor();
    const [firstAccordion, setFirstAccordion] = useState(false);
    const [secondAccordion, setSecondAccordion] = useState(false);
    return (
        <div
            className={
                open
                    ? classNames(styles.sidebar, styles.sidebar__visible, className)
                    : classNames(styles.sidebar, className)
            }
        >
            <div className={classNames(styles.sidebar__container, className)}>
                <div className={classNames(styles.sidebar__menu, className)}>
                    <div
                        className={classNames(styles.sidebar__accordion, className)}
                    >
                        <button
                            className={classNames(styles.voice, className)}
                            onClick={() => {
                                setFirstAccordion(!firstAccordion);
                                setSecondAccordion(false);
                            }}
                        >
                            {I18n.t('dashboard.start.surveysButton')}
                        </button>
                        {firstAccordion && (
                            <div
                                className={classNames(
                                    styles.sidebar__accordion__content,
                                    className,
                                )}
                            >
                                {vendor.roles.includes('ROLE_USER') &&
                                !vendor.roles.includes('ROLE_ADMIN') ? (
                                    <Link
                                        to={START_ROUTE.SURVEYS}
                                        onClick={() => {
                                            setOpen(!open);
                                            setFirstAccordion(!firstAccordion);
                                        }}
                                    >
                                        {I18n.t('dashboard.start.surveysButton')}
                                    </Link>
                                ) : null}
                                {vendor.roles.includes('ROLE_USER') &&
                                !vendor.roles.includes('ROLE_ADMIN') ? (
                                    <Link
                                        to={SURVEY_ROUTES.CREATE}
                                        onClick={() => {
                                            setOpen(!open);
                                            setFirstAccordion(!firstAccordion);
                                        }}
                                    >
                                        {I18n.t('dashboard.start.newSurveyButton')}
                                    </Link>
                                ) : null}
                                {vendor.roles.includes('ROLE_ADMIN') ||
                                vendor.roles.includes('ROLE_USER') ? (
                                    <Link
                                        to={START_ROUTE.TEMPLATES}
                                        onClick={() => {
                                            setOpen(!open);
                                            setFirstAccordion(!firstAccordion);
                                        }}
                                    >
                                        {I18n.t('dashboard.start.templatesButton')}
                                    </Link>
                                ) : null}
                                {vendor.roles.includes('ROLE_USER') &&
                                !vendor.roles.includes('ROLE_ADMIN') ? (
                                    <Link
                                        to={START_ROUTE.RESULTS}
                                        onClick={() => {
                                            setOpen(!open);
                                            setFirstAccordion(!firstAccordion);
                                        }}
                                    >
                                        {I18n.t(
                                            'dashboard.start.tiles.results.title',
                                        )}
                                    </Link>
                                ) : null}
                            </div>
                        )}
                    </div>
                    <div
                        className={classNames(styles.sidebar__accordion, className)}
                    >
                        {vendor.roles.includes('ROLE_USER') &&
                        !vendor.roles.includes('ROLE_ADMIN') ? (
                            <button
                                className={classNames(styles.voice, className)}
                                onClick={() => {
                                    setSecondAccordion(!secondAccordion);
                                    setFirstAccordion(false);
                                }}
                            >
                                {I18n.t('dashboard.start.tiles.recipients.title')}
                            </button>
                        ) : null}
                        {vendor.roles.includes('ROLE_ADMIN') ? (
                            <button
                                className={classNames(styles.voice, className)}
                                onClick={() => {
                                    setSecondAccordion(!secondAccordion);
                                    setFirstAccordion(false);
                                }}
                            >
                                {I18n.t('dashboard.start.tiles.vendors.title')}
                            </button>
                        ) : null}
                        {secondAccordion && (
                            <div
                                className={classNames(
                                    styles.sidebar__accordion__content,
                                    className,
                                )}
                            >
                                {vendor.roles.includes('ROLE_USER') &&
                                !vendor.roles.includes('ROLE_ADMIN') ? (
                                    <Link
                                        to={START_ROUTE.RECIPIENTS}
                                        onClick={() => {
                                            setOpen(!open);
                                            setSecondAccordion(!secondAccordion);
                                        }}
                                    >
                                        {I18n.t(
                                            'dashboard.start.tiles.recipients.title',
                                        )}
                                    </Link>
                                ) : null}
                                {vendor.roles.includes('ROLE_ADMIN') ? (
                                    <Link
                                        to={START_ROUTE.VENDORS}
                                        onClick={() => {
                                            setOpen(!open);
                                            setSecondAccordion(!secondAccordion);
                                        }}
                                    >
                                        {I18n.t(
                                            'dashboard.start.tiles.vendors.title',
                                        )}
                                    </Link>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
                <div className={classNames(styles.sidebar__icons, className)}>
                    <Anchor
                        title={I18n.t('navigation.logOut')}
                        href={'#'}
                        onClick={() => {
                            {
                                if (window.confirm(I18n.t('signOut'))) {
                                    setOpen(!open);
                                    setFirstAccordion(false);
                                    setSecondAccordion(false);
                                    logOut();
                                }
                            }
                        }}
                        className={styles.logOut}
                    >
                        <Icon icon={'logout'} size={'medium'} />
                    </Anchor>
                    <Link
                        to={START_ROUTE.SETTINGS}
                        title={I18n.t('dashboard.start.tiles.settings.title')}
                        className={styles.settings}
                    >
                        <Icon
                            icon={'settings'}
                            size={'medium'}
                            onClick={() => {
                                setOpen(!open);
                                setFirstAccordion(false);
                                setSecondAccordion(false);
                            }}
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};
