import React, {ButtonHTMLAttributes} from 'react';

import classNames from 'classnames';

import {Icon, IconProps} from 'components/icon';

import styles from './button.module.scss';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    align?: 'left' | 'center';
    variant?: 'primary' | 'secondary' | 'warning';
    size?: 'small' | 'big';
    transitionEffects?: boolean;
    fixedWidth?: boolean;
    shadow?: boolean;
    icon?: IconProps;
    transparent?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
    variant = 'primary',
    size = 'small',
    transitionEffects = true,
    fixedWidth = true,
    shadow = true,
    transparent = false,
    icon,
    className,
    children,
    ...restParams
}) => {
    return (
        <button
            className={classNames(
                styles.button,
                styles[variant],
                styles[size],
                {
                    [styles.fixedWidth]: fixedWidth,
                    [styles.shadow]: shadow,
                    [styles.withIcon]: icon,
                    [styles.transitionEffects]: transitionEffects,
                    [styles.transparent]: transparent,
                },
                className,
            )}
            {...restParams}
        >
            {icon ? <Icon {...icon} className={styles.icon} /> : null}
            {children}
        </button>
    );
};
