import React, {HTMLAttributes} from 'react';

import I18n from 'services/I18n';

import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {Button} from 'components/html/button';
import {useModal} from 'components/modal';

import styles from './confirmation-dialog.module.scss';

export type ConfirmationDialogProps = HTMLAttributes<HTMLDivElement> & {
    onConfirm: () => void;
    message?: string;
    title?: string;
    displayCancelButton?: boolean;
    confirmButtonLabel?: string;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    title = I18n.t('modal.confirmationDialog.title'),
    displayCancelButton = true,
    confirmButtonLabel = I18n.t('modal.confirmationDialog.confirmButton'),
    onConfirm,
    message,
    ...restParam
}) => {
    const [, detachModal] = useModal();

    return (
        <div {...restParam}>
            <Heading element={'h3'}>{title}</Heading>
            {message ? (
                <Paragraph className={styles.message}>{message}</Paragraph>
            ) : null}
            <div className={styles.controls}>
                <Button
                    onClick={() => {
                        onConfirm();
                        detachModal();
                    }}
                    title={confirmButtonLabel}
                    className={styles.controlsButton}
                >
                    {confirmButtonLabel}
                </Button>
                {displayCancelButton ? (
                    <Button
                        onClick={() => {
                            detachModal();
                        }}
                        title={I18n.t('modal.confirmationDialog.cancelButton')}
                        className={styles.controlsButton}
                    >
                        {I18n.t('modal.confirmationDialog.cancelButton')}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
