import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import I18n from 'services/I18n';

import styles from './header.module.scss';
import DASHBOARD_ROUTES from '../../containers/dashboard/routes';

export type HeaderProps = {
    logo: string;
    className?: string;
    children?: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = ({logo, className, children}) => {
    return (
        <div className={classNames(styles.header, className)}>
            <div className={classNames(styles.header__container, className)}>
                {children}
                <Link to={DASHBOARD_ROUTES.START}>
                    <img
                        src={logo}
                        className={styles.logo}
                        alt={I18n.t('app.title')}
                    />
                </Link>
            </div>
        </div>
    );
};
