import {Contact} from 'iterfaces/contact';
import {GivenAnswer} from 'iterfaces/given-answer';

export type ContactWithAnsweredSurvey = Contact & {
    answeredSurvey: boolean;
};

export const surveyRecipientsState = (
    contacts: Contact[],
    givenAnswers: GivenAnswer[],
): ContactWithAnsweredSurvey[] => {
    const contactsAnswered: Map<number, boolean> = new Map(
        contacts.map((contact) => [contact.id, false]),
    );
    givenAnswers.forEach((givenAnswer) => {
        const contactId = givenAnswer.recipient.contact?.id;
        if (contactId && contactsAnswered.has(contactId)) {
            contactsAnswered.set(contactId, true);
        }
    });
    return contacts.map((contact) => ({
        ...contact,
        answeredSurvey: contactsAnswered.get(contact.id) || false,
    }));
};
