import {readSurvey} from 'api/survey.api';
import {Survey} from 'iterfaces/survey';
import {useState} from 'react';

export default (): [Survey | undefined, (id: number) => void] => {
    const [template, setTemplate] = useState<Survey>();

    const fetchTemplate = (id: number) => {
        readSurvey(
            id,
            ({data}) => {
                setTemplate(data);
            },
            () => {
                setTemplate(undefined);
            },
        );
    };

    return [template, fetchTemplate];
};
