import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {get} from 'lodash';

import {setApiAuthorization} from '../api/authorization.api';
import {removeAuth} from '../store/user/auth';

import {REACT_APP_API_URL} from './Config';

export interface AxiosHeaders {
    [key: string]: string;
}

export interface AxiosErrorData {
    code: string;
    title: string;
    message: string;
    violations: Violations[];
}

export interface Violations {
    propertyPath: string;
    message: string;
}

export interface ErrorResponse {
    title?: string;
    message?: string;
    details?: string;
    detail?: string;
    violations?: Violations[];
}

export type ObjectType = Record<string, unknown> | unknown[] | unknown;
export type ResponseCallback<T extends ObjectType = ObjectType> = (
    response: AxiosResponse<T>,
) => void;
export type ErrorCallback = (errors: AxiosErrorData) => void;

interface AxiosConfig<R> extends Partial<AxiosRequestConfig> {
    onError: (error: AxiosErrorData) => void;
    onSuccess: (message: AxiosResponse<R>) => void;
    headers?: AxiosHeaders;
}

export const axiosInstance = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

const errorNormalizer = (error: AxiosError<ErrorResponse>): AxiosErrorData => {
    const errorValue = <T>(key: keyof ErrorResponse, defaultValue: T): T => {
        return get(error, ['response', 'data', key], defaultValue) as T;
    };

    return {
        code: error.code || (error.response ? `${error.response.status}` : '500'),
        title: errorValue('title', 'Error'),
        message: errorValue(
            'message',
            errorValue('details', errorValue('detail', 'Error')),
        ),
        violations: errorValue<Violations[]>('violations', [] as Violations[]),
    };
};

const errorConfig = async (
    error: AxiosError<AxiosErrorData>,
    callback: (error: AxiosErrorData) => void,
) => {
    error.response ? callback(errorNormalizer(error)) : console.warn(error);
};

const logoutPage = (url: string) => {
    if (!/authentication_token/.test(url)) {
        setApiAuthorization();
        removeAuth();
        window.location.reload();
    }
};

axiosInstance.interceptors.response.use(
    undefined,
    (error: AxiosError<AxiosErrorData>) => {
        if (error.response && error.response.status === 401) {
            logoutPage(error.response.config.url || '');
        }
        return Promise.reject(error);
    },
);

export default {
    get: <R>(url: string, {onSuccess, onError, ...config}: AxiosConfig<R>) =>
        axiosInstance
            .get(url, config)
            .then(onSuccess)
            .catch((error) => errorConfig(error, onError)),

    post: <T, R>(
        url: string,
        data: T,
        {onSuccess, onError, ...config}: AxiosConfig<R>,
    ) =>
        axiosInstance
            .post(url, data, config)
            .then(onSuccess)
            .catch((error) => errorConfig(error, onError)),

    put: <T, R>(
        url: string,
        data: T,
        {onSuccess, onError, ...config}: AxiosConfig<R>,
    ) =>
        axiosInstance
            .put(url, data, config)
            .then(onSuccess)
            .catch((error) => errorConfig(error, onError)),

    delete: <R>(url: string, {onSuccess, onError, ...config}: AxiosConfig<R>) =>
        axiosInstance
            .delete(url, config)
            .then(onSuccess)
            .catch((error) => errorConfig(error, onError)),
};
