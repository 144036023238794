import Api, {ErrorCallback, ResponseCallback} from 'services/Api';
import {trackPromise} from 'react-promise-tracker';

import {
    Contact,
    CreateContactRequest,
    CreateContactResponse,
} from 'iterfaces/contact';

export const createContact = (
    data: CreateContactRequest,
    onSuccess: ResponseCallback<CreateContactResponse>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.post<CreateContactRequest, CreateContactResponse>('contacts', data, {
            onSuccess,
            onError,
        }),
    );
};

export const readContacts = (
    onSuccess: ResponseCallback<Contact[]>,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.get<Contact[]>('contacts?page=1&itemsPerPage=1000', {
            onSuccess,
            onError,
        }),
    );
};

export const deleteContact = (
    id: Contact['id'],
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.delete(`contacts/${id}`, {
            onSuccess,
            onError,
        }),
    );
};

export const updateContact = (
    id: Contact['id'],
    data: Partial<Contact>,
    onSuccess: ResponseCallback,
    onError: ErrorCallback,
) => {
    trackPromise(
        Api.put(`contacts/${id}`, data, {
            onSuccess,
            onError,
        }),
    );
};
