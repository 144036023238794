import Auth, {initAuth} from '../../iterfaces/Auth';
import Store from '../../services/Store';

const AUTH_KEY = 'auth';

export const getAuth = () => {
    return Store.getItem<Auth>(AUTH_KEY) || initAuth;
};

export const setAuth = (auth: Partial<Auth>) => {
    Store.setItem(AUTH_KEY, {...getAuth(), ...auth});
};

export const removeAuth = () => {
    Store.setItem(AUTH_KEY, initAuth);
};
