import {createContact, deleteContact} from 'api/contact.api';
import {ActionDataTable} from 'components/action-data-table';
import {ErrorMessage} from 'components/error-message';
import {CreateContactForm} from 'components/forms/create-contact-form';
import {Anchor} from 'components/html/anchor';
import {Button} from 'components/html/button';
import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {Icon} from 'components/icon';

import {Modal, useModal} from 'components/modal';

import {Contact} from 'iterfaces/contact';
import React, {useEffect, useState} from 'react';
import {trackPromise} from 'react-promise-tracker';
import {RouteComponentProps} from 'react-router-dom';

import I18n from 'services/I18n';

import {useContactGroupsSubscription} from '../../hooks';

import RECIPIENTS_ROUTES from '../../routes';

import styles from './edit-contacts.module.scss';

import {ExportDialog} from './export-dialog';
import {ImportButton} from './import-button';

export const EXPORT_HEADERS = ['name', 'email'];

export type ExportContact = Pick<Contact, 'name' | 'email'>;

export type EditContactsProps = React.FC<RouteComponentProps<{id: string}>>;

export const EditContacts: EditContactsProps = ({history, match}) => {
    const [importedContacts, setImportedContacts] = useState<ExportContact[]>([]);
    const [contactGroups, updateContactGroups] = useContactGroupsSubscription();
    const [appendModal] = useModal();

    const contactGroup = contactGroups.find(
        (value) => value.id === parseInt(match.params.id),
    );

    const fetchData = () => {
        if (contactGroup && importedContacts.length) {
            trackPromise(
                Promise.all(
                    contactGroup.contacts.map(
                        (contact) =>
                            new Promise((resolve, reject) => {
                                if (contactGroup.contacts.length) {
                                    deleteContact(
                                        contact.id,
                                        () => resolve(undefined),
                                        () => reject(undefined),
                                    );
                                } else {
                                    resolve(undefined);
                                }
                            }),
                    ),
                )
                    .then(() =>
                        Promise.all(
                            importedContacts.map(
                                (contact) =>
                                    new Promise((resolve, reject) => {
                                        createContact(
                                            {
                                                name: contact.name,
                                                email: contact.email,
                                                contactGroup: contactGroup.id,
                                            },
                                            () => resolve(undefined),
                                            () => reject(undefined),
                                        );
                                    }),
                            ),
                        ),
                    )
                    .then(() => {
                        updateContactGroups();
                    }),
            );
        }
    };

    useEffect(() => {
        fetchData();
    }, [importedContacts]);

    if (contactGroup) {
        return (
            <Modal size={'full'} className={styles.modal}>
                <Heading element={'h4'}>
                    {I18n.t('dashboard.contacts.editContactGroupContacts.title')}
                </Heading>
                <Paragraph
                    fontSize={'small'}
                    muted={true}
                    className={styles.description}
                >
                    {I18n.t(
                        'dashboard.contacts.editContactGroupContacts.description',
                    )}
                </Paragraph>
                <Heading element={'h5'} className={styles.contactGroupHeading}>
                    {contactGroup.name}
                </Heading>
                <Anchor
                    onClick={() => {
                        history.push(RECIPIENTS_ROUTES.START);
                    }}
                    title={I18n.t('navigation.return')}
                    transitions={false}
                    className={styles.returnButton}
                >
                    <Icon icon={'cross'} size={'medium'} />
                </Anchor>
                <div className={styles.forms}>
                    <CreateContactForm
                        onSuccess={() => {
                            updateContactGroups();
                        }}
                        contactGroup={contactGroup.id}
                    />
                    <ActionDataTable<Contact>
                        data={contactGroup.contacts}
                        columns={['name', 'email']}
                        isContactTable
                        callback={() => updateContactGroups()}
                        actions={[
                            {
                                button: {
                                    icon: {
                                        icon: 'minus',
                                    },
                                    title: I18n.t(
                                        'dashboard.contacts.editContactGroupContacts.deleteButton',
                                    ),
                                    children: I18n.t(
                                        'dashboard.contacts.editContactGroupContacts.deleteButton',
                                    ),
                                },
                                onClick: ({id}) => {
                                    deleteContact(
                                        id,
                                        () => {
                                            updateContactGroups();
                                        },
                                        ({code}) => {
                                            appendModal({
                                                children: (
                                                    <ErrorMessage error={code} />
                                                ),
                                            });
                                        },
                                    );
                                },
                            },
                        ]}
                        columnWidths={{
                            name: '48%',
                            email: '50%',
                        }}
                        showTableHeader={false}
                        className={styles.table}
                    />
                </div>
                {/* if contacts in this recipient are less than 5, show warning */}
                {contactGroup.contacts.length < 5 && (
                    <div className={styles.warning}>
                        {I18n.t(
                            'dashboard.contacts.editContactGroupContacts.warning',
                        )}
                    </div>
                )}
                <div className={styles.controls}>
                    <Button
                        className={styles.control}
                        disabled={!contactGroup.contacts.length}
                        title={I18n.t(
                            'dashboard.contacts.editContactGroupContacts.exportButton.description',
                        )}
                        onClick={() => {
                            appendModal({
                                children: (
                                    <ExportDialog contacts={contactGroup.contacts} />
                                ),
                                hasCloseIcon: true,
                            });
                        }}
                    >
                        {I18n.t(
                            'dashboard.contacts.editContactGroupContacts.exportButton.label',
                        )}
                    </Button>
                    <ImportButton
                        onContactsUpload={(contacts) => {
                            setImportedContacts(contacts);
                        }}
                        className={styles.control}
                    />
                </div>
                <Paragraph fontSize={'small'} className={styles.formatHint}>
                    {I18n.t(
                        'dashboard.contacts.editContactGroupContacts.fileStructureHint',
                    )}
                </Paragraph>
            </Modal>
        );
    } else {
        history.push(RECIPIENTS_ROUTES.START);
        return null;
    }
};
