import {mapValues} from 'lodash';
import I18n from 'services/I18n';
import * as yup from 'yup';

export const surveyNameSchema = yup
    .string()
    .max(200, I18n.t('validation.survey.title.tooLong'));

export const descriptionSchema = yup
    .string()
    .max(500, I18n.t('validation.survey.description.tooLong'));

export const logoSchema = yup.object().shape({
    id: yup.number(),
    contentUrl: yup.string(),
});

export const sectionNameSchema = yup
    .string()
    .max(100, I18n.t('validation.survey.section.tooLong'));

export const questionNameSchema = yup
    .string()
    .max(500, I18n.t('validation.survey.question.question.tooLong'));

export const questionTypeSchema = yup
    .string()
    .oneOf(['multipleChoice', 'singleChoice']);

export const answerNameSchema = yup
    .string()
    .max(200, I18n.t('validation.survey.answer.tooLong'));

export const translationsSurveySchema = yup.lazy((obj) =>
    yup.object(
        //eslint-disable-next-line
        //@ts-ignore
        mapValues(obj, () =>
            yup.object({
                name: yup.string(),
                description: yup.string(),
            }),
        ),
    ),
);

export const translationsSchema = yup.lazy((obj) =>
    yup.object(
        //eslint-disable-next-line
        //@ts-ignore
        mapValues(obj, () =>
            yup.object({
                name: yup.string(),
                description: yup.string(),
            }),
        ),
    ),
);

export const createSurveyTemplateFormSchema = yup.object().shape({
    id: yup.number().required(),
    name: surveyNameSchema.required(I18n.t('validation.survey.name.missing')),
    description: descriptionSchema.required(
        I18n.t('validation.survey.description.missing'),
    ),
    translations: translationsSurveySchema,
    sections: yup
        .array()
        .of(
            yup.object().shape({
                id: yup.number().required(),
                name: sectionNameSchema.required(
                    I18n.t('validation.survey.section.name.missing'),
                ),
                translations: translationsSchema,
                questions: yup.array().of(
                    yup.object().shape({
                        id: yup.number().required(),
                        name: questionNameSchema.required(
                            I18n.t('validation.survey.question.name.empty'),
                        ),
                        translations: translationsSchema,
                        type: questionTypeSchema.required(
                            I18n.t('validation.survey.question.type.missing'),
                        ),
                        answers: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    id: yup.number().required(),
                                    name: answerNameSchema.required(
                                        I18n.t('validation.survey.answer.empty'),
                                    ),
                                    translations: translationsSchema,
                                }),
                            )
                            .min(
                                1,
                                I18n.t('validation.survey.answer.missingAtLeastOne'),
                            ),
                    }),
                ),
            }),
        )
        .min(1, I18n.t('validation.survey.section.missingAtLeastOne')),
});

export const createSurveyFormSchema = createSurveyTemplateFormSchema.concat(
    yup.object().shape({
        logo: logoSchema,
    }),
);

export const answersSurveySchema = yup.object().shape({
    selectedAnswers: yup.array().of(
        yup.object().shape({
            answerId: yup
                .string()
                .required(I18n.t('validation.survey.answer.missingAtLeastOne')),
        }),
    ),
});
