import {Survey} from 'iterfaces/survey';

const areKeysTranslated = (translation: Record<string, string>, keys: string[]) => {
    return keys.every((key) => {
        return !!translation[key] || translation[key].trim() !== '';
    });
};

export enum TranslationStatus {
    'NOT_TRANSLATED' = 2,
    'TRANSLATED' = 1,
    'PARTIALLY_TRANSLATED' = 3,
}

const isSurveyTranslated = (survey: Survey, locale: string) => {
    const isTranslated = [];

    isTranslated.push(
        !!survey.translations[locale] &&
            areKeysTranslated(survey.translations[locale], ['name']),
    );
    isTranslated.push(
        !!survey.translations[locale] &&
            areKeysTranslated(survey.translations[locale], ['description']),
    );
    survey.sections.forEach((section) => {
        isTranslated.push(
            !!section.translations[locale] &&
                areKeysTranslated(section.translations[locale], ['name']),
        );
        section.questions.forEach((question) => {
            isTranslated.push(
                !!question.translations[locale] &&
                    areKeysTranslated(question.translations[locale], ['name']),
            );
            question.answers.forEach((answer) => {
                isTranslated.push(
                    !!answer.translations[locale] &&
                        areKeysTranslated(answer.translations[locale], ['name']),
                );
            });
        });
    });

    if (isTranslated.every((translated) => translated)) {
        return TranslationStatus.TRANSLATED;
    } else {
        if (isTranslated.some((translated) => translated)) {
            return TranslationStatus.PARTIALLY_TRANSLATED;
        }
        return TranslationStatus.NOT_TRANSLATED;
    }
};

export default isSurveyTranslated;
