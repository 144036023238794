import React, {HTMLAttributes, useState} from 'react';
import classNames from 'classnames';

import {Header, HeaderProps} from 'components/header';
//eslint-disable-next-line
import {Sidebar, SidebarProps} from 'components/sidebar';
import {Hamburger} from 'components/html/hamburger';

import {useUserIsLoggedIn} from 'store/user/hooks';

import styles from './page-wrapper.module.scss';

export type PageWrapperProps = HTMLAttributes<HTMLElement> & {
    header: HeaderProps;
};

export const PageWrapper: React.FC<PageWrapperProps> = ({
    header,
    className,
    children,
}) => {
    const isLoggedIn = useUserIsLoggedIn();
    const [open, setOpen] = useState(false);
    return (
        <div className={classNames(styles.pageWrapper, className)}>
            <Header {...header} className={styles.header}>
                {isLoggedIn && <Hamburger open={open} setOpen={setOpen} />}
            </Header>
            {isLoggedIn && (
                <Sidebar open={open} setOpen={setOpen} className={styles.sidebar} />
            )}
            <div className={styles.contentContainer}>{children}</div>
        </div>
    );
};
