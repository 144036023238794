import {readGivenAnswers} from 'api/given-answer';
import {ActionDataTable} from 'components/action-data-table';
import {ErrorMessage} from 'components/error-message';
//eslint-disable-next-line
import {Button} from 'components/html/button';
import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {Icon} from 'components/icon';
import {Modal, useModal} from 'components/modal';
import {QuestionResults} from 'components/question-results';
import {useContactGroupsSubscription} from 'containers/dashboard/routes/recipients';
import useTemplate from 'containers/dashboard/routes/templates/hooks/useTemplate';
import {GivenAnswer} from 'iterfaces/given-answer';
import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import I18n from 'services/I18n';
import {calculateQuestionResults} from 'utils/calculate-question-results';
import {mapGivenAnswersToAnswersId} from 'utils/map-given-answers-to-answers-ids';
import {mapQuestionResultsToAnswersWithResults} from 'utils/map-question-results-to-answers-with-results';
import {
    ContactWithAnsweredSurvey,
    surveyRecipientsState,
} from 'utils/survey-recipients-state';
import RESULTS_ROUTES from '../../../results/routes';
import styles from './view-results.module.scss';
import {useMemo} from 'react';

const useGivenAnswersSubscription = (id: GivenAnswer['id']): GivenAnswer[] => {
    const [givenAnswers, setGivenAnswers] = useState<GivenAnswer[]>([]);
    const [showModal] = useModal();

    const updateValues = (id: GivenAnswer['id']) => {
        readGivenAnswers(
            id,
            ({data}) => {
                setGivenAnswers(data);
            },
            ({code}) => {
                showModal({
                    children: <ErrorMessage error={code} />,
                });
            },
        );
    };

    useEffect(() => {
        updateValues(id);
    }, []);

    return givenAnswers;
};

export const ViewResults: React.FC<
    RouteComponentProps<{
        surveyId: string;
    }>
> = ({history, match}) => {
    /* Getting all recipients in database */
    const [contactGroups] = useContactGroupsSubscription();
    const surveyId = parseInt(match.params.surveyId);
    const [survey, setSurvey] = useTemplate();
    useEffect(() => {
        setSurvey(surveyId);
    }, []);

    /* Create new state to save all contact groups related to current survey */
    //eslint-disable-next-line
    const [contactGroup, setContactGroup] = useState<any>([]);
    /* Fire useEffect to update the state only when survey and contactGroups is updated */
    useEffect(() => {
        setContactGroup(
            contactGroups.filter((contactGroup) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                survey?.contactGroups?.find((scg) => scg.id === contactGroup.id),
            ),
        );
    }, [survey, contactGroups]);

    /* Create new state to save all contacts related to current survey */
    //eslint-disable-next-line
    const [allContacts, setAllContacts] = useState<any>([]);
    /* Fire useEffect to update the state only when survey and contactGroup is updated */
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (contactGroup.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const allContacts_temp = []; //creates the clone of the state
            //eslint-disable-next-line
            contactGroup.map((contactGroup: any) => {
                //eslint-disable-next-line
                contactGroup.contacts.map((contact: any) => {
                    allContacts_temp.push(contact);
                });
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setAllContacts(allContacts_temp);
        }
    }, [contactGroup]);

    const givenAnswers = useGivenAnswersSubscription(surveyId);
    const givenAnswersIds = mapGivenAnswersToAnswersId(givenAnswers);
    //eslint-disable-next-line
    const [isPercentageExpanded, setIsPercentageExpanded] = useState(false);

    const surveyState =
        contactGroup.length > 0
            ? surveyRecipientsState(allContacts, givenAnswers)
            : undefined;

    const surveyPercentage = useMemo(() => {
        if (survey) {
            const allRecipients = survey.recipients.length;

            const numberOfAnswers = survey.recipients.filter(
                (recipient) => recipient.givenAnswers.length >= 1,
            ).length;

            return Math.round((100 * numberOfAnswers) / allRecipients);
        }
        return 0;
    }, [survey]);

    return givenAnswersIds && survey && surveyState ? (
        <Modal
            size={'full'}
            hasCloseIcon={true}
            closeIconClickHandler={() => {
                history.push(RESULTS_ROUTES.START);
            }}
        >
            <Heading element={'h4'}>
                {I18n.t('dashboard.results.view.title')}
            </Heading>
            <Paragraph fontSize={'small'} className={styles.description}>
                {I18n.t('dashboard.results.view.description')}
            </Paragraph>
            <Heading element={'h2'} className={styles.surveyHeading}>
                {survey.translations.is.name}
            </Heading>
            <span className={styles.statistics}>
                <div className={styles.statisticsRow}>
                    <span className={styles.statisticsNumber}>
                        {surveyPercentage}%
                    </span>
                    <span className={styles.statisticsLabel}>
                        {I18n.t(
                            'dashboard.results.view.statistics.answeredSurveysPercentageLabel',
                        )}
                    </span>
                    {/* <Button
                        variant={'secondary'}
                        transitionEffects={false}
                        icon={{
                            icon: isPercentageExpanded ? 'minus' : 'plus',
                        }}
                        shadow={false}
                        fixedWidth={false}
                        className={styles.statisticsButton}
                        type={'submit'}
                        title={
                            isPercentageExpanded
                                ? I18n.t(
                                      'dashboard.results.view.statistics.hideDetails',
                                  )
                                : I18n.t(
                                      'dashboard.results.view.statistics.showDetails',
                                  )
                        }
                        onClick={() => {
                            setIsPercentageExpanded(!isPercentageExpanded);
                        }}
                    >
                        {isPercentageExpanded
                            ? I18n.t('dashboard.results.view.statistics.hideDetails')
                            : I18n.t(
                                  'dashboard.results.view.statistics.showDetails',
                              )}
                    </Button> */}
                </div>
                {isPercentageExpanded ? (
                    <div>
                        <ActionDataTable<ContactWithAnsweredSurvey>
                            columns={['name', 'email', 'answeredSurvey']}
                            data={surveyState}
                            readableNames={{
                                name: I18n.t('contact.name'),
                                email: I18n.t('contact.email'),
                                answeredSurvey: I18n.t(
                                    'dashboard.results.view.statistics.answeredLabel',
                                ),
                            }}
                            mapHandlers={{
                                answeredSurvey: (value) => {
                                    if (value) {
                                        return <Icon icon={'check'} />;
                                    }
                                    return <Icon icon={'cross'} variant={'ghost'} />;
                                },
                            }}
                            className={styles.statisticsTable}
                        />
                    </div>
                ) : null}
            </span>
            {survey.sections.map((section, index) => (
                <div className={styles.section} key={index}>
                    <Heading element={'h5'} className={styles.sectionHeading}>
                        {section.translations.is.name}
                    </Heading>
                    <div className={styles.questions}>
                        {section.questions.map((question, index) => {
                            return (
                                <QuestionResults
                                    name={get(question, 'translations.is.name', '')}
                                    answers={mapQuestionResultsToAnswersWithResults(
                                        calculateQuestionResults(
                                            question,
                                            givenAnswersIds,
                                        ),
                                        question.answers,
                                    )}
                                    className={styles.questionResults}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </div>
            ))}
        </Modal>
    ) : null;
};
