import join from 'lodash/join';
import React, {InputHTMLAttributes, useRef, useState} from 'react';
import styles from './input.module.scss';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    help?: string;
    error?: string;
    fixedWidth?: boolean;
};

export const Input: React.FC<InputProps> = ({
    fixedWidth = true,
    label,
    help,
    error,
    type,
    className,
    onFocus,
    onBlur,
    ...restParam
}) => {
    const [isFocus, setIsFocus] = useState(false);
    const isPlaceholder =
        restParam.placeholder && restParam.placeholder.trim().length > 0;
    const ref = useRef<HTMLInputElement>(null);

    return (
        <label
            className={join(
                [
                    styles.container,
                    fixedWidth ? styles.fixedWidth : '',
                    type === 'radio' ? styles.radio : '',
                    type === 'checkbox' ? styles.checkbox : '',
                    restParam.disabled ? styles.disabled : '',
                    styles.contactGroup && restParam.disabled
                        ? styles.contactGroup
                        : '',
                    className ? className : '',
                ],
                ' ',
            )}
        >
            <input
                {...restParam}
                ref={ref}
                type={type}
                onClick={(event) => {
                    ref?.current?.focus();
                    if (restParam.onClick) {
                        restParam.onClick(event);
                    }
                }}
                onFocus={(event) => {
                    setIsFocus(true);
                    if (onFocus) {
                        onFocus(event);
                    }
                }}
                onBlur={(event) => {
                    setIsFocus(false);
                    if (onBlur) {
                        onBlur(event);
                    }
                }}
                className={join(
                    [styles.input, type === 'radio' ? styles.radio : ''],
                    ' ',
                )}
            />
            {type === 'radio' ? <span className={styles.bullet} /> : null}
            {type === 'checkbox' ? <span className={styles.checkmark} /> : null}
            <div
                className={join(
                    [
                        styles.label,
                        restParam.value || isFocus || isPlaceholder
                            ? styles.labelUp
                            : '',
                        error ? styles.labelError : '',
                        type === 'radio' || type === 'checkbox'
                            ? styles.labelUp
                            : '',
                        type === 'radio' ? styles.radio : '',
                        type === 'checkbox' ? styles.labelUp : '',
                        type === 'text' ? styles.labelLowercase : '',
                    ],
                    ' ',
                )}
            >
                {label}
            </div>
            <div
                className={join([styles.annotation, error ? styles.error : ''], ' ')}
            >
                {error || help}
            </div>
        </label>
    );
};
