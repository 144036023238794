import React, {HTMLAttributes} from 'react';

import I18n from 'i18n-js';

import join from 'lodash/join';

import {useModal} from 'components/modal';

import {Heading} from 'components/html/heading';
import {Paragraph} from 'components/html/paragraph';
import {Button} from 'components/html/button';

import styles from './error-message.module.scss';

export type ErrorMessageProps = HTMLAttributes<HTMLDivElement> & {
    error: string;
    handler?: () => void;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
    error,
    handler,
    className,
}) => {
    const [, hideModal] = useModal();

    return (
        <div className={join([styles.errorMessage, className], ' ')}>
            <Heading element={'h3'}>{I18n.t('exception.modal.title')}</Heading>
            <Paragraph>
                {I18n.t('exception.modal.content')}: {error}.
            </Paragraph>
            <Button
                onClick={() => {
                    hideModal();
                    if (typeof handler === 'function') {
                        handler();
                    }
                }}
                title={I18n.t('navigation.close')}
                className={styles.button}
            >
                {I18n.t('navigation.close')}
            </Button>
        </div>
    );
};
