import {requestResettingPassword} from 'api/vendor.api';
import {Button} from 'components/html/button';

import {Input} from 'components/html/input';

import {Field, Form, Formik, FormikProps} from 'formik';
import React from 'react';

import I18n from 'services/I18n';
import {forgotPasswordSchema} from 'validators/vendor';
import {InferType} from 'yup';

import styles from './forgot-password-form.module.scss';

export type ForgotPasswordFormValues = Pick<
    InferType<typeof forgotPasswordSchema>,
    'email'
>;

export type ForgotPasswordFormProps = {
    onSuccess: (email: string) => void;
};

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
    onSuccess,
}) => (
    <Formik
        validationSchema={forgotPasswordSchema}
        initialValues={{
            email: '',
        }}
        onSubmit={(values, {setErrors, setSubmitting}) => {
            requestResettingPassword(
                {
                    email: values.email,
                },
                () => {
                    onSuccess(values.email);
                },
                (error) => {
                    if (error.code === '404') {
                        setErrors({
                            email: I18n.t('validation.email.notFound'),
                        });
                    } else {
                        console.error(error);
                    }
                    setSubmitting(false);
                },
            );
        }}
    >
        {({errors, isSubmitting}: FormikProps<ForgotPasswordFormValues>) => (
            <Form>
                <Field
                    label={I18n.t('vendor.email')}
                    name={'email'}
                    error={errors.email}
                    as={Input}
                />
                <Button
                    type={'submit'}
                    disabled={isSubmitting}
                    className={styles.submit}
                >
                    {I18n.t('form.submit')}
                </Button>
            </Form>
        )}
    </Formik>
);
