import {ContactGroup} from 'iterfaces/contact-group';

import * as ACTIONS from './types';

export type ContactGroupsStore = {
    contactGroups: ContactGroup[];
};

type Action = {
    type: string;
    payload: Partial<ContactGroupsStore>;
};

const initialState: ContactGroupsStore = {
    contactGroups: [],
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case ACTIONS.SET_CONTACT_GROUPS: {
            return {...state, contactGroups: action.payload.contactGroups};
        }
        default:
            return state;
    }
};
