import React, {ChangeEvent, useEffect, useRef, InputHTMLAttributes} from 'react';
import classNames from 'classnames';

import I18n from 'services/I18n';
import {MediaObject} from 'iterfaces/media-object';
import {useUploadFile} from 'hooks/use-upload-file';
import {Button} from 'components/html/button';

import styles from './image-upload-input.module.scss';

type ImageUploadInputProps = InputHTMLAttributes<HTMLInputElement> & {
    mediaObject: MediaObject;
    label?: string;
    help?: string;
    error?: string;
    allowChange?: boolean;
    onMediaObjectChange?: (value: MediaObject) => void;
};

export const ImageUploadInput: React.FC<ImageUploadInputProps> = ({
    allowChange = true,
    mediaObject,
    onMediaObjectChange,
    label,
    help,
    error,
    className,
    ...restParam
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [image, imageError, uploadFile] = useUploadFile();

    const error_ = error || imageError;

    const fileSelectedHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files && event.target.files[0]) {
            uploadFile(event.target.files[0]);
        }
    };

    useEffect(() => {
        if (image.id) {
            if (onMediaObjectChange) {
                onMediaObjectChange(image);
            }
        }
    }, [image.id]);

    const imageUrl = mediaObject.contentUrl || image.contentUrl || '';
    return (
        <label
            className={classNames(styles.container, className)}
            onClick={(event) => {
                if (!allowChange) {
                    event.preventDefault();
                }
            }}
        >
            {help ? help : null}
            {error_ ? error_ : null}
            <input type={'hidden'} {...restParam} />
            <input
                ref={inputRef}
                type={'file'}
                accept={'image/*'}
                onChange={(event) => {
                    fileSelectedHandler(event);
                }}
                className={classNames(styles.fileInput, {
                    [styles.fileInputDisabled]: !allowChange,
                })}
            />
            <div
                className={classNames(styles.wrapper, {
                    [styles.wrapperWithNoImage]: !imageUrl,
                })}
            >
                {!imageUrl && imageUrl === '' && label ? label : null}
                {!imageUrl && imageUrl === '' ? null : (
                    <img src={imageUrl} className={styles.image} alt={''} />
                )}
            </div>
            {allowChange ? (
                <Button
                    transitionEffects={false}
                    fixedWidth={false}
                    variant={'secondary'}
                    icon={{
                        icon: 'pen',
                    }}
                    shadow={false}
                    className={styles.button}
                    title={'change'}
                    type={'button'}
                    onClick={(e) => {
                        e.currentTarget.click();
                    }}
                >
                    {I18n.t('form.imageUploadInput.changeButton')}
                </Button>
            ) : null}
        </label>
    );
};
