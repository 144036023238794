import {UserLoginResponse} from 'api/authorization.api';
import {ErrorMessage} from 'components/error-message';
import {Anchor} from 'components/html/anchor';

import {Button} from 'components/html/button';
import {Input} from 'components/html/input';
import {useModal} from 'components/modal';

import {Field, Form, Formik, FormikProps} from 'formik';
import {FormProps} from 'iterfaces/form';
import React from 'react';

import {AxiosErrorData} from 'services/Api';

import I18n from 'services/I18n';
import {logInSchema} from 'validators/vendor';
import {InferType} from 'yup';

import styles from './log-in-form.module.scss';

export type LoginFormValues = Pick<
    InferType<typeof logInSchema>,
    'email' | 'password'
>;

export type LoginFormProps = FormProps<LoginFormValues, UserLoginResponse> & {
    onForgotPasswordClick: () => void;
};

export const LogInForm: React.FC<LoginFormProps> = ({
    onForgotPasswordClick,
    apiCallback,
    onSuccess,
    ...restParam
}) => {
    const [showModal] = useModal();

    return (
        <Formik
            validationSchema={logInSchema}
            onSubmit={(values, {setErrors, setSubmitting}) => {
                apiCallback(
                    values,
                    onSuccess.bind(values),
                    ({code}: AxiosErrorData) => {
                        if (/[53]../.test(code)) {
                            showModal({children: <ErrorMessage error={code} />});
                        } else {
                            setSubmitting(false);
                            setErrors({
                                email: I18n.t('validation.badCredentials'),
                                password: I18n.t('validation.badCredentials'),
                            });
                        }
                    },
                );
            }}
            {...restParam}
        >
            {({errors, isSubmitting}: FormikProps<LoginFormValues>) => {
                return (
                    <Form>
                        <Field
                            label={I18n.t('vendor.email')}
                            name={'email'}
                            error={errors.email}
                            as={Input}
                            className={styles.field}
                        />
                        <Field
                            label={I18n.t('vendor.password')}
                            name={'password'}
                            type={'password'}
                            error={errors.password}
                            as={Input}
                            className={styles.field}
                        />
                        <div className={styles.controls}>
                            <Button
                                type={'submit'}
                                disabled={isSubmitting}
                                className={styles.submitButton}
                            >
                                {I18n.t('form.submit')}
                            </Button>
                            <Anchor
                                fontSize={'small'}
                                title={I18n.t('form.logIn.forgotPasswordLink')}
                                onClick={() => {
                                    onForgotPasswordClick();
                                }}
                            >
                                {I18n.t('form.logIn.forgotPasswordLink')}
                            </Anchor>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
